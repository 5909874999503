import React from "react";
import ReactFlow, { Background, BackgroundVariant } from "reactflow";
import styled from "styled-components";
import Column from "../../components/common/_customColumn";
import CustomRow from "../../components/common/_customRow";
import PipelineIcon from "../../assets/images/pipeline_bg.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { StyledButtonPrimary } from "../../components/common/_buttonNewOne";

const StyledRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
`;

const Title = styled.div`
  font-family: "Articulat CF Medium";
  font-size: 2.5rem;
  line-height: 41.6px;
  text-align: left;
  color: #f6f6f6;
  font-weight: bold;
`;
const Description = styled.div`
  font-family: "Articulat CF Medium";
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: left;
  color: #cccccc;
  font-weight: lighter;
`;

const PipeLinePageContent = styled.div`
  padding: 2.8rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  overflow: none;
  /* overflow-y: auto; */
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.78rem;
`;
const ImageArea = styled.div`
  height: calc(100vh - 372px);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: pink; */
`;
const Icon = styled.img``;
const PipelinesMainPage = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push("/connectors");
  };
  return (
    <PipeLinePageContent>
      <Content>
        <CustomRow>
          <Column xs={10} md={12} lg={8}>
            <Title>Ready to create your pipeline?</Title>
          </Column>
        </CustomRow>
        <StyledRow>
          <Column xs={5.5} md={5.5}>
            <Description>
              You currently have no connectors added. Click on the button below
              to get started!
            </Description>
          </Column>
        </StyledRow>
        <StyledRow>
          <Column xs={6}>
            <StyledButtonPrimary
              onClick={handleClick}
              style={{ height: "3.3rem", width: "21.15rem" }}
            >
              Add Connector
            </StyledButtonPrimary>
          </Column>
        </StyledRow>
      </Content>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "0.84rem",
          // height: "calc(100vh - 412px)",
        }}
      >
        <img src={PipelineIcon} alt="img" width="100%" height="auto" />
      </div>
    </PipeLinePageContent>
  );
};

export default PipelinesMainPage;
