import React, { Fragment } from "react";
import FlexContainer from "../../../../components/common/_flexContainer";
import Chip from "../../../../components/common/_chip";
import Text from "../../../../components/common/_text";
import Tooltip from "../../../../components/common/_tooltip";
import Popover from "../../../../components/common/_popover";
import styled from "styled-components";
import { ReactComponent as AddWorkspaceMemberIcon } from "../../../../assets/images/add_workspace_member.svg";

const PopoverChip = styled(Chip)`
  width: 1.388rem;
  height: 1.388rem;
  background: #b0b0b0;
  border: none;
  &:hover {
    border: none;
  }
`;
const AddWorkspaceMemberIconStyled = styled(AddWorkspaceMemberIcon)`
  width: 1.111rem;
  height: 1.111rem;
`;

const WorkspaceMembersCell = ({ row, onEditWorkspaceMembersClick }) => {
  const PopoverContent = row.workspaceMembers
    .slice(3)
    .map((workspaceMember, index) => (
      <Fragment key={index}>
        <PopoverChip>
          <Text
            $fontWeight="bold"
            $fontSize="0.833rem"
            $color="#4B4B4B"
            $cursor="default"
          >
            {workspaceMember.substring(0, 1).toUpperCase()}
          </Text>
        </PopoverChip>
        <Text $width="calc(100% - 1.388rem - 0.555rem)" $fontSize="0.833rem">
          {workspaceMember}
        </Text>
      </Fragment>
    ));

  return (
    <FlexContainer $width="100%" $justifyContent="flex-start">
      {row.workspaceMembers.map((workspaceMember, index) =>
        index <= 3 ? (
          index < 3 ? (
            <Tooltip key={index} title={workspaceMember} placement="bottom">
              <Chip $index={index} $isRemainingMembersChip={index === 3}>
                <Text $fontWeight="bold" $fontSize="0.833rem" $cursor="default">
                  {index < 3
                    ? workspaceMember.substring(0, 1).toUpperCase()
                    : `+${row.workspaceMembers.length - 3}`}
                </Text>
              </Chip>
            </Tooltip>
          ) : (
            <Popover
              $maxWidth="10.416rem"
              key={index}
              content={PopoverContent}
              placement="bottom-start"
            >
              <Chip $index={index} $isRemainingMembersChip={index === 3}>
                <Text $fontWeight="bold" $fontSize="0.833rem" $cursor="default">
                  {index < 3
                    ? workspaceMember.substring(0, 1).toUpperCase()
                    : `+${row.workspaceMembers.length - 3}`}
                </Text>
              </Chip>
            </Popover>
          )
        ) : null,
      )}
      {row.isEditable && (
        <Chip
          $index={
            row.workspaceMembers.length <= 3 ? row.workspaceMembers.length : 4
          }
          $cursor="pointer"
          onClick={() => onEditWorkspaceMembersClick(row)}
        >
          <AddWorkspaceMemberIconStyled />
        </Chip>
      )}
    </FlexContainer>
  );
};

export default WorkspaceMembersCell;
