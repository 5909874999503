import styled from "styled-components";
import condenseLogo from "../../assets/images/condenseLogo.svg";
import LandingPageLogo from "../../assets/images/landingpage_logo_new.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const MainContainer = styled.div`
  background-color: #0d0d0d;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MainPageLeftImage = styled.img`
  background-color: #2f2f2f;
  height: 100vh;
  width: 57vw;
  object-fit: cover;
`;

const MainPageRightSection = styled.div`
  background-color: inherit;
  height: 100vh;
  width: 43vw;
`;

const CondenseLogoImage = styled.img`
  height: 3.333rem;
  width: 15.763rem;
`;

const CondenseLogoContainer = styled.div`
  margin-top: ${({ increaseTopGap }) =>
    increaseTopGap ? "6.666rem" : "5.208rem"};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 9.583rem;
`;

const ChildrenPageContentContainer = styled.div`
  margin: 2.777rem 9.583rem 1.111rem 9.583rem;
  overflow: auto;
  height: ${({ increaseTopGap }) =>
    `calc(100vh - (3.333rem + ${
      increaseTopGap ? "6.666rem" : "5.208rem"
    } + 1.527rem + 1.111rem + 2.777rem + 1.111rem))`};
`;

const FooterSection = styled.div`
  margin-bottom: 1.111rem;
  height: 1.527rem;
  padding: 0rem 1.666rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Text = styled.p`
  font-family: "Articulat CF Medium";
  margin: 0;
`;

export const CommonFirstPage = (props) => {
  const history = useHistory();
  return (
    <MainContainer>
      <MainPageLeftImage src={LandingPageLogo} alt="Landing-Image" />
      <MainPageRightSection>
        <CondenseLogoContainer increaseTopGap={props.increaseTopGap}>
          <CondenseLogoImage src={condenseLogo} alt="Condense-Logo" />
        </CondenseLogoContainer>
        <ChildrenPageContentContainer increaseTopGap={props.increaseTopGap}>
          {props.children}
        </ChildrenPageContentContainer>
        <FooterSection>
          <Text
            style={{
              color: "#B0B0B0",
              fontSize: "0.833rem",
              lineHeight: "1.388rem",
            }}
          >
            Need more help?{" "}
            <span
              style={{
                color: "#CCCCCC",
                fontWeight: "bold",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => history.push("/support")}
            >
              Contact Support
            </span>
          </Text>
          <Text
            style={{
              color: "#CCCCCC",
              fontSize: "0.833rem",
              lineHeight: "1.388rem",
            }}
          >
            All rights reserved © 2024 | Zeliot
          </Text>
        </FooterSection>
      </MainPageRightSection>
    </MainContainer>
  );
};
