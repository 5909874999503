import React from "react";
import { Col, Row } from "reactstrap";
import styled from "styled-components";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import Typography from "../common/_typography";

const DeviceListCards = styled.div`
  cursor: pointer;
  height: 200px;
  background: ${(props) =>
    !props.isSelected ? "#232323" : " rgba(71, 204, 214, 0.10)"};
  border: ${(props) => props.isSelected && " 1px solid #47ccd6"};
`;

const DeviceListImageCards = styled.div`
  height: 126px;
  display: flex;
  background: ${(props) =>
    !props.isSelected ? "#171717" : " rgba(71, 204, 214, 0.10)"};
`;

const DeviceListImageTitle = styled.div`
  height: 24px;
  overflow-wrap: break-word;
`;

const DeviceListToConfigure = React.memo((props) => {
  const {
    name,
    id,
    isSelected,
    onSelect,
    icon_link,
    manufact_name,
    devicePort,
    networkType,
    status,
    description,
    code,
  } = props;

  const handleClick = () => {
    onSelect(
      id,
      name,
      manufact_name,
      devicePort,
      networkType,
      status,
      description,
      code,
    );
  };

  return (
    <Row>
      <Col xs={12} onClick={() => handleClick()}>
        <DeviceListCards isSelected={isSelected}>
          <Col xs={12}>
            <DeviceListImageCards isSelected={isSelected}>
              <Col xs={12} className="onBoardingDeviceList">
                <img
                  src={icon_link}
                  alt="Device Image"
                  style={{ aspectRatio: 5 / 2, objectFit: "contain" }}
                  className="img-fluid"
                />
              </Col>
            </DeviceListImageCards>

            <Col xs={12} className="onBoardingDeviceListTitle">
              <p
                className="onBoardingDeviceListTitleText"
                data-tooltip-id="tooltip-example"
                data-tooltip-content={name}
              >
                {name}
              </p>
              <Tooltip id="tooltip-example" effect="solid" place="bottom" />
            </Col>

            <Col xs={12} className="onBoardingDeviceListDescription">
              <DeviceListImageTitle>
                {/* <Typography
                    tag={"p-x-small"}
                    text={manufact_name}
                    color={"#FAFAFA"}
                  /> */}
              </DeviceListImageTitle>
            </Col>
          </Col>
        </DeviceListCards>
      </Col>
    </Row>
  );
});

export default DeviceListToConfigure;
