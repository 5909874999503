import React from "react";
import { ReactComponent as ResendInviteIcon } from "../../../../assets/images/resend_invite_icon.svg";
import styled from "styled-components";
import FlexContainer from "../../../../components/common/_flexContainer";
import Text from "../../../../components/common/_text";

const HoverText = styled(Text)`
  cursor: pointer;
  :hover {
    color: #f6f6f6;
  }
`;

const ResendInviteIconStyled = styled(ResendInviteIcon)`
  width: 1.388rem;
  height: 1.388rem;
  cursor: pointer;
`;
const ResendInviteButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.555rem;
  outline: none;
  border: none;
  border-radius: 0;
  background: transparent;
  &:hover {
    outline: none;
    border: none;
    svg {
      path {
        stroke: ${({ $hoverIconColor }) => $hoverIconColor || "#F6F6F6"};
      }
    }
  }
`;

const WorkspaceCountOrInviteCell = ({ row, handleResendInvite }) => {
  return row.isJustInvite ? (
    <ResendInviteButton onClick={() => handleResendInvite(row)}>
      <ResendInviteIconStyled />
      <HoverText $fontSize="0.972rem" $color="#47CCD6">
        Resend Invite
      </HoverText>
    </ResendInviteButton>
  ) : (
    <FlexContainer $width="100%" $gap="0.277rem">
      <Text $width="100%" $fontSize="0.833rem" $color="#B0B0B0">
        Assigned To
      </Text>
      <Text
        $width="100%"
        $fontWeight="bold"
      >{`${row.assignedWorkspaceCount} Workspaces`}</Text>
    </FlexContainer>
  );
};

export default WorkspaceCountOrInviteCell;
