import React, { useState, useCallback, createContext, useMemo } from "react";

const SnackbarContext = createContext({
  message: "",
  showMessage: () => {},
});

const SnackbarProvider = ({ children }) => {
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const showMessage = useCallback((newMessage, type) => {
    setMessage(newMessage);
    setMessageType(type);
  }, []);

  const value = useMemo(
    () => ({ message, showMessage, messageType }),
    [message, messageType, showMessage],
  );

  return (
    <SnackbarContext.Provider value={value}>
      {children}
    </SnackbarContext.Provider>
  );
};

export { SnackbarProvider, SnackbarContext };
