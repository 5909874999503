import React, { cloneElement, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import FlexContainer from "./_flexContainer";
import Text from "./_text";
import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  FloatingPortal,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useMergeRefs,
  useRole,
} from "@floating-ui/react";
import { remToPx } from "../../utils";

const TooltipContainer = styled(FlexContainer)`
  width: max-content;
  max-width: 20.833rem;
  place-content: center;
  padding: 0.416rem 0.833rem;
  background: #2f2f2f;
  border: 0.035rem solid #b0b0b0;
  border-radius: 0.555rem;
  position: relative;
  z-index: 1;
  font-family: "Articulat CF Medium";
  font-weight: 500;
  font-size: 1.111rem;
  line-height: 1.3;
  color: #f6f6f6;
  text-align: start;
`;

const Tooltip = ({
  children,
  title,
  initialOpen = false,
  placement = "bottom",
  open: controlledOpen,
  onOpenChange: setControlledOpen,
  disabled = false,
}) => {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);
  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;
  const arrowRef = useRef(null);
  const arrowHeight = remToPx(0.416),
    gap = remToPx(0.416);
  const { refs, floatingStyles, context } = useFloating({
    open,
    onOpenChange: setOpen,
    middleware: [
      offset(arrowHeight + gap),
      flip(),
      shift(),
      arrow({
        element: arrowRef,
      }),
    ],
    whileElementsMounted: autoUpdate,
    placement,
  });
  const hover = useHover(context, {
    move: false,
    enabled: controlledOpen == null && !disabled,
  });
  const focus = useFocus(context, {
    enabled: controlledOpen == null && !disabled,
  });
  const dismiss = useDismiss(context);
  const role = useRole(context, {
    role: "tooltip",
  });
  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ]);
  const childrenRef = children.ref;
  const ref = useMergeRefs([refs.setReference, childrenRef]);
  const childrenWithProps = cloneElement(
    children,
    getReferenceProps({
      ref,
      ...children.props,
      "data-state": context.open ? "open" : "closed",
    }),
  );

  useEffect(() => {
    if (disabled) setOpen(false);
  }, [disabled, setOpen]);

  return (
    <>
      {childrenWithProps}
      {open && (
        <FloatingPortal>
          <TooltipContainer
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            <FloatingArrow
              ref={arrowRef}
              context={context}
              width={arrowHeight * 2}
              height={arrowHeight}
              tipRadius={remToPx(0.138)}
              fill="#2F2F2F"
              stroke="#b0b0b0"
              strokeWidth={remToPx(0.036)}
            />
            <Text $width="100%" $fontSize="0.833rem">
              {title}
            </Text>
          </TooltipContainer>
        </FloatingPortal>
      )}
    </>
  );
};

export default Tooltip;
