import React from "react";
import PropTypes from "prop-types";
// import styled from "styled-components";
import "../../scss/PreBuiltConnectUpdated.scss";
import connectorIcon from "../../assets/images/connector.png";
import { Row, Col, Container } from "react-bootstrap";
import { ConnectorsContext } from "../../pages/WorkSpaceConnectors/WorkSpaceConnectorsMainPage";
import { useContext, useState } from "react";
import Vector from "../../assets/images/Vector.svg";
import styled from "styled-components";
import ConnectorIcon from "../../assets/images/Connectors.svg";

const VectorIconTopRight = styled.img`
  position: absolute;
  left: 18.6rem;
  bottom: 10.8rem;
  height: 1.875rem;
  width: 1.805rem;

  transform: rotate(16deg);
`;

const VectorIconBottomLeft = styled.img`
  position: absolute;
  top: 10.763rem;
  right: 19.791rem;
  height: 1.875rem;
  width: 1.805rem;

  transform: rotate(180deg); // Rotate the image 180 degrees
`;

const truncateContent = (content, maxLength = 50) => {
  return content.length > maxLength
    ? `${content.slice(0, maxLength)}...`
    : content;
};
const hideContent = (content, maxLength = 25) => {
  return content.length > maxLength
    ? `${content.slice(0, maxLength)} `
    : content;
};

/**
 * PreBuiltCard component renders a card displaying information about a connector.
 *
 * @param {string} props.title - The title of the connector.
 * @param {string} props.category - The category of the connector.
 * @param {string} props.type - The type of the connector.
 * @param {string} props.description - The description of the connector.
 * @param {} icon - The link of icon of that particular type connector.
 *
 * @returns {JSX.Element} The rendered PreBuiltCard component.
 */

const PublishModalCard = ({ data, handleApplicationCardClick }) => {
  const {
    title,
    publisher,
    content,
    version,
    showVectors,
    icon,
    applicationId,
  } = data;
  // console.log("propssssssssssssssssssss", showVectors);

  return (
    <Container
      style={{
        position: "relative",
        width: "19.86rem",
        // height: "12.91rem",
        // maxHeight: "12.91rem",
        // marginTop: "2.77rem",
      }}
      onClick={() => handleApplicationCardClick(applicationId, title, content)}
    >
      <Row noGutters className="cardcontainer" style={{ height: "11.11rem" }}>
        <Col xs={1}>
          <div
            className="leftlineOrange"
            style={{ marginTop: "0.84rem", marginBottom: "0.84rem" }}
          />
        </Col>

        <Col xs={11}>
          {showVectors && (
            <>
              <VectorIconTopRight src={Vector} alt="Vector Top Right" />
              <VectorIconBottomLeft src={Vector} alt="Vector Bottom Left" />
            </>
          )}
          <Row noGutters style={{ marginTop: "1.25rem" }}>
            <Col
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Row noGutters style={{ width: "100%", flexWrap: "nowrap" }}>
                <Col xs={2} className="g-0">
                  <div
                    className="connectorlogoarea"
                    style={{ height: "2.225rem", width: "2.225rem" }}
                  >
                    <img
                      style={{ width: "1.67rem", height: "1.67rem" }}
                      src={icon || ConnectorIcon}
                      alt="Connector Icon"
                    />
                  </div>
                </Col>
                <Col
                  style={{ fontSize: "1.112rem" }}
                  xs={10}
                  className="connectorname g-0"
                >
                  {title}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row noGutters style={{ marginTop: "0.7rem", flexWrap: "nowrap" }}>
            <Col xs={7}>
              <Row
                style={{
                  color: "#777777",
                  fontSize: "0.7rem",
                  fontWeight: "bold",
                  fontFamily: "Articulat CF Medium",
                }}
              >
                Published By:
              </Row>
              <Row style={{ fontSize: "0.84rem" }} className="categoryOrange">
                {publisher}
              </Row>
            </Col>
            <Col xs={5}>
              <div className="connectorcategoryarea">
                <div
                  style={{
                    fontSize: "0.7rem",
                    color: "#777777",
                    fontWeight: "bold",
                  }}
                >
                  Version
                </div>
                <div style={{ fontSize: "0.84rem" }} className="categoryOrange">
                  {version}
                </div>
              </div>
            </Col>
          </Row>
          <Row
            style={{
              marginTop: "1.39rem",
              // marginBottom: "0.84rem",
              flexWrap: "nowrap",
            }}
          >
            <Row style={{ fontSize: "0.84rem" }} className="description">
              {truncateContent(content)}
            </Row>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

// Prop types
PublishModalCard.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    publisher: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired,
    showVectors: PropTypes.bool,
  }).isRequired,
};
export default PublishModalCard;
