import moment from "moment";
import styled from "styled-components";
import CustomRow from "../common/_customRow";
import Column from "../common/_customColumn";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { StyledButtonPrimary } from "../common/_buttonNewOne";
import { workspaceAvatarMap } from "../../components/common/_workspaceAvatarMap";
import { AuthContext } from "../../AuthContext";
import { useContext } from "react";

const MainContainer = styled.div`
  background-color: #2f2f2f;
  display: flex;
  flex-direction: column;
  border-radius: 0.6rem;
  padding: 1.113rem;
  height: 100%;
`;

const HeaderContainer = styled.div`
  flex: 0 0 auto;
`;

const DescriptionContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 1.111rem 0 1.112rem;
`;

const ButtonContainer = styled.div`
  flex: 0 0 auto;
`;

const Text = styled.p`
  font-family: "Articulat CF Medium";
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const TextDescription = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AvatarIcon = styled.div`
  height: 3.06rem;
  width: 3.06rem;
`;

const WorkspaceCard = ({ eachWorkSpace }) => {
  const { setSelectedWorkspaceData } = useContext(AuthContext);
  const history = useHistory();

  const handleWorkSpaceClick = () => {
    setSelectedWorkspaceData(eachWorkSpace);
    history.push("/connectorsPlayground");
  };

  return (
    <MainContainer>
      <HeaderContainer>
        <CustomRow style={{ alignItems: "flex-start" }}>
          <Column
            xs={9.6}
            style={{
              display: "flex",
              flexFlow: "row wrap",
              justifyContent: "flex-start",
              alignItems: "space-between",
              gap: "0.278rem",
            }}
          >
            <Column xs={12}>
              <Text
                style={{
                  fontSize: "1.39rem",
                  color: "#F6F6F6",
                  fontWeight: "bold",
                  lineHeight: "1.3",
                }}
              >
                {eachWorkSpace.workspaceName}
              </Text>
            </Column>
            <Column xs={12}>
              <Text
                style={{
                  color: "#777777",
                  fontSize: "0.84rem",
                  lineHeight: "1.3",
                }}
              >
                {`Created on ${moment
                  .unix(eachWorkSpace.createdOn)
                  .format("DD MMM YYYY")}`}
              </Text>
            </Column>
          </Column>
          <Column
            xs={1.75}
            style={{
              justifyContent: "flex-end",
              display: "flex",
            }}
          >
            <AvatarIcon
              as={workspaceAvatarMap[eachWorkSpace.iconFile ?? "Avataar_1.svg"]}
            />
          </Column>
        </CustomRow>
      </HeaderContainer>

      <DescriptionContainer>
        <CustomRow
          style={{
            display: "flex",
            flexFlow: "row wrap",
            justifyContent: "flex-start",
            alignItems: "space-between",
            gap: "0.278rem",
          }}
        >
          <Column xs={12}>
            <Text
              style={{
                color: "#777777",
                fontSize: "0.84rem",
                lineHeight: "1.3",
              }}
            >
              Workspace Description
            </Text>
          </Column>
          <Column xs={12}>
            <TextDescription
              style={{
                fontSize: "0.972rem",
                lineHeight: "1.3",
                color: "#F6F6F6",
              }}
            >
              {eachWorkSpace.description}
            </TextDescription>
          </Column>
        </CustomRow>
      </DescriptionContainer>

      <ButtonContainer>
        <StyledButtonPrimary
          onClick={() => handleWorkSpaceClick()}
          style={{
            height: "2.78rem",
            width: "100%",
            borderRadius: "0.5rem",
          }}
        >
          Open Workspace
        </StyledButtonPrimary>
      </ButtonContainer>
    </MainContainer>
  );
};

export default WorkspaceCard;
