import { Col, Row } from "reactstrap";
import "../../scss/components/buildPage/_condenseBuild.scss";
import {
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import ReactFlow, { ConnectionLineType } from "reactflow";
import ConnectorCardDashed from "../connectorsComponents/_connectorsCardDashed";
import ConnectorsCard from "../connectorsComponents/_connectorsCard";
import { useContext, useEffect, useState } from "react";
import dagre from "dagre";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import "reactflow/dist/style.css";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";

let configIdArray = [];
const nodeWidth = 400;
const nodeHeight = 150;

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const getLayoutedElements = (nodes, edges) => {
  if (nodes && edges) {
    // Find nodes that have no outgoing edges
    const nodesWithoutTarget = nodes
      .filter((node) => {
        return !edges.some((edge) => edge.source === node.id);
      })
      .map((node) => node.id);

    //Graph Related Logics
    dagreGraph.setGraph({ rankdir: "LR" });

    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    nodes.forEach((node, i) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      node.targetPosition = "left";
      node.sourcePosition = "right";

      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      node.position = {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      };
      if (node.position.x === 0 && node.position.y === 0) {
        node.position.x = 30;
        node.position.y = 30;
      } else if (node.position.x === 0) {
        node.position.x = 30;
      } else if (node.position.y === 0) {
        node.position.y = 30;
      }

      // Modifying data object of node
      // Check if the node's id is present in nodesWithoutTarget array
      if (nodes.length > 1) {
        node.data.isNodeWithoutTarget = nodesWithoutTarget.includes(node.id);
      } else {
        node.data.isNodeWithoutTarget = true;
      }

      //Inserting key "firstNode" = true, if node id is 1
      //This is being inserted only to hide , left side dot from node card from UI, if node card is first one
      if (node.id === 1) {
        node.data.firstNode = true;
      } else {
        node.data.firstNode = false;
      }

      //Picking all config Ids
      if (node.data?.configId) {
        configIdArray.push(node.data?.configId);
      }

      return node;
    });

    return { nodes, edges };
  }
};

const nodeTypes = {
  customDashed: ConnectorCardDashed,
  custom: ConnectorsCard,
};

const getInitialNode = (
  e,
  selectedConnectorName,
  selectedConnectorType,
  currentConfigId,
  selectedWorkspaceId,
) => {
  let titleLable;
  let lastNodePosition = {};
  switch (selectedConnectorType) {
    case "INPUT":
      titleLable = "Input Data Source";
      break;
    case "TRANSFORMS":
      titleLable = "Transformer";
      break;
    case "OUTPUT":
      titleLable = "Output Data Source";
      break;
    default:
      break;
  }
  let stringifyData = {
    id: selectedWorkspaceId,
    layout: {
      node: [
        {
          id: "1",
          type: "customDashed",
          data: {
            label: selectedConnectorType,
            isConnector: false,
            title: titleLable,
            status: "Running",
            description: `${selectedConnectorName} ${selectedConnectorType} Connector `,
            sourceName: selectedConnectorName,
            connectionType: "HTTP",
            isNodeWithoutTarget: false,
            firstNode: false,
            configId: currentConfigId,
            targetPosition: "left",
            sourcePosition: "right",
            position: {
              x: lastNodePosition.x + 420,
              y: e?.node?.length >= 3 ? 80 : 30,
            },
          },
        },
      ],
      edges: [
        {
          id: "reactflow__edge-1-2",
          source: "1",
          sourceHandle: null,
          targetHandle: null,
        },
      ],
    },
  };
  return stringifyData;
};

const getAdditionalNode = (
  e,
  selectedConnectorName,
  selectedConnectorType,
  currentConfigId,
  nodeId,
) => {
  let titleLable;
  let lastNodePosition = {};
  let selectedNodeId = JSON.parse(e.node[e.node.length - 1].id);
  const newConnectionNode = {
    id: (selectedNodeId + 1).toString(),
    type: "customDashed",
    data: {
      label: selectedConnectorType,
      isConnector: false,
      title: titleLable,
      status: "Running",
      description: `${selectedConnectorName} ${selectedConnectorType} Connector `,
      sourceName: selectedConnectorName,
      connectionType: "HTTP",
      isNodeWithoutTarget: false,
      firstNode: false,
      configId: currentConfigId,
      targetPosition: "left",
      sourcePosition: "right",
      position: {
        x: lastNodePosition.x + 420,
        y: e.node.length >= 3 ? 80 : 30,
      },
    },
  };
  e.node.push(newConnectionNode);
  return e;
};

const CustomTransformDeployment = () => {
  const { showMessage } = useContext(SnackbarContext);
  const location = useLocation();
  const history = useHistory();
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [selectedNodeIds, setSelectedNodeIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [workspaceDetails, setWorkSpaceDetails] = useState({});
  const [currentConfigId, setCurrentConfigId] = useState("");

  useEffect(() => {
    (async () => {
      try {
        let stringifiedData = {
          connectorName: "Custom Transform",
          config: [],
          type: "TRANSFORM",
        };
        let setConnectorConfigResponse = await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "pipeline/setConnectorConfigs",
          stringifiedData,
        );

        if (setConnectorConfigResponse.data.status === "OK") {
          setCurrentConfigId(setConnectorConfigResponse.data.data.id);
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    })();

    (async () => {
      try {
        let selectedWorkspaceId = localStorage.getItem("selectedWorkSpaceId");
        let stringifyData = { id: selectedWorkspaceId };
        let getWorkSpace = await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "pipeline/getWorkSpace",
          stringifyData,
        );

        if (
          getWorkSpace &&
          getWorkSpace.data &&
          getWorkSpace.data.data.layout != null
        ) {
          setWorkSpaceDetails(getWorkSpace.data.data.layout);
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    })();
  }, []);

  useEffect(() => {
    let fetchedResult;
    if (workspaceDetails && workspaceDetails.node && workspaceDetails.edges) {
      getAdditionalNode(
        workspaceDetails,
        "Custom Transform",
        "TRANSFORM",
        currentConfigId,
      );

      fetchedResult = getLayoutedElements(
        workspaceDetails.node,
        workspaceDetails.edges,
      );
    } else if (currentConfigId) {
      let selectedWorkspaceId = localStorage.getItem("selectedWorkSpaceId");
      let getInitalData = getInitialNode(
        workspaceDetails,
        "Custom Transform",
        "TRANSFORM",
        currentConfigId,
        selectedWorkspaceId,
      );
      fetchedResult = getLayoutedElements(
        getInitalData.layout.node,
        getInitalData.layout.edges,
      );
    }

    if (fetchedResult) {
      setNodes(fetchedResult.nodes);
      setEdges(fetchedResult.edges);
    }
  }, [workspaceDetails, currentConfigId]);

  const handleNodeClick = (x, y) => {
    let nodeId = [y.id];
    let selectedNodeId = JSON.parse(nodes[nodes.length - 1].id);
    let finalNodeId = selectedNodeId.toString();
    let addedNewEdgeConnection;

    if (!selectedNodeIds.includes(nodeId.toString()) && finalNodeId !== y.id) {
      setSelectedNodeIds([...selectedNodeIds, ...nodeId]);
      const newEdgeConnection = {
        id: `reactflow__edge-${y.id}-${finalNodeId}`,
        source: `${y.id}`,
        sourceHandle: null,
        target: `${finalNodeId}`,
        targetHandle: null,
      };
      addedNewEdgeConnection = [...edges, newEdgeConnection];
      setEdges(addedNewEdgeConnection);
    } else if (
      selectedNodeIds.includes(nodeId.toString()) &&
      finalNodeId !== y.id
    ) {
      const edgeToRemoveId = `reactflow__edge-${y.id}-${finalNodeId}`;
      const updatedEdges = edges.filter((edge) => edge.id !== edgeToRemoveId);
      setEdges(updatedEdges);
      const updatedSelectedNodeIds = selectedNodeIds.filter(
        (id) => id !== nodeId.toString(),
      );
      setSelectedNodeIds(updatedSelectedNodeIds);
    }

    const fetchedResult = getLayoutedElements(nodes, addedNewEdgeConnection);
    if (fetchedResult) {
      setNodes(fetchedResult.nodes);
      setEdges(fetchedResult.edges);
    }
  };

  const handleDeploy = async () => {
    setIsLoading(true);
    if (selectedNodeIds.length) {
      const updatedNodes = nodes.map((node) =>
        node.type === "customDashed" ? { ...node, type: "custom" } : node,
      );

      let setWorkspaceList;
      try {
        const deployCustomTransformsBody = {
          buildId: location.state?.buildId,
          configId: currentConfigId,
          workspaceId: parseInt(localStorage.getItem("selectedWorkSpaceId")),
        };
        await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "pipeline/deployCustomTransforms",
          deployCustomTransformsBody,
        );
        showMessage("Successfully deployed");
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        showMessage("Deployment Failed");
        return;
      }

      try {
        const setWorkSpaceBody = {
          id: parseInt(localStorage.getItem("selectedWorkSpaceId")),
          layout: {
            node: updatedNodes,
            edges: edges,
          },
        };
        console.log(setWorkSpaceBody);
        setWorkspaceList = await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "pipeline/setWorkSpace",
          setWorkSpaceBody,
        );
      } catch (err) {
        setIsLoading(false);
        showMessage("Failed to set the workspace");
        return;
      }

      if (setWorkspaceList.status === 200 || setWorkspaceList.status === 201) {
        history.push({
          pathname: "connectorsPlayground",
          selectedWorkspace: localStorage.getItem("selectedWorkSpaceId"),
        });
      }
    } else {
      showMessage("Please select a node to connect");
    }
    setIsLoading(false);
  };
  // Redirect to homepage when there is no indication that this page was opened through redirection
  if (!Boolean(location.state?.isRedirected))
    return <Redirect to={{ pathname: "/" }} />;

  return (
    <div
      style={{
        height: "calc(100vh - 112px)",
        backgroundColor: "#171717",
        overflow: "auto",
      }}
    >
      <Row noGutters>
        <Col xs="4" style={{ padding: "20px", paddingRight: "10px" }}>
          <div className="mainContainer">
            <div className="innerRow flexStart mb">
              <p className="p">Image Name: {location.state?.imageName}</p>
            </div>
            <div className="innerRow flexStart mb">
              <p className="p">Tag Name: {location.state?.tag}</p>
            </div>
            <div className="innerRow flexStart mb">
              <p className="p">
                Deployment Name: {location.state?.deploymentName}
              </p>
            </div>
            <div className="innerRow flexStart mb">
              <p className="p">
                ENVs:&nbsp;
                {location.state?.envVariables.length === 0 ? "None" : ""}
              </p>
            </div>
            {location.state?.envVariables.map(({ name, value }, index) => {
              return (
                <div className="innerRow flexStart mb" key={index}>
                  <p className="p">
                    {index + 1}.&nbsp;{name}:&nbsp;{value}
                  </p>
                </div>
              );
            })}
          </div>
        </Col>
        <Col xs="8" style={{ padding: "20px", paddingLeft: "10px" }}>
          <div style={{ backgroundColor: "#313131", height: "600px" }}>
            <ReactFlow
              nodes={nodes}
              edges={edges}
              nodeTypes={nodeTypes}
              onNodeClick={handleNodeClick}
              nodesConnectable={false}
              connectionLineType={ConnectionLineType.Bezier}
              disableKeyboardA11y={true}
            />
          </div>
        </Col>
      </Row>
      <Row noGutters>
        <Col
          xs={12}
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <button
            type="button"
            className={
              isLoading
                ? "btn btn-primary codeEditorCommitButtonDisabled"
                : "btn btn-primary codeEditorCommitButton"
            }
            style={{ marginRight: 40 }}
            onClick={handleDeploy}
            disabled={isLoading}
          >
            {isLoading ? "Deploying..." : "Deploy"}
          </button>
        </Col>
      </Row>
    </div>
  );
};

export default CustomTransformDeployment;
