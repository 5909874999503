import React from "react";
import styled from "styled-components";
import NonTelematicsInputForm from "./streamStoreConnectorConfigFormInput";
import TelematicsInputForm from "./telematicsConnectorConfigInputForm";

const Container = styled.div`
  padding-right: 1.736rem;
  height: 100%;
  border-radius: 0.84rem;
  background-color: #2f2f2f;
  color: #fafafa;
  font-family: "Articulat CF Medium";
  overflow-y: auto;
  overflow-x: none;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 2px;
  }

  ::-webkit-scrollbar-button {
    width: 4px;
    height: 10px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.67rem;
`;

const Heading = styled.div`
  font-size: 1.67rem;
  text-align: left;
  margin-bottom: 2.25rem;
  font-family: "Articulat CF Medium";
  font-weight: bold;
  line-height: 1.5rem;
`;

const ConfigForm = ({
  deviceData,
  configs,
  setConfigs,
  allTopics,
  setAllTopics,
  setPreviewData,
  connectorVersionList,
  setSelectedConnectorVersion,
  selectedConnectorVersion,
}) => {
  return (
    <Container>
      <Content>
        <Heading>Add your Configurations</Heading>
        {deviceData && deviceData.length ? (
          <TelematicsInputForm
            configs={configs}
            setConfigs={setConfigs}
            deviceData={deviceData}
            setPreviewData={setPreviewData}
            allTopics={allTopics}
            setAllTopics={setAllTopics}
          />
        ) : (
          <NonTelematicsInputForm
            configs={configs}
            setConfigs={setConfigs}
            allTopics={allTopics}
            setAllTopics={setAllTopics}
            setPreviewData={setPreviewData}
            connectorVersionList={connectorVersionList}
            setSelectedConnectorVersion={setSelectedConnectorVersion}
            selectedConnectorVersion={selectedConnectorVersion}
          />
        )}
      </Content>
    </Container>
  );
};

export default ConfigForm;
