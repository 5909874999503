import { createContext, useState, useMemo } from "react";
import axiosAdapter from "../utils";
import { env } from "../env";

// Create AuthContext
export const GrafanaUserCredentialsContext = createContext({
  username: "",
  password: "",
  isError: false,
  isLoading: true,
});

export const GrafanaCredentialsProvider = ({ children }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);

  useMemo(async () => {
    try {
      const grafanaResponse = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "admin/getGrafanaUser",
        { url: env.REACT_APP_URL ? env.REACT_APP_URL.slice(0, -1) : "" },
      );
      if (
        grafanaResponse &&
        grafanaResponse.data &&
        grafanaResponse.data.data
      ) {
        setUsername(grafanaResponse.data.data.username);
        setPassword(grafanaResponse.data.data.password);
        setLoading(false);
      } else {
        setLoading(false);
        setError(true);
      }
    } catch (err) {
      setError(true);
      setLoading(false);
      console.log("Error: getGrafanaUser", err);
    }
  }, []);

  // Memoize the context value to prevent unnecessary re-renders
  const grafanaCredentialsValue = useMemo(
    () => ({
      username,
      password,
      isLoading,
      isError,
    }),
    [isError, isLoading, password, username],
  );

  return (
    <GrafanaUserCredentialsContext.Provider value={grafanaCredentialsValue}>
      {children}
    </GrafanaUserCredentialsContext.Provider>
  );
};
