import { useState, createContext, useEffect } from "react";
import ConnectorsConfigurationPage from "./ConnectorsConfigurationPage";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import styled from "styled-components";
import Search from "../../assets/images/search_FILL0_wght400_GRAD0_opsz24.svg";
import { Col, Row } from "reactstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import ConfigBottomNavbar from "../../components/ConfigForm/configFormBottomNav";
import PreBuiltCard from "../../components/PreBuiltConnectors/PreBuiltConnectorUpdated";
import CreateNewConnector from "./CreateNewConnector";
import loadingAnimation from "../../assets/images/Loading Animation.gif";
import CustomConnectorCard from "../ConnectorsPage/CustomConnectorCard";
import CustomRow from "../../components/common/_customRow";
import Column from "../../components/common/_customColumn";

const WsConnectorSearchArea = styled.div`
  width: 22.4rem;
  height: 2.8rem;
  background-color: #272727;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 0.8rem;
`;

const ParentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 95vh;
`;

const AnimationContainer = styled.div`
  position: relative;
  width: 240px;
`;

const ScrollAnimationBar = styled.div`
  width: 100%;
  height: 4px;
  background-color: #2f2f2f;
  border-radius: 2px;
  box-shadow: -8px 4px 9px 0px #2f2f2f;
  position: absolute;
  bottom: 0;
  left: 0;
`;

const LoadingAnimation = styled.img`
  width: 100%;
  bottom: 4px;
`;

//Context created to manage all States and Functions
export const ConnectorsContext = createContext("");

const WorkSpaceConnectorsMainPage = (props) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [filteredConnectors, setFilteredConnectors] = useState([]);
  const [allConnectors, setAllConnectors] = useState([]);

  const [isAddPipelineClicked, setIsAddPipelineClicked] = useState(false);
  const [selectedConnectorType, setSelectedConnectorType] = useState("");
  const [selectedConnectorName, setSelectedConnectorName] = useState("");
  const [selectedConnectorId, setSelectedConnectorId] = useState(null);
  const [selectedConnectorCategory, setSelectedConnectorCategory] =
    useState("");
  const [selectedConnectorDescription, setSelectedConnectorDescription] =
    useState("");
  const history = useHistory();

  const [workspaceLayout, setWorkspaceLayout] = useState({
    node: [],
    edges: [],
  });
  useEffect(() => {
    console.log(currentStep, "currentStepChanged....");
  }, [currentStep]);
  const [isLoading, setIsloading] = useState(false);
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(
    localStorage.getItem("selectedWorkSpaceId"),
  );
  const [customConnectorList, setCustomConnectorList] = useState([]);
  const [customConnectorVersion, setCustomConnectorVersion] = useState("");

  const getAllConnectors = async () => {
    // Set the loading state to true
    setIsloading(true);

    // Fetch all stream connectors and set its category
    let listStreamConnectors = await fetchStreamConnectors();
    let filteredStreamConnectors = listStreamConnectors;
    filteredStreamConnectors = filteredStreamConnectors.map((obj) => ({
      id: obj.id,
      name: obj.name,
      icon_link: obj.icon_link,
      description: obj.description,
      connectionType: obj.connectionType,
      category: obj.category,
      available: Boolean(obj.available),
    }));

    // Fetch all store connectors and set its category
    let listStoreConnectors = await fetchStoreConnectors();
    let filteredStoreConnectors = listStoreConnectors;
    filteredStoreConnectors = filteredStoreConnectors.map((obj) => ({
      id: obj.id,
      name: obj.name,
      icon_link: obj.icon_link,
      description: obj.description,
      connectionType: obj.connectionType,
      category: obj.category,
      available: Boolean(obj.available),
    }));

    // Fetch all manufacturers
    let listManufacturer = await fetchManufacturers();
    let customConnectorsList = await fetchCustomConnectors();
    // Merge all 3 types of connectors
    let listAllConnectors = [];
    listAllConnectors = [
      ...listManufacturer.map((obj) => ({
        available: true,
        ...obj,
      })),
      ...filteredStreamConnectors,
      ...filteredStoreConnectors,
    ];

    // Set state value
    setCustomConnectorList(customConnectorsList);
    setFilteredConnectors(listAllConnectors);
    setAllConnectors(listAllConnectors);
    setIsloading(false);
  };

  const nextStep = () => {
    if (currentStep == 2) {
      console.log("before--", currentStep);
      setCurrentStep((prev) => prev - 1);
      console.log("after--", currentStep);
    } else if (currentStep < 3) {
      // setAnimatingLine(currentStep);
      setCurrentStep((prev) => prev + 1);
    }
  };

  const fetchCustomConnectors = async () => {
    let selectedWorkspaceId = localStorage.getItem("selectedWorkSpaceId");
    let strigifieddata = JSON.stringify({
      workspaceId: parseInt(selectedWorkspaceId),
    });

    try {
      let fetchCustomConnectors = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/getCustomConnectors",
        strigifieddata,
      );
      return fetchCustomConnectors.data.data;
    } catch (err) {
      console.log("err: getCustomConnectors", err);
      return [];
    }
  };

  const fetchManufacturers = async () => {
    let fetchedManufacturers = await axiosAdapter(
      "GET",
      env.REACT_APP_URL + "admin/getAllTelematicsManufacturers",
    );
    return fetchedManufacturers.data.data;
  };

  async function fetchStreamConnectors() {
    let listStreamConnectors = await axiosAdapter(
      "POST",
      env.REACT_APP_URL + "admin/listAllMasterStreamConnectors",
    );
    if (listStreamConnectors && listStreamConnectors.data) {
      return listStreamConnectors.data;
    }
  }

  async function fetchStoreConnectors() {
    let listStoreConnectors = await axiosAdapter(
      "POST",
      env.REACT_APP_URL + "admin/listAllMasterStoreConnectors",
    );
    if (listStoreConnectors && listStoreConnectors.data) {
      return listStoreConnectors.data;
    }
  }

  useEffect(() => {
    getAllConnectors();
    getWorkspace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function to filter the connectors based on the search field
  const handleSearchTextChange = (text) => {
    setSearchValue(text);
    let filteredValues = allConnectors.filter((connector) =>
      connector.name.toLowerCase().includes(text.toLowerCase()),
    );
    setFilteredConnectors(filteredValues);
  };

  const handleFilterClick = () => {
    setFilterModalOpen(!filterModalOpen);
  };

  // Function executed when a Pre Built connector is clicked
  const handlePreConnectorClick = (
    selectedConnectorName,
    selectedConnectorType,
    connectorId,
    category,
    description,
  ) => {
    history.push("/connectorConfiguration", {
      selectedConnectorName,
      selectedConnectorType,
      selectedConnectorId: connectorId,
      selectedConnectorCategory: category,
      selectedConnectorDescription: description,
      workspaceLayout,
      selectedWorkspaceId,
      customConnectorVersion,
    });
  };

  const handleCustomConnectorCardClick = (
    customConnectorId,
    customConnectorName,
    connectorDescription,
    connectorVersion,
  ) => {
    setSelectedConnectorName(customConnectorName);
    setSelectedConnectorId(customConnectorId);
    setSelectedConnectorDescription(connectorDescription);
    setSelectedConnectorType("Custom Connector");
    setSelectedConnectorCategory("Application");
    setCustomConnectorVersion(connectorVersion);

    history.push("/connectorConfiguration", {
      selectedConnectorName: customConnectorName,
      selectedConnectorId: customConnectorId,
      selectedConnectorDescription: connectorDescription,
      selectedConnectorType: "Custom Connector",
      selectedConnectorCategory: "Application",
      customConnectorVersion: connectorVersion,
      workspaceLayout,
      selectedWorkspaceId,
    });
  };

  // Function executed when the back button is clicked in Connector Configuration section
  const handleBackClick = () => {
    setSelectedConnectorType("");
    setSelectedConnectorName("");
    setSelectedConnectorId(null);
    setSelectedConnectorCategory("");
    setIsAddPipelineClicked(false);
  };

  const getWorkspace = async () => {
    let selectedWorkspaceId = localStorage.getItem("selectedWorkSpaceId");
    setSelectedWorkspaceId(selectedWorkspaceId);
    let stringifyData = { id: selectedWorkspaceId };
    let getWorkSpace = await axiosAdapter(
      "POST",
      env.REACT_APP_URL + "pipeline/getWorkSpace",
      stringifyData,
    );

    if (getWorkSpace && getWorkSpace.data && getWorkSpace.data.data) {
      setWorkspaceLayout(
        getWorkSpace.data.data.layout || { node: [], edges: [] },
      );
    } else {
      setWorkspaceLayout(null);
    }
  };

  const refetchCustomConnectors = async () => {
    setCustomConnectorList(await fetchCustomConnectors());
  };

  //Pass States and Functions here, you want to share with all Child Components
  const sharedData = {
    searchValue,
    filterModalOpen,
    handleFilterClick,
    handlePreConnectorClick,
    selectedConnectorName,
    selectedConnectorType,
    handleBackClick,
    refetchCustomConnectors,
  };

  return (
    <div className="wsConnectorMainContainer makeOverFlow">
      <ConnectorsContext.Provider value={sharedData}>
        <>
          <div className="wsConnectorNavBarArea">
            <>
              {isAddPipelineClicked ? (
                <ConfigBottomNavbar
                  connectorName={selectedConnectorName}
                  handleBackClick={handleBackClick}
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                />
              ) : (
                <>
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Col xs={3}>
                      <div className="wsConnectorNavBarLeftArea">
                        <div
                          className="wsConnectorNavBarTitle"
                          style={{ marginTop: "1.67rem" }}
                        >
                          <p>Your Connectors</p>
                        </div>
                      </div>
                    </Col>

                    <Col>
                      <div className="wsConnectorNavBarRightArea">
                        <WsConnectorSearchArea>
                          <div className="wsConnectorSearchIcon">
                            <img
                              src={Search}
                              alt=""
                              style={{ width: "1.67rem", height: "1.67rem" }}
                            />
                          </div>
                          <div
                            className="wsConnectorSearchTextArea"
                            style={{ marginLeft: "1.112rem" }}
                          >
                            <input
                              className="wsConnectorSearchTextInput"
                              type="text"
                              value={searchValue}
                              onChange={(e) => {
                                handleSearchTextChange(e.target.value);
                              }}
                              placeholder="Search for Connectors"
                            />
                          </div>
                        </WsConnectorSearchArea>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </>
          </div>

          {isLoading ? (
            <ParentContainer>
              <AnimationContainer>
                <ScrollAnimationBar />
                <LoadingAnimation
                  src={loadingAnimation}
                  alt="Loading Animation"
                />
              </AnimationContainer>
            </ParentContainer>
          ) : filteredConnectors.length ? (
            <div>
              {isAddPipelineClicked ? (
                <ConnectorsConfigurationPage
                  workspaceLayout={workspaceLayout}
                  selectedConnectorName={selectedConnectorName}
                  selectedConnectorType={selectedConnectorType}
                  selectedWorkspaceId={selectedWorkspaceId}
                  selectedConnectorId={selectedConnectorId}
                  selectedConnectorCategory={selectedConnectorCategory}
                  selectedConnectorDescription={selectedConnectorDescription}
                  customConnectorVersion={customConnectorVersion}
                  nextStep={nextStep}
                  currentStepper={currentStep}
                  setCurrentStepper={setCurrentStep}
                />
              ) : (
                <>
                  <CustomRow
                    style={{
                      gap: "0.84rem",
                      justifyContent: "normal",
                      alignItems: "stretch",
                      marginBottom: "70px",
                      marginTop: "0.55rem",
                    }}
                  >
                    {customConnectorList.map((eachObj) => (
                      <Column key={eachObj.id} xs={2.9}>
                        <CustomConnectorCard
                          connectorName={eachObj.connectorName}
                          publisher={eachObj.latestPublishedBy}
                          description={eachObj.latestDescription}
                          connectorVersion={eachObj.latestVersion}
                          iconLink={eachObj.latestIconLink || null}
                          iconFile={eachObj.latestIconFile}
                          connectorId={eachObj.id}
                          handleCustomConnectorCardClick={
                            handleCustomConnectorCardClick
                          }
                        />
                      </Column>
                    ))}
                    <Column xs={2.9}>
                      <CreateNewConnector />
                    </Column>
                  </CustomRow>
                  <div>
                    <CustomRow>
                      <Column xs={12}>
                        <div className="wsConnectorNavBarLeftArea">
                          <div
                            className="wsConnectorNavBarTitle"
                            tour-guide="explain_connectors"
                          >
                            <p>Pre-Built Connectors</p>
                          </div>
                        </div>
                      </Column>
                    </CustomRow>
                    {/* <div style={{backgroundColor:"pink"}}> */}

                    <div
                      style={{
                        gap: "0.8rem",
                        display: "flex",
                        flexWrap: "wrap",
                        marginTop: "2.05rem",
                      }}
                    >
                      {filteredConnectors
                        .filter((eachObj) => eachObj.available)
                        .map((eachObj, index) => (
                          <Column xs={2.911} md={2.911} lg={2.911}>
                            <PreBuiltCard
                              key={index}
                              title={eachObj.name}
                              description={eachObj.description || ""}
                              category={eachObj.category}
                              type={eachObj.connectionType}
                              id={eachObj.id}
                              icon={eachObj.icon_link}
                            />
                          </Column>
                        ))}
                    </div>
                    {/* </div> */}
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className="wsNoConnectorsFound">
              <p>No Connectors Found</p>
            </div>
          )}
        </>
      </ConnectorsContext.Provider>
    </div>
  );
};

export default WorkSpaceConnectorsMainPage;
