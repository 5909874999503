import styled from "styled-components";

const FlexContainer = styled.div`
  width: ${({ $width }) => $width || ""};
  height: ${({ $height }) => $height || ""};
  flex: ${({ $flex }) => $flex || ""};
  display: flex;
  flex-flow: ${({ $flexDirection, $flexWrap }) =>
    ($flexDirection || "row") + " " + ($flexWrap || "wrap")};
  justify-content: ${({ $justifyContent }) =>
    $justifyContent || "space-between"};
  align-items: ${({ $alignItems }) => $alignItems || "center"};
  align-content: ${({ $alignContent }) => $alignContent || "flex-start"};
  gap: ${({ $gap }) => $gap || ""};
  margin: ${({ $margin }) => $margin || ""};
  padding: ${({ $padding }) => $padding || ""};
`;

export default FlexContainer;
