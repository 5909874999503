import {
  MapContainer,
  TileLayer,
  Marker,
  Circle,
  Polygon,
  useMapEvents,
  Polyline,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import {
  Row,
  Col,
  Container,
  Dropdown,
  InputGroup,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  InputGroupText,
  Input,
  Table,
} from "reactstrap";
import { createRef, useContext, useEffect, useState } from "react";
import { polygon, lineString, buffer, geometry, center } from "@turf/turf";
import axios from "axios";
import { env } from "../../env";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Modal } from "react-bootstrap";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";

const GeoFenceLeaflet = (props) => {
  const [selectedFenceData, setSelectedFenceData] = useState(null);
  const [fenceId, setFenceId] = useState([]);
  const [geofenceList, setGeofenceList] = useState([]);
  const [getconfigId, setgetconfigId] = useState();
  const [selectedFenceType, setSelectedFenceType] = useState(null);
  const [radius, setRadius] = useState(0);
  const [Latitude, setLatitude] = useState(0);
  const [Longitude, setLongitude] = useState(0);
  const [circleCenter, setCircleCenter] = useState(null);
  const [geoJson, setGeoJson] = useState(null);
  const [geoJsonPoly, setgeoJsonPoly] = useState(null);
  const [polygonPoints, setPolygonPoints] = useState([]);
  const [polylinePoints, setPolylinePoints] = useState([]);
  const [geoJsonRoute, setgeoJsonRoute] = useState(null);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [isPolySubmitEnabled, setIsPolySubmitEnabled] = useState(false);
  const [bufferedPoints, setBufferedPoints] = useState([]);
  const [isDropdownOpen, setIsDropDownOpen] = useState(false);
  const [fenceName, setFenceName] = useState("");
  const [getfenceName, setgetfenceName] = useState("");
  const [hexColorValue, setHexColorValue] = useState("ff8e00");
  const [createCircle, setcreateCircle] = useState(null);
  const [createPoly, setcreatePoly] = useState(null);
  // const [createRoute, setcreateRoute] = useState(null);
  const [updateCircle, setupdateCircle] = useState(null);
  const [updatePoly, setupdatePoly] = useState(null);
  const [updateRoute, setupdateRoute] = useState(null);
  const [fenceNewName, setFenceNewName] = useState();
  const [fenceType, setFenceType] = useState();
  const [fenceRadius, setFenceRadius] = useState();
  const [coordinates, setCoordinates] = useState();
  const [polyCoordinates, setpolyCoordinates] = useState();
  const [fenceClick, setfenceClick] = useState(false);
  const [showFenceData, setShowFenceData] = useState(false);
  const [routeCoordinate, setrouteCoordinate] = useState();
  const [routeRadius, setrouteRadius] = useState();
  const { showMessage } = useContext(SnackbarContext);
  const [bufferPoints, setBufferPoints] = useState([]);
  let createRoute = null;
  // const configId = props.configIdForGeoFence;
  const configId = 1;
  console.log("Config Ids areeee", configId);

  const handleEdit = () => {
    setfenceClick(false);
    setShowFenceData(true);
  };
  const handleClose = () => {
    setfenceClick(false);
    setShowFenceData(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const geofenceListData = await fetchGeofenceListByConfigId(configId);

        if (geofenceListData) {
          setFenceId(geofenceListData);

          setGeofenceList(geofenceListData);
        } else {
          console.log("No geofences found.");
        }
      } catch (error) {
        console.error("Error fetching geofence list:", error);
      }
    };

    fetchData();
  }, [configId]);
  useEffect(() => {
    if (selectedFenceData?.data?.data?.fencegeoJson?.geometry?.coordinates) {
      setCoordinates(
        selectedFenceData.data.data.fencegeoJson.geometry.coordinates[0],
      );
    }
  }, [selectedFenceData]);
  useEffect(() => {
    if (selectedFenceData?.data?.data?.fenceName) {
      setgetfenceName(selectedFenceData.data.data.fenceName);
    }
  }, [selectedFenceData]);
  useEffect(() => {
    if (selectedFenceData?.data?.data?.fencegeoJson?.geometry?.coordinates) {
      setpolyCoordinates(
        selectedFenceData.data.data.fencegeoJson.geometry.coordinates[0],
      );
    }
  }, [selectedFenceData]);

  useEffect(() => {
    if (selectedFenceData?.data?.data?.coordinatesRoute) {
      setrouteCoordinate(
        JSON.parse(selectedFenceData.data.data.coordinatesRoute),
      );
    }
    if (selectedFenceData?.data?.data?.fencegeoJson?.geometry?.coordinates) {
      setBufferPoints(
        selectedFenceData.data.data.fencegeoJson?.geometry?.coordinates,
      );
    }
    if (selectedFenceData?.data?.data?.fenceRadius) {
      setrouteRadius(selectedFenceData?.data?.data?.fenceRadius);
    }
  }, [selectedFenceData]);

  const fetchGeofenceListByConfigId = async (configId) => {
    try {
      const response = await axios.post(
        env.REACT_APP_URL + "geo/getGeofenceByConfigId",
        { configId },
      );

      if (response.status === 200) {
        return response.data.data;
      }
    } catch (error) {
      console.error("Error fetching geofence list:", error);
      return [];
    }
  };

  useEffect(() => {
    if (selectedFenceData?.data?.data?.fenceName) {
      setFenceNewName(selectedFenceData.data.data.fenceName);
    }
  }, [selectedFenceData]);

  useEffect(() => {
    if (selectedFenceData?.data?.data?.fenceRadius) {
      setFenceRadius(selectedFenceData.data.data.fenceRadius);
    }
  }, [selectedFenceData]);

  const fetchGeofence = async (fenceId) => {
    try {
      const response = await axios.post(env.REACT_APP_URL + "geo/getGeofence", {
        fenceId,
      });
      // setBufferedPoints(response.data.data.);
      // setBufferPoints(fenceCoordinates);
      setgetconfigId(response.data.data.configId);
      if (response.status === 200) {
        return response;
      }
    } catch (error) {
      console.error("Error fetching geofence:", error);
      return [];
    }
  };

  const handleNewNameChange = (e) => {
    setFenceNewName(e.target.value);
    selectedFenceData.data.data.fenceName = e.target.value;
  };

  const handleRadiusChange = (e) => {
    setFenceRadius(e.target.value);
    selectedFenceData.data.data.fenceRadius = e.target.value;
  };

  const geoFenceSelector = (e) => {
    setSelectedFenceType(e.target.name);
    setFenceName("");
    setHexColorValue("");
    setRadius("");
    setIsDropDownOpen(false);
  };
  const geoFenceListSelector = (e) => {
    setSelectedFenceType(null);
  };

  const handleDropDownhandler = () => {
    setIsDropDownOpen(!isDropdownOpen);
  };

  const handleRadiusSelector = (e) => {
    const rad = e.target.value;
    setRadius(rad);
  };

  const handleLatitudechange = (e) => {
    setLatitude(parseFloat(e.target.value));
  };

  const handleLongitudeChange = (e) => {
    setLongitude(parseFloat(e.target.value));
  };
  const handleFenceClick = async (fenceId) => {
    setfenceClick(true);

    try {
      const response = await fetchGeofence(fenceId);
      if (response.status === 200) {
        setSelectedFenceData(response);
      }
    } catch (error) {
      console.error("Error fetching geofence:", error);
    }
  };

  const handleCircleSubmit = (e) => {
    const { lat, lng } = e.latlng;
    if (lat && lng) {
      setLatitude(lat);
      setLongitude(lng);
      setCircleCenter([lat, lng]);
      const geojson = {
        configId: configId,
        fenceName: fenceName,
        fenceType: selectedFenceType,
        fenceColor: hexColorValue,
        fencegeoJson: {
          type: "feature",
          properties: {
            name: fenceName,
            color: hexColorValue,
          },
          geometry: {
            type: "Circle",
            coordinates: [[lat, lng]],
          },
        },
        fenceRadius: fenceRadius,
      };

      setGeoJson(geojson);
    }
  };

  const handleSubmit = () => {
    if (radius != null) {
      setCircleCenter([Latitude, Longitude]);

      // This is for fencegeojson field (to be used later)

      //   const centerPoint = point([Longitude, Latitude]);
      // const options = { steps: 3, units: 'kilometers' };
      // const circle1 = circle(centerPoint, radius / 1000, options);

      // const geojson = featureCollection([centerPoint]);
      // geojson.features[0].properties = {
      //   radius: radius/1000
      // };
      const geojson = {
        configId: configId,
        fenceName: fenceName,
        fenceType: selectedFenceType,
        fenceColor: hexColorValue,
        fencegeoJson: {
          type: "feature",
          properties: {
            name: fenceName,
            color: hexColorValue,
          },
          geometry: {
            type: "Circle",
            coordinates: [[Latitude, Longitude]],
          },
        },
        fenceRadius: radius,
      };

      setGeoJson(geojson);
      setcreateCircle(geojson);
      setupdateCircle(geojson);
    } else {
      showMessage("Radius cannot be null. Please enter a valid radius.");
    }
  };

  const createGeofence = async (selectedFenceType) => {
    try {
      const selectedFence =
        selectedFenceType === "circle"
          ? createCircle
          : selectedFenceType === "polygon"
          ? createPoly
          : selectedFenceType === "route"
          ? createRoute
          : null;
      selectedFence.configId = 1;
      if (selectedFenceType) {
        const response = await axios.post(
          env.REACT_APP_URL + "geo/createGeofence",
          selectedFence,
        );
        // console.log(
        //   "response",
        //   response.data.data,
        //   response.data.data.geometry,
        //   response.data.data.geometry.coordinates,
        // );
        if (selectedFenceType == "route") {
          console.log("insidesettingggggg");
          setBufferedPoints(response.data.data.geometry.coordinates);
          setBufferPoints(response.data.data.geometry.coordinates);
        }

        const updatedGeofenceList = await fetchGeofenceListByConfigId(configId);
        if (updatedGeofenceList) {
          setGeofenceList(updatedGeofenceList);
        }

        console.log("Geofence created successfully", response.data);
        showMessage("Geofence created successfully ", "success");
      }
    } catch (error) {
      console.error("Error in creating Geofence", error);
      showMessage("Error in creating Geofence");
    }
  };

  const updateGeoFence = async (data) => {
    try {
      data.fencegeoJson.geometry.coordinates = bufferPoints;
      let fenceId = data.fenceId;
      let fenceName = data.fenceName;
      let fenceType = data.fenceType;
      let fenceColor = data.fenceColor;
      let fencegeoJson = data.fencegeoJson;
      let fenceRadius = data.fenceRadius;
      let configId = data.configId;
      let coordinatesRoute = null;
      if (fenceType == "ROUTE") {
        coordinatesRoute = data.coordinatesRoute;
      }
      if (data.fenceId) {
        const response = await axios.post(
          env.REACT_APP_URL + "geo/updateGeofence",
          {
            fenceId,
            fenceName,
            fenceType,
            fenceColor,
            fencegeoJson,
            fenceRadius,
            configId,
            coordinatesRoute,
          },
        );
        const updatedGeofenceList = await fetchGeofenceListByConfigId(configId);
        if (updatedGeofenceList) {
          setGeofenceList(updatedGeofenceList);
        }
        console.log("Updated Geofence successfully ", response.data);
        showMessage("Updated Geofence successfully ", "success");
      } else {
        console.error("Invalid fence data");
      }
    } catch (error) {
      console.error("Error in Updating Geofence", error);
    }
  };
  const deleteGeoFence = async (fenceId) => {
    try {
      if (fenceId) {
        const response = await axios.post(
          env.REACT_APP_URL + "geo/deleteGeofence",
          { fenceId },
        );
        setfenceClick(false);
        setShowFenceData(false);
        const updatedList = geofenceList.filter(
          (geofence) => geofence.fenceId !== fenceId,
        );
        setGeofenceList(updatedList);

        console.log("Deleted Geofence successfully ", response.data);
        showMessage("Deleted Geofence successfully ", "success");
      } else {
        console.error("Provide Fence ID");
      }
    } catch (error) {
      console.error("Error in Deleting Geofence", error);
    }
  };
  const handlePolygonMapClick = (e) => {
    const { lat, lng } = e.latlng;
    setPolygonPoints([...polygonPoints, [lat, lng]]);
  };

  const handlePolyCoordinateChange = (index, value, axis) => {
    const updatedCoordinates = [...polyCoordinates];
    updatedCoordinates[index][axis] = parseFloat(value);
    setpolyCoordinates(updatedCoordinates);
  };
  const handleRouteCoordinateChange = (index, value, axis) => {
    const updatedCoordinates = [...routeCoordinate];
    updatedCoordinates[index][axis] = parseFloat(value);
    setrouteCoordinate(updatedCoordinates);
    selectedFenceData.data.data.coordinatesRoute =
      JSON.stringify(updatedCoordinates);
    handleSubmitRoute();
  };

  const handleSubmitPolygon = () => {
    let points = [...polygonPoints];
    const firstPoint = points[0];
    const lastPoint = points[points.length - 1];
    if (firstPoint[0] !== lastPoint[0] || firstPoint[1] !== lastPoint[1]) {
      points.push(firstPoint);
    }
    const latLngs = points.map((point) => [
      parseFloat(point[0]),
      parseFloat(point[1]),
    ]);
    const poly = polygon([latLngs]);
    const polyJson = {
      configId: configId,
      fenceName: fenceName,
      fenceType: selectedFenceType,
      fenceColor: hexColorValue,
      fencegeoJson: poly,
      fenceRadius: radius,
    };

    setgeoJsonPoly(polyJson);
    setcreatePoly(polyJson);
    setupdatePoly(polyJson);
  };

  const ClickHandler = ({ onClick }) => {
    useMapEvents({
      click: (e) => {
        onClick(e);
      },
    });

    return null;
  };
  const handleMapClick = (e) => {
    const { lat, lng } = e.latlng;
    setPolylinePoints([...polylinePoints, [lat, lng]]);
  };

  const routeBuffer = (coordinates) => {
    const cordPoints = [];
    for (let j = 0; j < coordinates.length; j++) {
      const coord = coordinates[j];
      cordPoints.push([coord[1], coord[0]]);
    }
    let coordinateRoute = JSON.stringify(cordPoints);
    const routejson = {
      configId: configId,
      fenceName: fenceName,
      fenceType: selectedFenceType,
      fenceColor: hexColorValue,
      // fencegeoJson: {
      //   type: "Feature",
      //   geometry: {
      //     type: "Route",
      //     coordinates: fenceCoordinates,
      //   },
      // },
      fenceRadius: radius,
      coordinatesRoute: coordinateRoute,
    };
    createRoute = routejson;
    createGeofence("route");
    setgeoJsonRoute(routejson);

    setupdateRoute(routejson);
  };

  const handleSubmitRoute = () => {
    if (!selectedFenceType) {
      setRadius(selectedFenceData.data.data.fenceRadius);
      const coordinates = routeCoordinate.map((point) => [point[1], point[0]]);
      routeBuffer(coordinates);
    } else {
      if (radius != null) {
        const coordinates = polylinePoints.map((point) => [point[1], point[0]]);
        routeBuffer(coordinates);
      } else {
        showMessage("Radius cannot be null. Please enter a valid radius.");
      }
    }
  };

  const handleNameChange = (e) => {
    setFenceName(e.target.value);
  };

  const handleColorChange = (e) => {
    setHexColorValue(e.target.value);
  };

  useEffect(() => {
    setCircleCenter(null);
    setPolygonPoints([]);
    setGeoJson(null);
    setgeoJsonPoly(null);
    setPolylinePoints([]);
    setgeoJsonRoute(null);
    setRadius(null);
    setBufferedPoints(null);
  }, [selectedFenceType]);

  useEffect(() => {
    setIsSubmitEnabled(polylinePoints.length > 1);
  }, [polylinePoints]);

  useEffect(() => {
    setIsPolySubmitEnabled(polygonPoints.length > 3);
  }, [polygonPoints]);

  const handleCoordinateChange = (e, index, coordIndex) => {
    const updatedCoordinates = [...coordinates];
    updatedCoordinates[coordIndex] = e.target.value;
    setCoordinates(updatedCoordinates);
    selectedFenceData.data.data.fencegeoJson.geometry.coordinates[0][
      coordIndex
    ] = e.target.value;
  };

  const position = [12.982867217730057, 77.54874525597238];

  return (
    <div>
      <Container>
        <Row noGutters>
          <Col
            sm={12}
            md={3}
            lg={3}
            style={{
              height: "calc(100vh - 220px)",
              backgroundColor: "#535353",
              overflow: "auto",
            }}
          >
            <Row noGutters>
              <Col
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <Dropdown
                  isOpen={isDropdownOpen}
                  toggle={handleDropDownhandler}
                >
                  <DropdownToggle caret>
                    {selectedFenceType ?? "Select GeoFence"}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem name={"CIRCLE"} onClick={geoFenceSelector}>
                      Circle
                    </DropdownItem>
                    <DropdownItem name={"POLYGON"} onClick={geoFenceSelector}>
                      Polygon
                    </DropdownItem>
                    <DropdownItem name={"ROUTE"} onClick={geoFenceSelector}>
                      Route
                    </DropdownItem>
                    <DropdownItem name={"List"} onClick={geoFenceListSelector}>
                      List of Fences
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </Col>

              {selectedFenceType && (
                <>
                  <Col xs={12}>
                    <Row
                      noGutters
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginTop: "32px",
                        marginLeft: "14px",
                        marginRight: "12px",
                      }}
                    >
                      <Col xs={10}>
                        <InputGroup size="sm">
                          <InputGroupText>
                            <b>Name</b>
                          </InputGroupText>
                          <Input
                            value={fenceName}
                            type="text"
                            aria-label="Small"
                            aria-describedby="inputGroup-sizing-sm"
                            onChange={handleNameChange}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={12}>
                    <Row
                      noGutters
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginTop: "10px",
                        marginLeft: "14px",
                        marginRight: "12px",
                      }}
                    >
                      <Col xs={10}>
                        <InputGroup size="sm">
                          <InputGroupText>
                            <b>Hex Color</b>
                          </InputGroupText>
                          <Input
                            type="text"
                            aria-label="Small"
                            aria-describedby="inputGroup-sizing-sm"
                            value={hexColorValue}
                            onChange={handleColorChange}
                          />
                        </InputGroup>
                      </Col>

                      <Col xs={1}>
                        <div
                          style={{
                            height: "30px",
                            width: "30px",
                            marginLeft: "6px",
                            backgroundColor: hexColorValue
                              ? `#${hexColorValue}`
                              : "#535353",
                          }}
                        ></div>
                      </Col>
                    </Row>
                  </Col>
                </>
              )}

              {fenceClick && geofenceList.length > 0 && (
                <>
                  <Modal
                    show={fenceClick}
                    backdrop="static"
                    keyboard={false}
                    onHide={handleClose}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Fence Name: {getfenceName}</Modal.Title>
                    </Modal.Header>

                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleEdit}>
                        Edit
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() =>
                          deleteGeoFence(selectedFenceData.data.data.fenceId)
                        }
                      >
                        Delete
                      </Button>
                      {}
                    </Modal.Footer>
                  </Modal>
                </>
              )}

              <Col xs={12}>
                {selectedFenceData && showFenceData && !selectedFenceType && (
                  <div style={{ marginTop: "20px" }}>
                    <h3>Selected Fence Data</h3>
                    <label>
                      Fence ID:
                      <input
                        type="text"
                        value={selectedFenceData.data.data.fenceId}
                        disabled
                      />
                    </label>
                    <label>
                      Config ID:
                      <input
                        type="text"
                        value={selectedFenceData.data.data.configId}
                        disabled
                      />
                    </label>
                    <label>
                      Fence Type:
                      <input
                        type="text"
                        value={selectedFenceData.data.data.fenceType}
                        disabled
                      />
                    </label>
                    <label>
                      Fence Name:
                      <input
                        type="text"
                        value={fenceNewName}
                        onChange={(e) => handleNewNameChange(e)}
                      />
                    </label>
                    {selectedFenceData.data.data.fenceType == "CIRCLE" && (
                      <label>
                        Fence Radius:
                        <input
                          type="text"
                          value={fenceRadius}
                          onChange={(e) => handleRadiusChange(e)}
                        />
                      </label>
                    )}
                    {selectedFenceData.data.data.fenceType == "ROUTE" && (
                      <label>
                        Fence Width:
                        <input
                          type="text"
                          value={fenceRadius}
                          onChange={(e) => handleRadiusChange(e)}
                        />
                      </label>
                    )}

                    {selectedFenceData.data.data.fenceType === "CIRCLE" && (
                      <div>
                        <h4>Coordinates:</h4>
                        <ul>
                          {selectedFenceData.data.data.fencegeoJson &&
                            coordinates && (
                              <div>
                                <ul>
                                  {selectedFenceData.data.data.fencegeoJson.geometry.coordinates.map(
                                    (coordinate, index) => (
                                      <li key={index}>
                                        Latitude:
                                        <input
                                          type="text"
                                          value={coordinates[0]}
                                          onChange={(e) =>
                                            handleCoordinateChange(e, index, 0)
                                          }
                                        />
                                        Longitude:
                                        <input
                                          type="text"
                                          value={coordinates[1]}
                                          onChange={(e) =>
                                            handleCoordinateChange(e, index, 1)
                                          }
                                        />
                                      </li>
                                    ),
                                  )}
                                </ul>
                              </div>
                            )}
                        </ul>
                      </div>
                    )}

                    {selectedFenceData.data.data.fenceType === "POLYGON" && (
                      <div>
                        <h2>Coordinates:</h2>
                        <ul>
                          {selectedFenceData?.data?.data?.fencegeoJson?.geometry?.coordinates[0]
                            .slice(0, -1)
                            .map((coord, index) => (
                              <li key={index}>
                                Coordinate {index + 1}:
                                <input
                                  type="number"
                                  value={coord[0]}
                                  onChange={(e) =>
                                    handlePolyCoordinateChange(
                                      index,
                                      e.target.value,
                                      0,
                                    )
                                  }
                                />
                                <input
                                  type="number"
                                  value={coord[1]}
                                  onChange={(e) =>
                                    handlePolyCoordinateChange(
                                      index,
                                      e.target.value,
                                      1,
                                    )
                                  }
                                />
                              </li>
                            ))}
                        </ul>
                      </div>
                    )}
                    {selectedFenceData.data.data.fenceType === "ROUTE" && (
                      <div>
                        <h2>Coordinates:</h2>
                        <ul>
                          {JSON.parse(
                            selectedFenceData?.data?.data?.coordinatesRoute,
                          ).map((coord, index) => (
                            <li key={index}>
                              Coordinate {index + 1}:
                              <input
                                type="number"
                                value={coord[0]}
                                onChange={(e) =>
                                  handleRouteCoordinateChange(
                                    index,
                                    e.target.value,
                                    0,
                                  )
                                }
                              />
                              <input
                                type="number"
                                value={coord[1]}
                                onChange={(e) =>
                                  handleRouteCoordinateChange(
                                    index,
                                    e.target.value,
                                    1,
                                  )
                                }
                              />
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                )}

                <Col xs={12}>
                  {selectedFenceData && showFenceData && !selectedFenceType && (
                    <Col xs={12} style={{ marginTop: "20px" }}>
                      <Button
                        style={{ marginLeft: "10px" }}
                        onClick={() =>
                          updateGeoFence(selectedFenceData.data.data)
                        }
                      >
                        Save
                      </Button>
                      <Button
                        style={{ marginLeft: "10px" }}
                        onClick={handleClose}
                      >
                        Back
                      </Button>
                    </Col>
                  )}
                </Col>
              </Col>

              <Col xs={12} style={{ marginRight: "40px" }}>
                <Container>
                  {selectedFenceType === "CIRCLE" && (
                    <Row noGutters>
                      <Col xs={10} style={{ marginTop: "10px" }}>
                        <InputGroup size="sm">
                          <InputGroupText>
                            <b>Radius</b>
                          </InputGroupText>
                          <Input
                            type="number"
                            aria-label="Small"
                            aria-describedby="inputGroup-sizing-sm"
                            onChange={handleRadiusSelector}
                          />
                        </InputGroup>
                      </Col>
                      <Col xs={10} style={{ marginTop: "10px" }}>
                        <InputGroup size="sm">
                          <InputGroupText>
                            <b>Latitude</b>
                          </InputGroupText>
                          <Input
                            type="number"
                            aria-label="Small"
                            aria-describedby="inputGroup-sizing-sm"
                            value={Latitude}
                            onChange={handleLatitudechange}
                          />
                        </InputGroup>
                      </Col>
                      <Col xs={10} style={{ marginTop: "10px" }}>
                        <InputGroup size="sm">
                          <InputGroupText>
                            <b>Longitude</b>
                          </InputGroupText>
                          <Input
                            value={Longitude}
                            type="number"
                            aria-label="Small"
                            aria-describedby="inputGroup-sizing-sm"
                            onChange={handleLongitudeChange}
                          />
                        </InputGroup>
                      </Col>
                      <Col xs={10} style={{ marginTop: "20px" }}>
                        <Button onClick={handleSubmit}>View</Button>
                        <Button
                          style={{ marginLeft: "10px" }}
                          onClick={() => createGeofence("circle")}
                        >
                          Create
                        </Button>
                      </Col>
                      {geoJson && (
                        <Row>
                          <Col
                            xs={12}
                            style={{
                              marginTop: "20px",
                              overflow: "auto",
                              color: "#fefefe",
                            }}
                          >
                            <h5>Json:</h5>
                            <pre style={{ overflow: "unset" }}>
                              {JSON.stringify(geoJson, null, 2)}
                            </pre>
                          </Col>
                        </Row>
                      )}
                    </Row>
                  )}
                  {selectedFenceType === "POLYGON" && (
                    <div style={{ marginTop: "24px", marginLeft: "24px" }}>
                      <h5>Selected Points:</h5>
                      <Table className="table">
                        <thead>
                          <tr>
                            <th>Latitude</th>
                            <th>Longitude</th>
                          </tr>
                        </thead>
                        <tbody>
                          {polygonPoints.map((point, index) => (
                            <tr key={index}>
                              <td>{point[0]}</td>
                              <td>{point[1]}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <Col xs={12} style={{ marginTop: "20px" }}>
                        <Button
                          onClick={handleSubmitPolygon}
                          disabled={!isPolySubmitEnabled}
                        >
                          View
                        </Button>
                        <Button
                          style={{ marginLeft: "10px" }}
                          onClick={() => createGeofence("polygon")}
                          disabled={!isPolySubmitEnabled}
                        >
                          Create
                        </Button>
                      </Col>

                      {geoJsonPoly && (
                        <Row>
                          <Col
                            xs={12}
                            style={{
                              marginTop: "20px",
                              overflow: "auto",
                              color: "#fefefe",
                            }}
                          >
                            <h5>Json:</h5>
                            <pre style={{ overflow: "unset" }}>
                              {JSON.stringify(geoJsonPoly, null, 2)}
                            </pre>
                          </Col>
                        </Row>
                      )}
                    </div>
                  )}
                  {selectedFenceType === "ROUTE" && (
                    <div>
                      <Col
                        xs={10}
                        style={{ marginTop: "10px", marginBottom: "24px" }}
                      >
                        <InputGroup size="sm">
                          <InputGroupText>
                            <b>Radius</b>
                          </InputGroupText>
                          <Input
                            type="number"
                            aria-label="Small"
                            aria-describedby="inputGroup-sizing-sm"
                            onChange={handleRadiusSelector}
                          />
                        </InputGroup>
                      </Col>

                      <h5>Selected Points:</h5>
                      <Col xs={12} style={{ height: "20vh", overflow: "auto" }}>
                        <Table className="table">
                          <thead>
                            <tr>
                              <th>Latitude</th>
                              <th>Longitude</th>
                            </tr>
                          </thead>
                          <tbody>
                            {polylinePoints.map((point, index) => (
                              <tr key={index}>
                                <td>{point[0]}</td>
                                <td>{point[1]}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Col>

                      <Col xs={12} style={{ marginTop: "20px" }}>
                        {/* <Button
                          onClick={handleSubmitRoute}
                          disabled={!isSubmitEnabled}
                        >
                          View
                        </Button> */}
                        <Button
                          style={{ marginLeft: "10px" }}
                          onClick={() => {
                            handleSubmitRoute();
                          }}
                          disabled={!isSubmitEnabled}
                        >
                          Create
                        </Button>
                      </Col>

                      {geoJsonRoute && (
                        <Row>
                          <Col
                            xs={12}
                            style={{
                              marginTop: "20px",
                              height: "40vh",
                              overflow: "auto",
                              color: "#fefefe",
                            }}
                          >
                            <h5>Json:</h5>
                            <pre>{JSON.stringify(geoJsonRoute, null, 2)}</pre>
                          </Col>
                        </Row>
                      )}
                    </div>
                  )}
                </Container>
              </Col>
            </Row>
          </Col>

          <Col sm={12} md={9} lg={9} gutter>
            <MapContainer
              center={position}
              zoom={13}
              scrollWheelZoom={false}
              style={{
                height: "100%",
                width: "100%",
              }}
              gestureHandling={true}
            >
              {selectedFenceType === "CIRCLE" && (
                <>
                  <ClickHandler onClick={handleCircleSubmit} />

                  {circleCenter && radius && (
                    <Circle
                      center={circleCenter}
                      radius={radius}
                      fillColor={`#${hexColorValue}`}
                      color={`#${hexColorValue}`}
                    />
                  )}
                </>
              )}
              {polygonPoints && selectedFenceType === "POLYGON" && (
                <>
                  <ClickHandler onClick={handlePolygonMapClick} />
                  <Polygon
                    positions={polygonPoints}
                    pathOptions={{
                      fillColor: `#${hexColorValue}`,
                      color: `#${hexColorValue}`,
                    }}
                  ></Polygon>
                </>
              )}
              {polylinePoints && selectedFenceType === "ROUTE" && (
                <>
                  <ClickHandler onClick={handleMapClick} />
                  <Polyline
                    positions={polylinePoints}
                    pathOptions={{
                      color: `#${hexColorValue}`,
                    }}
                  />
                </>
              )}
              {bufferedPoints && selectedFenceType === "ROUTE" && (
                <>
                  <Polygon
                    positions={bufferedPoints.map((point) => [
                      point[1],
                      point[0],
                    ])}
                    pathOptions={{
                      fillColor: `#000000`,
                      color: `#000000`,
                    }}
                  ></Polygon>
                </>
              )}

              {selectedFenceData &&
                showFenceData &&
                !selectedFenceType &&
                selectedFenceData?.data?.data?.fenceType === "CIRCLE" && (
                  <>
                    <>
                      <ClickHandler onClick={handleCoordinateChange} />

                      <Circle
                        center={coordinates}
                        radius={fenceRadius}
                        fillColor={`#${hexColorValue}`}
                        color={`#${hexColorValue}`}
                      />
                    </>
                  </>
                )}

              {selectedFenceData &&
                showFenceData &&
                !selectedFenceType &&
                selectedFenceData?.data?.data?.fenceType === "POLYGON" && (
                  <>
                    <ClickHandler onClick={handlePolyCoordinateChange} />

                    <Polygon
                      positions={polyCoordinates}
                      pathOptions={{
                        fillColor: `#${hexColorValue}`,
                        color: `#${hexColorValue}`,
                      }}
                    ></Polygon>
                  </>
                )}

              {selectedFenceData &&
                showFenceData &&
                !selectedFenceType &&
                selectedFenceData?.data?.data?.fenceType === "ROUTE" && (
                  <>
                    {/* <ClickHandler onClick={handleRouteCoordinateChange} /> */}
                    <Polyline
                      positions={routeCoordinate}
                      pathOptions={{
                        color: `#${hexColorValue}`,
                      }}
                    />
                    <Polygon
                      positions={bufferPoints.map((point) => [
                        point[1],
                        point[0],
                      ])}
                      pathOptions={{
                        fillColor: `#${hexColorValue}`,
                        color: `#${hexColorValue}`,
                      }}
                    ></Polygon>
                  </>
                )}

              {!selectedFenceType && !showFenceData ? (
                <Container
                  style={{
                    backgroundColor: "black",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <div style={{ marginLeft: "10vh", width: "130vh" }}>
                    <Col xs={6}>
                      <Row>
                        <Col>
                          <div>
                            <h2
                              style={{
                                color: "white",
                                marginLeft: "6rem",
                                marginTop: "2rem",
                              }}
                            >
                              List of exisiting Fence{" "}
                            </h2>
                            <div
                              style={{
                                height: "60vh",
                                overflow: "auto",
                                marginTop: "2rem",
                              }}
                            >
                              <Table
                                style={{
                                  color: "black",
                                  background: "grey",
                                  width: "100%",
                                  cursor: "pointer",
                                }}
                              >
                                <thead>
                                  <tr>
                                    <th>Fence ID</th>
                                    <th>Name</th>
                                    <th>Type</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {geofenceList.length > 0 ? (
                                    geofenceList
                                      .sort((a, b) => a.fenceId - b.fenceId)
                                      .map((geofence, index) => (
                                        <tr
                                          key={index}
                                          onClick={() =>
                                            handleFenceClick(geofence.fenceId)
                                          }
                                        >
                                          <td>{geofence.fenceId}</td>
                                          <td>{geofence.fenceName}</td>
                                          <td>{geofence.fenceType}</td>
                                        </tr>
                                      ))
                                  ) : (
                                    <tr>
                                      <td colSpan="3">No geofences found.</td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </div>
                </Container>
              ) : (
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
              )}
            </MapContainer>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default GeoFenceLeaflet;
