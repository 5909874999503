import axios from "axios";
import decode from "jwt-decode";

const axiosAdapter = async (method, url, data) => {
  // console.log("data",data)
  let getTokenFromStorage = localStorage.getItem("token");
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("loginId");
    localStorage.removeItem("userName");
    localStorage.removeItem("selectedWorkSpaceName");
    localStorage.removeItem("selectedWorkSpaceId");
    localStorage.removeItem("selectedWorkSpaceIconLink");
    localStorage.removeItem("roles");
  };
  if (getTokenFromStorage) {
    const { exp } = decode(getTokenFromStorage);
    if (Date.now() / 1000 > exp) {
      logout();
      if (!localStorage.getItem("token")) {
        window.location.reload();
      }
      throw new Error("Token expired");
    }
  }

  let config = {
    method: method,
    // NOTE: Need to add the dyncamic URL such that only routes shall be passed
    url: url,
    // withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getTokenFromStorage}`,
    },
    ...(data && { data }),
  };
  //API Call: Validating OTP and Resetting Password.
  let axiosResponse = await axios(config);
  return axiosResponse;
};

export const remToPx = (rem) => {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
};

export default axiosAdapter;
