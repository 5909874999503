import { React } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import styled from "styled-components";
import AddCircle from "../../assets/images/add_circle.svg";

const CardContainer = styled.div`
  width: 100%;
  height: 11.19rem;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23777' stroke-width='1.5' stroke-dasharray='10' stroke-dashoffset='23' stroke-linecap='square'/%3e%3c/svg%3e");
  display: flex;
  /* border: 0.1rem dashed white; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.84rem;
  cursor: pointer;
  gap: 0.84rem;
  border-radius: 0.55rem;
  min-height: 10.9rem;

  &:hover {
    background-color: #2f2f2f;
    border-radius: 0.55rem;
  }
`;

const Icon = styled.img`
  height: 2.225rem;
  width: 2.225rem;
`;

const Text = styled.p`
  font-family: "Articulat CF Medium";
  margin: 0;
`;

const CreateNewConnector = () => {
  const history = useHistory();
  return (
    <CardContainer onClick={() => history.push("/applications")}>
      <Icon src={AddCircle} alt="Add Icon" />
      <Text
        style={{ color: "#FAFAFA", fontSize: "1.112rem", fontWeight: "bold" }}
      >
        Create a New Connector
      </Text>
    </CardContainer>
  );
};

export default CreateNewConnector;
