import React, { useRef, useState } from "react";
import { env } from "../../../env";
import styled from "styled-components";
import loadingAnimation from "../../../assets/images/Loading Animation.gif";

const ParentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #171717;
`;

const AnimationContainer = styled.div`
  position: relative;
  width: 240px;
`;

const ScrollAnimationBar = styled.div`
  width: 100%;
  height: 4px;
  background-color: #2f2f2f;
  border-radius: 2px;
  box-shadow: -8px 4px 9px 0px #2f2f2f;
  position: absolute;
  bottom: 0;
  left: 0;
`;

const LoadingAnimation = styled.img`
  width: 100%;
  bottom: 4px;
`;

const token = localStorage.getItem("token");

const VSCodeUI = ({ height, width, podName, repoName }) => {
  const [isloading, setLoading] = useState(true);
  const iframeRef = useRef(null);
  const customUrl =
    env.REACT_APP_URL +
      `code/server/` +
      podName +
      "?folder=/home/condense/" +
      repoName +
      "&token=" +
      encodeURIComponent(token) || "null";

  return (
    <div
      style={{
        height: height,
        width: width,
        borderTop: "1px solid #2f2f2f",
        borderLeft: "1px solid #2f2f2f",
      }}
    >
      {isloading && (
        <ParentContainer>
          <AnimationContainer>
            <ScrollAnimationBar />
            <LoadingAnimation src={loadingAnimation} alt="Loading Animation" />
          </AnimationContainer>
        </ParentContainer>
      )}
      <iframe
        style={{
          overflow: "hidden",
          scrollbarColor: "black",
          visibility: isloading ? "hidden" : "visible",
        }}
        onLoad={() => setLoading(false)}
        title="codeEditor"
        ref={iframeRef}
        src={customUrl}
        height={height - 1}
        width={width - 1}
      />
    </div>
  );
};

export default VSCodeUI;
