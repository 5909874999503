import React from "react";
import ConfigApplication from "./ConfigApplication";
import CustomAppEditorProvider from "../../context/customApp/CustomAppEditorProvider";

const ConfigApplicationWrapper = () => {
  return (
    <CustomAppEditorProvider>
      <ConfigApplication />
    </CustomAppEditorProvider>
  );
};

export default ConfigApplicationWrapper;
