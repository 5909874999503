import React, { cloneElement, useState } from "react";
import styled from "styled-components";
import FlexContainer from "./_flexContainer";
import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useMergeRefs,
  useRole,
} from "@floating-ui/react";
import { remToPx } from "../../utils";

const PopoverContainer = styled(FlexContainer)`
  width: max-content;
  max-width: ${({ $maxWidth }) => $maxWidth || ""};
  gap: 0.833rem 0.555rem;
  position: relative;
  z-index: 1;
  background: #2f2f2f;
  border: 0.069rem solid #b0b0b0;
  border-radius: 0.833rem;
  box-shadow: 0.347rem 0.347rem 0.555rem 0.138rem #00000029;
  padding: 0.833rem;
  font-family: "Articulat CF Medium";
  font-weight: 500;
  font-size: 1.111rem;
  line-height: 1.3;
  color: #f6f6f6;
  text-align: start;
`;

const Popover = ({
  $maxWidth,
  children,
  content,
  initialOpen = false,
  placement = "bottom",
  open: controlledOpen,
  onOpenChange: setControlledOpen,
}) => {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);
  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;
  const { refs, floatingStyles, context } = useFloating({
    open,
    onOpenChange: setOpen,
    middleware: [offset(remToPx(0.416)), flip(), shift()],
    whileElementsMounted: autoUpdate,
    placement,
  });
  const hover = useHover(context, {
    move: false,
    enabled: controlledOpen == null,
  });
  const focus = useFocus(context, {
    enabled: controlledOpen == null,
  });
  const dismiss = useDismiss(context);
  const role = useRole(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ]);
  const childrenRef = children.ref;
  const ref = useMergeRefs([refs.setReference, childrenRef]);
  const childrenWithProps = cloneElement(
    children,
    getReferenceProps({
      ref,
      ...children.props,
      "data-state": context.open ? "open" : "closed",
    }),
  );

  return (
    <>
      {childrenWithProps}
      {open && (
        <FloatingPortal>
          <PopoverContainer
            $maxWidth={$maxWidth}
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            {content}
          </PopoverContainer>
        </FloatingPortal>
      )}
    </>
  );
};

export default Popover;
