import dagre from "dagre";

// These are constant values and please change these values based on the height and width of the node card
const NODE_HEIGHT = 180;
const NODE_WIDTH = 300;

// Function to take in interfaces of 2 nodes and return edges if applicable based on the topic name and directions in the interfaces
const getEdges = (interface1, interface2, id1, id2) => {
  let edges = [];
  for (let topic1 of interface1) {
    if (topic1.direction === "input") {
      for (let topic2 of interface2) {
        if (topic1.name === topic2.name && topic2.direction === "output") {
          edges.push({ source: id2, target: id1 });
        }
      }
    } else {
      for (let topic2 of interface2) {
        if (topic1.name === topic2.name && topic2.direction === "input") {
          edges.push({ source: id1, target: id2 });
        }
      }
    }
  }
  return edges;
};

// Function to repostion all the nodes based on the dagre graph algorithm
export const repositionNodes = (
  nodes,
  edges,
  nodeWidth = NODE_WIDTH,
  nodeHeight = NODE_HEIGHT,
) => {
  // Initialize dagre graph
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  // Set the graph configuration to Left->Right
  dagreGraph.setGraph({ rankdir: "LR" });

  // Set the nodes for the graph
  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  // Set the edges for the graph
  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  // Set the positions of each node based on the layout of graph
  for (let node of nodes) {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.position.x = nodeWithPosition.x;
    node.position.y = nodeWithPosition.y;
  }

  // Return the updated node
  return nodes;
};

// Function to add a new node along with edge calculation for the newly added node and repositioning of nodes based on new graph
export const getNodesAndEdgesWithNewnode = (allNodes, allEdges, newNode) => {
  // Clone both nodes and edges to avoid issues with deeper object mutations
  let tempAllNodes = JSON.parse(JSON.stringify(allNodes));
  let tempAllEdges = JSON.parse(JSON.stringify(allEdges));

  // For every node, if its interface is available, caluclate the edges w.r.t new node and push to existing edge array if applicable
  for (let node of allNodes) {
    if (node.data.interfaces) {
      let tempEdges = getEdges(
        newNode.data.interfaces,
        node.data.interfaces,
        newNode.id,
        node.id,
      );
      for (let edge of tempEdges) {
        tempAllEdges.push({
          ...edge,
          sourceHandle: null,
          targetHandle: null,
          id: `reactflow__edge-${edge.source}-${edge.target}`,
        });
      }
    }
  }

  // Push the new node to nodes array
  tempAllNodes.push(newNode);

  // Call the repositioning function on the nodes and return nodes and edges
  return {
    nodes: repositionNodes(tempAllNodes, tempAllEdges),
    edges: tempAllEdges,
  };
};
