// src/Modal.js
import React from "react";
import styled from "styled-components";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background: #2f2f2f;
  padding: 2.222rem;
  border-radius: 1.8rem;
  border: 0.1rem solid #777777;
  width: 55vw;
  box-shadow: 0 0.138rem 0.7rem rgba(0, 0, 0, 0.1);
  max-height: ${({ $maxHeight }) => $maxHeight};
  overflow: auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.222rem;
`;

const Title = styled.h2`
  margin: 0;
  font-family: "Articulat CF Medium";
  font-size: 1.67rem;
  line-height: 1;
  color: #f6f6f6;
  text-align: left;
  font-weight: bold;
`;

const Body = styled.div``;

/**
 * Renders a generic modal component.
 *
 * @param {boolean} props.show - Indicates whether the modal should be shown.
 * @param {function} props.onClose - The function to be called when the modal is closed.
 * @param {string} props.title - The title of the modal.
 * @param {boolean} props.disableCloseOnOutsideClick - Indicates whether the modal should be closed when the user clicks outside of it.
 * @param {number} props.maxHeight - The maximum height of the modal in vh.
 * @param {ReactNode} props.children - The content of the modal.
 * @return {ReactElement|null} The rendered modal component or null if the modal should not be shown.
 */
const GenericModal = ({
  show,
  onClose,
  title,
  disableCloseOnOutsideClick,
  children,
  maxHeight = "60vh",
}) => {
  if (!show) {
    return null;
  }

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <Overlay
      onClick={(e) =>
        !disableCloseOnOutsideClick && onClose && handleOverlayClick(e)
      }
    >
      <ModalContainer $maxHeight={maxHeight}>
        {title && (
          <Header>
            <Title>{title}</Title>
          </Header>
        )}
        <Body>{children}</Body>
      </ModalContainer>
    </Overlay>
  );
};

export default GenericModal;
