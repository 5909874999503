import styled from "styled-components";
import { CommonFirstPage } from "../LandingPages/CommonFirstPage";
import CustomRow from "../../components/common/_customRow";
import Column from "../../components/common/_customColumn";
import { useState } from "react";
import { StyledButtonPrimary } from "../../components/common/_buttonNewOne";
import ChevronRight from "../../assets/images/Chevron-left.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import { useContext } from "react";
import axiosAdapter from "../../utils";
import { env } from "../../env";

const EMAIL_REGEX = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

const CustomColumn = styled(Column)`
  margin-bottom: ${({ mb }) => (mb ? `${mb}rem` : "0rem")};
`;

const Text = styled.p`
  font-family: "Articulat CF Medium";
  margin: 0;
  color: #f6f6f6;
  font-size: 1.527rem;
  line-height: 2.166rem;
`;

const InputField = styled.input`
  font-family: "Articulat CF Medium";
  font-size: 1.111rem;
  line-height: 1.944rem;
  font-weight: 500;
  color: #f6f6f6;
  background-color: inherit;
  outline: none;
  border: none;
  width: 100%;
  caret-color: white;
  &::placeholder {
    color: #777777;
  }
`;

const InputFieldContainer = styled.div`
  padding: 0.625rem 1.111rem;
  width: 100%;
  border-radius: 0.555rem;
  border: none;
  background-color: #2f2f2f;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.069rem solid #2f2f2f;
`;

export const ForgotPassword = () => {
  const history = useHistory();
  const { showMessage } = useContext(SnackbarContext);

  const [emailAddress, setEmailAddress] = useState("");
  const [emailAddressError, setEmailAddressError] = useState("");
  const [disableAllButtons, setDisableAllButtons] = useState(false);

  const handleSendOtpClick = async () => {
    setEmailAddressError("");
    let isEmailIdValid = true;

    // Email validations
    if (!Boolean(emailAddress)) {
      setEmailAddressError("Please enter a value for the email address");
      isEmailIdValid = false;
    } else if (emailAddress.length > 320) {
      setEmailAddressError("Email length should be less than 320 characters");
      isEmailIdValid = false;
    } else if (!EMAIL_REGEX.test(emailAddress)) {
      setEmailAddressError("Please enter a valid email address");
      isEmailIdValid = false;
    }

    if (!isEmailIdValid) {
      return;
    }

    try {
      await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "user-management/generateOtp",
        {
          emailId: emailAddress,
          isNewAccount: false,
        },
      );

      // As OTP is generated, move to OTP verification page
      history.push("/otpVerification", {
        isRedirected: true,
        isSignUp: false,
        emailId: emailAddress,
      });
    } catch (err) {
      console.log("Error: generateOtp", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage("Unable to generate OTP, please try again later", "error");
      }
      setDisableAllButtons(false);
      return;
    }
  };

  return (
    <CommonFirstPage>
      <CustomRow>
        <CustomColumn mb={2.777}>
          <Text>
            Forgot your password? No problem. We will send an email with
            instructions to reset your password along with OTP.
          </Text>
        </CustomColumn>
        <CustomColumn mb={2.777}>
          <InputFieldContainer
            style={
              emailAddressError
                ? { borderColor: "#EA3A3A", marginBottom: "0.555rem" }
                : {}
            }
          >
            <InputField
              placeholder="Your email address"
              name="emailAddress"
              onChange={(e) => setEmailAddress(e.target.value)}
              value={emailAddress}
            />
          </InputFieldContainer>
          {emailAddressError && (
            <Text
              style={{
                fontSize: "0.833rem",
                lineHeight: "1.388rem",
                color: "#EA3A3A",
              }}
            >
              {emailAddressError}
            </Text>
          )}
        </CustomColumn>
        <CustomColumn mb={2.777}>
          <StyledButtonPrimary
            style={{ width: "100%", height: "3.888rem" }}
            onClick={handleSendOtpClick}
            disabled={disableAllButtons}
          >
            Send OTP
          </StyledButtonPrimary>
        </CustomColumn>
        <CustomColumn
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "auto",
            cursor: "pointer",
          }}
          onClick={() => !disableAllButtons && history.push("/signIn")}
        >
          <img
            src={ChevronRight}
            alt="Back Icon"
            style={{
              width: "0.833rem",
              height: "0.833rem",
              marginRight: "0.555rem",
            }}
          />
          <Text
            style={{
              color: "#CCCCCC",
              fontSize: "1.111rem",
              lineHeight: "1.388rem",
            }}
          >
            Back to Sign In
          </Text>
        </CustomColumn>
      </CustomRow>
    </CommonFirstPage>
  );
};
