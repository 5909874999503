import React, { useState } from "react";
import styled from "styled-components";
import ViewIcon from "../../assets/images/visibility_true.svg";
import HideIcon from "../../assets/images/visibility_off_false.svg";
import Typography from "./_typography";

const StyledInput = styled.input`
  padding: 10px 36px 10px 16px; /* Adjusted padding for the icon */
  width: 100%;
  background: #2f2f2f;
  border: 2px solid #2f2f2f;
  box-sizing: border-box;
  font-size: 16px;
  color: #f6f6f6;
  font-weight: 500; /* Corrected font-weight property */
  border-radius: 8px;
  caret-color: white;
  position: relative; /* To position the icon relative to the input */
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: #777777;
    font-size: 16px;
    font-weight: 500; /* Corrected font-weight property */
  }
`;

const EyeIcon = styled.img`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;

const InputField = (props) => {
  const [viewPassword, setViewPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setViewPassword(!viewPassword);
  };

  return (
    <div style={{ position: "relative" }}>
      <StyledInput
        name={props.name}
        value={props.value}
        autoComplete="none"
        placeholder={props.placeholder}
        onChange={props.onChange}
        type={props.type === "password" && !viewPassword ? "password" : "text"}
        disabled={props.disabled}
      />
      {props.type === "password" && (
        <EyeIcon
          alt="password"
          onClick={togglePasswordVisibility}
          src={viewPassword ? ViewIcon : HideIcon}
          width="20.15px"
          height="19.15px"
        />
      )}
    </div>
  );
};

export default InputField;
