import React, { useContext, useState } from "react";
import { Col, Container, Form, FormGroup, Label, Input, Row } from "reactstrap";
import styled from "styled-components";
import Typography from "../common/_typography";
import ConfigureInputField from "./_configureInputField";
import TransformInputFields from "../../pages/WorkSpaceConnectors/TransformInputFields";
import { ConnectorsContext } from "../../pages/WorkSpaceConnectors/WorkSpaceConnectorsMainPage";

const Card = styled.div`
  width: 100%;
  height: 523px;
  background: #313131;
  overflow: auto;
`;

const Line = styled.div`
  height: 0px;
  border: 2px solid #909090;
`;

const BreakLine = styled.hr`
  margin: 20px 0;
  color: #fafafa;
`;
const Model = styled(Label)`
  color: #fafafa;
  font-family: "Articulat CF Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
`;

const StyledInput = styled(Input)`
  background: #313131;
  color: #fafafa;
  font-family: "Articulat CF Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  border: 2px solid #909090;
  border-radius: none;
`;

const ConfigureCard = (props) => {
  const {
    deviceName,
    configs,
    handleConfigFieldsInputChange,
    inputValues,
    modaloptions,
  } = props;
  const { disableConfigInputs, selectedConnectorType } =
    useContext(ConnectorsContext);

  const handleInputChange = (e, index) => {
    handleConfigFieldsInputChange(e, index);
  };

  const [expandedCount, setExpandedCount] = useState(0);

  const toggleExpansion = () => {
    setExpandedCount(expandedCount + 1);
  };

  const renderInputFields = () => {
    console.log("consfigsss", configs);
    if (configs.length) {
      return configs.map((data, index) => {
        const { label, isRequired, placeHolder, description } = data;
        const type = typeof placeHolder === "number" ? "number" : "text"; // Dynamic type assignment

        return (
          <FormGroup row key={index}>
            <Label for="dataSource" sm={3}>
              <Typography
                text={isRequired ? `${label}*` : label}
                color="#FAFAFA"
                tag="p-x-small"
              />
            </Label>
            <Col sm={9}>
              <ConfigureInputField
                type={type}
                placeholder={placeHolder}
                value={inputValues[index] || ""}
                onChange={(e) => handleInputChange(e, index)}
                disabled={disableConfigInputs}
              />
              <div className="configureInputDescription">
                <Typography
                  text={description}
                  color="#FAFAFA"
                  tag="p-x-small"
                />
              </div>
            </Col>
          </FormGroup>
        );
      });
    } else {
      return (
        <div className="noConfigurationMessageContainer">
          <p className="noConfigurationMessage">
            Selected configurations not available
          </p>
        </div>
      );
    }
  };
  return (
    <Card>
      <Container className="configureCardContainer">
        <Row>
          <Col xs={12}>
            <Typography
              text={`Configure ${deviceName}`}
              color={"#FAFAFA"}
              tag={"head-x-x-small"}
            />
          </Col>
        </Row>
        <Row className="configureLineRow">
          <Col xs={12}>
            <Line />
          </Col>
        </Row>

        <Row style={{ marginBottom: "15px" }}>
          <Col xs={12}>
            {configs.length ? (
              <Typography
                text={`Fields with * are mandatory`}
                color={"#FAFAFA"}
                tag={"p-small"}
              />
            ) : null}
          </Col>
        </Row>

        <Row className="configureFormRow">
          <Form>
            {selectedConnectorType.toUpperCase() === "OUTPUT" ||
            selectedConnectorType.toUpperCase() === "INPUT" ||
            selectedConnectorType.toUpperCase() === "TRANSFORMS" ? (
              <>
                <FormGroup row>
                  <Model for="model" sm={3}>
                    Model*
                  </Model>
                  <Col sm={9}>
                    <StyledInput type="select" name="model" id="model">
                      {/* <option value="">Select a model</option>
                      {modaloptions.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))} */}
                    </StyledInput>
                  </Col>
                </FormGroup>
                {renderInputFields()}
              </>
            ) : selectedConnectorType === "CUSTOM TRANSFORM" ? (
              <TransformInputFields />
            ) : null}
          </Form>
        </Row>
      </Container>
    </Card>
  );
};

export default ConfigureCard;
