import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { env } from "../../env";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import loadingAnimation from "../../assets/images/Loading Animation.gif";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import axiosAdapter from "../../utils";

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 3.95rem);
  background-color: #0d0d0d;
`;

const AnimationContainer = styled.div`
  position: relative;
  width: 240px;
`;

const ScrollAnimationBar = styled.div`
  width: 100%;
  height: 4px;
  background-color: #2f2f2f;
  border-radius: 2px;
  box-shadow: -8px 4px 9px 0px #2f2f2f;
  position: absolute;
  bottom: 0;
  left: 0;
`;

const LoadingAnimation = styled.img`
  width: 100%;
  bottom: 4px;
`;

function NewApplicationPage() {
  const { showMessage } = useContext(SnackbarContext);
  const history = useHistory();
  const [isCreateAppLoading, setIsCreateAppLoading] = useState(true);

  useEffect(() => {
    let workspaceId = localStorage.getItem("selectedWorkspaceId");
    if (!workspaceId) {
      history.push("/");
    }
    workspaceId = parseInt(workspaceId);
    (async () => {
      try {
        if (!workspaceId) {
          return;
        }
        const createAppResponse = await axiosAdapter(
          "POST",
          `${env.REACT_APP_URL}/pipeline/createApplication`,
          { workspaceId: workspaceId },
        );
        history.push("/repoInformation", {
          isRedirected: true,
          applicationId: createAppResponse.data.data.id,
        });
      } catch (err) {
        console.log("Error: createApplication", err);
        setIsCreateAppLoading(false);
        if (err.response?.data?.remarks) {
          showMessage(err.response.data.remarks);
        } else {
          showMessage(
            "Failed to authenticate, please go back and try again later !!!",
          );
        }
        setTimeout(() => {
          history.push("/applications");
        }, 1500);
      }
    })();
  }, [history, showMessage]);

  return (
    <>
      {isCreateAppLoading ? (
        <PageContainer>
          <AnimationContainer>
            <ScrollAnimationBar />
            <LoadingAnimation src={loadingAnimation} alt="Loading Animation" />
          </AnimationContainer>
        </PageContainer>
      ) : (
        <PageContainer></PageContainer>
      )}
    </>
  );
}

export default NewApplicationPage;
