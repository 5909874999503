import { env } from "../../env";
import axiosAdapter from "../../utils";
import JSIcon from "../../assets/code-icons/javascript.svg";
import CssIcon from "../../assets/code-icons/css.svg";
import JsonIcon from "../../assets/code-icons/json.svg";
import HtmlIcon from "../../assets/code-icons/html.svg";
import JsxIcon from "../../assets/code-icons/react.svg";
import DefaultIcon from "../../assets/code-icons/textFile.svg";

export function calculateDaysAgo(lastUpdatedTimestamp) {
  const currentDate = new Date();
  const lastUpdatedDate = new Date(lastUpdatedTimestamp * 1000);
  const timeDifference = currentDate - lastUpdatedDate;
  const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  return daysAgo;
}

export const fetchRepoData = async (workSpaceId, applicationId) => {
  try {
    const stringifiedData = {
      workSpaceId,
      applicationId,
    };
    let response = await axiosAdapter(
      "POST",
      env.REACT_APP_URL + "github/getAllRepo",
      stringifiedData,
    );
    return response.data;
  } catch (error) {
    console.log("Error: ", error);
    return null;
  }
};

export const fetchBranchData = async (
  gitUsername,
  selectedRepo,
  selectedRepoId,
  selectedWorkspaceName,
  workSpaceId,
  applicationId,
) => {
  try {
    const stringifiedData = {
      gitUsername: gitUsername,
      gitRepo: selectedRepo,
      repoId: selectedRepoId,
      workspaceName: selectedWorkspaceName,
      repositoryName: selectedRepo,
      workSpaceId,
      applicationId,
    };
    let response = await axiosAdapter(
      "POST",
      env.REACT_APP_URL + "github/getAllBranchesOfRepo",
      stringifiedData,
    );

    return response.data.allBranches;
  } catch (error) {
    console.log("Error: ", error);
    return null;
  }
};

export const fetchFolderTree = async ({ workspaceId, applicationId }) => {
  try {
    const stringifiedData = {
      workspaceId,
      applicationId,
    };
    let response = await axiosAdapter(
      "POST",
      env.REACT_APP_URL + "debug/getFileTree",
      stringifiedData,
    );
    return response.data;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const fetchFileData = async (filepath, workspaceId, applicationId) => {
  console.log("filepath", filepath);
  try {
    const stringifiedData = {
      filePath: filepath,
      workspaceId,
      applicationId,
    };
    let response = await axiosAdapter(
      "POST",
      env.REACT_APP_URL + "debug/getFileContent",
      stringifiedData,
    );
    const convertBase = atob(response.data.fileContent);
    return convertBase;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const commitData = async (data) => {
  try {
    let response = await axiosAdapter(
      "POST",
      env.REACT_APP_URL + "debug/gitCommit",
      data,
    );
    return response.data;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const selectIcon = (iconName) => {
  switch (iconName) {
    case "js":
      return JSIcon;
    case "html":
      return HtmlIcon;
    case "css":
      return CssIcon;
    case "json":
      return JsonIcon;
    case "jsx":
      return JsxIcon;
    default:
      return DefaultIcon;
  }
};

export const logout = async (workSpaceId, applicationId) => {
  let response = await axiosAdapter(
    "POST",
    env.REACT_APP_URL + "github/logoutUserFromVC",
    {
      workSpaceId: workSpaceId,
      applicationId,
    },
  );

  return response.data;
};

const fileTypes = {
  css: "css",
  js: "javascript",
  json: "json",
  md: "markdown",
  mjs: "javascript",
  ts: "typescript",
  html: "html",
  jsx: "javascript",
  py: "python",
};
export const getFileExtention = (fileName) => {
  return fileTypes[fileName.split(".").pop()] || "plaintext";
};

export const capitalizeFirstLetter = (string) => {
  if (!string) return "Github";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const saveFileData = async (data) => {
  try {
    let response = await axiosAdapter(
      "POST",
      env.REACT_APP_URL + "debug/saveFile",
      data,
    );
    return response.data;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const cloneRepo = async (data) => {
  try {
    let response = await axiosAdapter(
      "POST",
      env.REACT_APP_URL + "debug/cloneRepo",
      data,
    );
    return response.data;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const pushRepo = async (data) => {
  let response = await axiosAdapter(
    "POST",
    env.REACT_APP_URL + "debug/gitPush",
    data,
  );
  return response.data;
};

export const gitStatus = async (data) => {
  let response = await axiosAdapter(
    "POST",
    env.REACT_APP_URL + "debug/gitStatus",
    data,
  );
  return response.data;
};

export const getPodDetails = async (data) => {
  let response = await axiosAdapter(
    "POST",
    env.REACT_APP_URL + "pipeline/getApplicationContainer",
    data,
  );
  return response.data;
};

export const getCurrentBranch = async (data) => {
  let response = await axiosAdapter(
    "POST",
    env.REACT_APP_URL + "debug/getCurrentBranch",
    data,
  );
  return response.data;
};
