import { useState, useContext } from "react";
import { ConnectorsContext } from "./WorkSpaceConnectorsMainPage";
import Typography from "../../components/common/_typography";
import { transformDataArr } from "./WorkSpaceConnectorsUtils";
import { Col, FormGroup, Label } from "reactstrap";
import ConfigureInputField from "../../components/onBoardingComponents/_configureInputField";
import NewButton from "../../components/common/_newButton";
import EditIcon from "../../../src/assets/images/edit.svg";
import DeleteIcon from "../../assets/images/delete.svg";

const TransformInputFields = () => {
  const {
    transformConnectorName,
    setTransformConnectorName,
    transformConfigParams,
    setTransformConfigParams,
    disableConfigInputs,
  } = useContext(ConnectorsContext);

  const [inputFormVisible, setInputFormVisible] = useState(false);
  const [inputValues, setInputValues] = useState({});
  const [editItem, setEditItem] = useState(null);

  // For text inputs
  const handleTextInputChange = (key, value) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [key]: value,
    }));
  };

  // For textarea inputs
  const handleTextareaChange = (key, value) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [key]: value,
    }));
  };

  const handlePlusButton = () => {
    setInputFormVisible(!inputFormVisible);
  };

  const handleCancelClick = () => {
    setInputValues({});
    setInputFormVisible(false);
  };

  const handleSaveClick = () => {
    const timestamp = new Date().getTime();

    if (editItem) {
      // Editing an existing item
      const updatedParams = transformConfigParams.map((item) => {
        if (item.id === editItem.id) {
          return { ...item, ...inputValues };
        }
        return item;
      });
      setTransformConfigParams(updatedParams);
      setEditItem(null);
    } else {
      // Adding a new item
      inputValues.id = timestamp;
      setTransformConfigParams((prevTransformConfigParams) =>
        prevTransformConfigParams.concat([inputValues]),
      );
    }

    setInputValues({});
    setInputFormVisible(!inputFormVisible);
  };

  const handleEditClick = (item) => {
    setInputValues(item);
    setEditItem(item);
    setInputFormVisible(true);
  };

  const handleRemoveClick = (id) => {
    const updatedConfigParams = transformConfigParams.filter(
      (param) => param.id !== id,
    );

    setTransformConfigParams(updatedConfigParams);
  };

  return (
    <div
      className={`transformInputFieldsContainer ${
        disableConfigInputs ? "disabled" : ""
      }`}
    >
      <FormGroup row>
        <Label for="dataSource" sm={3}>
          <Typography
            text="Name of the connector *"
            color="#FAFAFA"
            tag="p-x-small"
          />
        </Label>
        <Col sm={9}>
          <ConfigureInputField
            type="text"
            placeholder="Name of the connector"
            value={transformConnectorName}
            onChange={(e) => setTransformConnectorName(e.target.value)}
          />
          <div className="transformConfigureParametersHeader">
            <Typography
              text="Enter Transform Connector Name"
              color="#FAFAFA"
              tag="p-x-small"
            />
          </div>
        </Col>
      </FormGroup>
      <div className="transformConfigureParametersHeader">
        <Typography text="Configure Parameters" color="#FAFAFA" tag="p-large" />

        <div className="transformPlusButton" onClick={() => handlePlusButton()}>
          <div className="transformPlusButtonInner">
            <Typography text="+" color="#FAFAFA" tag="sub-head-small" />
          </div>
        </div>
      </div>

      {/* Threshold Setup Form */}
      {inputFormVisible && (
        <div className="transformThresholdSetupForm">
          {transformDataArr.map(
            ({ type, label, objKey, isRequired, placeHolder, description }) => (
              <FormGroup row key={label}>
                <Label for="dataSource" sm={3}>
                  <Typography
                    text={isRequired ? `${label}*` : label}
                    color="#FAFAFA"
                    tag="p-x-small"
                  />
                </Label>
                <Col sm={9} style={{ marginBottom: "10px" }}>
                  {type === "text" ? (
                    <ConfigureInputField
                      type={type}
                      placeholder={placeHolder}
                      value={inputValues[objKey] || ""}
                      onChange={(e) =>
                        handleTextInputChange(objKey, e.target.value)
                      }
                    />
                  ) : (
                    <div className="transformTextareaContainer">
                      <textarea
                        type="text"
                        placeholder={placeHolder}
                        value={inputValues[objKey] || ""}
                        onChange={(e) =>
                          handleTextareaChange(objKey, e.target.value)
                        }
                        className="transformTextarea"
                      />
                    </div>
                  )}

                  <div className="transformInputFieldsDescription">
                    <Typography
                      text={description}
                      color="#FAFAFA"
                      tag="p-x-small"
                    />
                  </div>
                </Col>
              </FormGroup>
            ),
          )}
          <div className="transformFormButtons">
            <NewButton
              width="150px"
              fontSize="16px"
              backgroundColor="inherit"
              margin="0px 24px 0px 0px"
              text="Cancel"
              onClick={() => handleCancelClick()}
            />
            <NewButton
              width="150px"
              fontSize="16px"
              backgroundColor="inherit"
              text="Save"
              onClick={() => handleSaveClick()}
            />
          </div>
        </div>
      )}

      {/* Threshold Config List */}
      {transformConfigParams.length &&
        transformConfigParams.map((obj) => {
          if (obj.id === editItem?.id && inputFormVisible) {
            return null; // Don't render the item being edited
          }

          return (
            <div key={obj.id} className="transformConfigListItem">
              <div className="transformConfigListItemContent">
                <p className="transformConfigListItemText">
                  {obj.inputParameter}
                </p>
              </div>

              <div className="transformConfigListItemButtons">
                <div
                  className="transformSettingsButton"
                  onClick={() => handleEditClick(obj)}
                >
                  <img
                    src={EditIcon}
                    alt=""
                    className="transformSettingsIcon"
                  />
                </div>
                <div
                  className="transformRemoveButton"
                  onClick={() => handleRemoveClick(obj.id)}
                >
                  <img src={DeleteIcon} alt="" className="transformCloseIcon" />
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default TransformInputFields;
