import React from "react";
import FlexContainer from "../common/_flexContainer";
import styled from "styled-components";
import Text from "../common/_text";
import { ReactComponent as RocketIcon } from "../../assets/images/Rocket.svg";

const FreePlanContainer = styled(FlexContainer)`
  padding: 0.277rem 0.555rem;
  gap: 0.694rem;
  border-radius: 0.277rem;
  border: 0.069rem solid #47ccd6;
  background: #47ccd614;
`;
const RocketIconStyled = styled(RocketIcon)`
  width: 0.833rem;
  height: 0.833rem;
`;

const FreePlan = () => {
  return (
    <FreePlanContainer>
      <Text $fontWeight="bold" $fontSize="0.833rem" $color="#47CCD6">
        Free Plan
      </Text>
      <RocketIconStyled />
    </FreePlanContainer>
  );
};

export default FreePlan;
