// import Login from "../pages/LandingPages/Login";
// import LandingPage from "../pages/LandingPages/LandingPage";
// import CreateAccount from "../pages/LandingPages/CreateAccount";
// import NewUserOtpVerification from "../pages/LandingPages/NewUserOtpVerification";
// import ForgetPassword from "../pages/LandingPages/ForgetPassword";
// import ForgetPasswordOtpVerification from "../pages/LandingPages/ForgetPasswordOtpVerification";
// import ResetPassword from "../pages/LandingPages/ResetPassword";
import OnboardingMainPage from "../pages/OnboardingPages/OnBoardingMainPage";
import ConnectorsMainPage from "../pages/ConnectorsPage/ConnectorsMainPage";
import SupportPage from "../pages/LandingPages/SupportPage";
import PipelinesMainPage from "../pages/Pipelines/PipelinesMainPage";
import TemplatesMainPage from "../pages/Templates/TemplatesMainPage";
import WorkSpaceConnectorsMainPage from "../pages/WorkSpaceConnectors/WorkSpaceConnectorsMainPage";
import WindowsMainPage from "../pages/Windows/WindowsMainPage";
import DocumentationMainPage from "../pages/Documentation/DocumentationMainPage";
import SupportMainPage from "../pages/Support/SupportMainPage";
// import SettingsMainPage from "../pages/Settings/SettingsMainPage";
import CondenseEditor from "../components/editorComponents/CondenseEditor";
import OauthAuthenticatePage from "../components/editorComponents/OAuthLoadingPage.jsx";
import CondenseBuildPage from "../components/buildPageComponents/CondenseBuild.jsx";
import CondenseBuildView from "../components/buildListViewComponent/CondenseBuildView.jsx";
import DeploymentConfig from "../components/deploymentPageComponents/deploymentConfig.jsx";
import CustomTransformDeployment from "../components/deploymentPageComponents/customTransformDeployment.jsx";
import GeoFenceMain from "../components/geoFenceComponents/geoFenceMain.jsx";
import ApplicationPage from "../pages/ApplicationsPage/ApplicationPage.jsx";
import NewApplicationPage from "../pages/ApplicationsPage/NewApplicationPage.jsx";
import ConfigApplicationWrapper from "../pages/ApplicationsPage/ConfigApplicationWrapper.jsx";
import BuildLogsAndConfiguration from "../components/buildApplicationTerminal/BuildLogsAndConfigurationPage.jsx";
import ConnectorInfoConfiguration from "../components/connectorInformation/ConnectorInfoConfigurationPage.jsx";
import VSCodeEditor from "../pages/ApplicationsPage/VSCodeEditor.jsx";
import RepoInformation from "../pages/ApplicationsPage/RepoInformation.jsx";
import Settings from "../pages/WorkspaceSettings/index.jsx";
import { SignIn } from "../pages/NewSignInAndSignUpPages/SignIn.jsx";
import { SignUp } from "../pages/NewSignInAndSignUpPages/signUp.jsx";
import { OtpVerification } from "../pages/NewSignInAndSignUpPages/OtpVerification.jsx";
import { ForgotPassword } from "../pages/NewSignInAndSignUpPages/ForgotPassword.jsx";
import { CreateNewAccount } from "../pages/NewSignInAndSignUpPages/CreateNewAccount.jsx";
import { ResetPasswordNew } from "../pages/NewSignInAndSignUpPages/ResetPassword.jsx";
import ConnectorsConfigurationPage from "../pages/WorkSpaceConnectors/ConnectorsConfigurationPage.jsx";

//NOTE: Add pages which are displayed after auth
//NOTE: Add pages which are displayed before auth
//TODO: Add React Suspense for Loading.

/**
 *  @type {Array}
 *  @description This Array contains all the Public Routes.
 * */
const publicRoutes = [
  // {
  //   path: "/login",
  //   component: Login,
  // },
  // {
  //   path: "/createAccount",
  //   component: CreateAccount,
  // },
  // {
  //   path: "/newUserVerification",
  //   component: NewUserOtpVerification,
  // },
  // {
  //   path: "/forgetPassword",
  //   component: ForgetPassword,
  // },
  // {
  //   path: "/forgetPasswordOtpVerification",
  //   component: ForgetPasswordOtpVerification,
  // },
  // {
  //   path: "/resetPassword",
  //   component: ResetPassword,
  // },
  {
    path: "/support",
    component: SupportPage,
  },

  {
    path: "/signIn",
    component: SignIn,
  },
  {
    path: "/signUp",
    component: SignUp,
  },
  {
    path: "/otpVerification",
    component: OtpVerification,
  },
  {
    path: "/forgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/createNewAccount",
    component: CreateNewAccount,
  },
  {
    path: "/resetPasswordPage",
    component: ResetPasswordNew,
  },
  //NOTE: This route should be at the end of all other routes.
  {
    path: "/",
    exact: true,
    component: SignIn,
  },
];

/**
 *  @type {Array}
 *  @description This Array contains all the Private Routes.
 * */
const authProtectedRoutes = [
  {
    path: "/onBoarding",
    component: OnboardingMainPage,
  },
  {
    path: "/connectorsPlayground",
    component: ConnectorsMainPage,
  },
  { path: "/pipelines", component: PipelinesMainPage },
  { path: "/templates", component: TemplatesMainPage },
  {
    path: "/connectors",
    component: WorkSpaceConnectorsMainPage,
    isSideBar: false,
  },
  { path: "/windows", component: WindowsMainPage },
  { path: "/documentation", component: DocumentationMainPage },
  { path: "/navSupport", component: SupportMainPage },
  { path: "/editor", component: CondenseEditor },
  { path: "/oauth/authorize", component: OauthAuthenticatePage },
  { path: "/build", component: CondenseBuildPage },
  { path: "/buildList", component: CondenseBuildView },
  { path: "/deploymentConfiguration", component: DeploymentConfig },
  { path: "/customDeployment", component: CustomTransformDeployment },
  { path: "/geoFenceCreation", component: GeoFenceMain },
  {
    path: "/buildLogs",
    component: BuildLogsAndConfiguration,
    isSideBar: false,
  },
  {
    path: "/connectorInformation",
    component: ConnectorInfoConfiguration,
  },
  {
    path: "/connectorConfiguration",
    exact: true,
    component: ConnectorsConfigurationPage,
  },

  { path: "/applications", component: ApplicationPage },
  { path: "/createApplication", component: NewApplicationPage },
  { path: "/configApplication", component: ConfigApplicationWrapper },
  { path: "/codeEditor", component: VSCodeEditor },
  { path: "/repoInformation", component: RepoInformation },
  { path: "/settings", component: Settings },
];

export { authProtectedRoutes, publicRoutes };
