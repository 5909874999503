import styled from "styled-components";

export const TableWrapper = styled.div`
  width: 100%;
  max-height: ${({ $maxHeight }) => $maxHeight};
  overflow: auto;
`;

export const Table = styled.table`
  width: ${({ $width }) => $width};
  table-layout: fixed;
  border-collapse: collapse;
`;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr`
  border-top: ${({ $border }) => $border};
  &:last-child td {
    padding-bottom: 0;
  }
  & td:last-child {
    padding-right: 0;
  }
`;

export const TableData = styled.td`
  padding: ${({ $padding }) => $padding};
  width: ${({ $width }) => $width};
`;
