import styled from "styled-components";

const IconButton = styled.button`
  color: inherit;
  border: ${({ $border }) => $border || "none"};
  padding: ${({ $padding }) => $padding || "0"};
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: ${({ $width }) => $width || "2.777rem"};
  height: ${({ $height }) => $height || "2.777rem"};
  border-radius: 0.555rem;
  background: ${({ $background }) => $background || "#2f2f2f"};
  &:hover {
    svg {
      path {
        stroke: ${({ $hoverIconColor }) => $hoverIconColor || "#F6F6F6"};
      }
    }
  }
  &:disabled {
    svg {
      path {
        stroke: ${({ $disabledColor }) => $disabledColor || "#777777"};
      }
    }
  }
`;

export default IconButton;
