import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import Repository from "../../assets/images/Repository.svg";
import Branch from "../../assets/images/git-branch.svg";
import LeftArrow from "../../assets/images/leftArrow.png";
import Norepository from "../../assets/images/Illustrationnorepo.svg";
import CommitCodeIcon from "../../assets/code-editor-icons/commit-code.svg";
import LogoutIcon from "../../assets/images/log-out.svg";
import GithubIcon from "../../assets/images/Github.svg";
import GitlabIcon from "../../assets/images/Gitlab.svg";
import BitBucketIcon from "../../assets/images/Bitbucket.svg";
import GenerateCodeIcon from "../../assets/code-editor-icons/generate-code.svg";
import OpenTerminalIcon from "../../assets/code-editor-icons/open-terminal.svg";
import PushCodeIcon from "../../assets/code-editor-icons/push-code.svg";
import SaveIcon from "../../assets/code-editor-icons/save.svg";
// import SelectBranchIcon from "../../assets/code-editor-icons/select-branch.svg";

import GenericSelection from "../../components/common/_dropDownComponent";
import CodeEditor from "../../components/editorComponents/CodeEditor";
import CustomAppEditorContext from "../../context/customApp/CustomAppEditorContext";
import FileExplorer from "./components/FileExplorer";
import {
  capitalizeFirstLetter,
  cloneRepo,
  commitData,
  fetchBranchData,
  fetchFolderTree,
  fetchRepoData,
  getPodDetails,
  gitStatus,
  logout,
  pushRepo,
  saveFileData,
} from "./utils";
import CodeTerminal from "../../components/editorComponents/CodeTerminal";
import GenericModal from "../../components/common/_genericModal";
import { Spinner } from "react-bootstrap";
import {
  Redirect,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import useContainerDimensions from "../../hooks/useContainerDimentions";
import FileTabs from "../../components/editorComponents/FileTabs";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import moment from "moment/moment";
import {
  StyledButtonPrimary,
  StyledButtonsecondary,
  StyledPrimaryButton2,
} from "../../components/common/_buttonNewOne";

const IMAGE_TAG_REGEX =
  /^(?:(?=[^:/]{1,253})(?!-)[a-zA-Z0-9-]{1,63}(?<!-)(?:\.(?!-)[a-zA-Z0-9-]{1,63}(?<!-))*(?::[0-9]{1,5})?\/)?((?![._-])(?:[a-z0-9._-]*)(?<![._-])(?:\/(?![._-])[a-z0-9._-]*(?<![._-]))*)(?::(?![.-])[a-zA-Z0-9_.-]{1,128})?$/;

const Container = styled.div`
  background-color: #171717;
  height: calc(100vh - 56px);
  display: flex;
  flex-direction: column;
`;

const HeaderComponent = styled.div`
  padding: 1.112rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.div`
  padding-right: 1.112rem;
  cursor: pointer;

  img {
    width: 2.225rem;
    height: 2.225rem;
  }
`;

const Title = styled.div`
  font-family: "Articulat CF Medium";
  color: #f6f6f6;
  font-size: 1.67rem;
  font-weight: bold;
`;

const Content = styled.div`
  font-family: Articulat CF Medium;
  font-size: 1.67rem;
  line-height: 2.167rem;
  color: #cccccc;
  width: 30.347rem;
`;

const ContentComponent = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  width: 100%;
  overflow: auto;
`;

const Divider = styled.div`
  width: 100%;
  border-top: 0.06rem solid #2f2f2f;
`;

const FooterComponent = styled.div`
  font-size: 0.8rem;
  font-weight: 500;
  border-top: 1px solid #52595b;
  padding: 0.55rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #1e2121;
  width: 100%;
  color: #ececec;
`;

const FooterButton = styled.div`
  font-family: "Articulat CF Medium";
  display: flex;
  margin-right: 1.041rem;
  align-items: center;
  cursor: pointer;
  text-decoration: underline;
  img {
    width: 1.112rem;
    height: 1.112rem;
    margin-right: 0.347rem;
  }
  color: ${(prop) => prop.color || "#ececec"};
  /* &:hover {
    color: #fff;
  } */
`;

const ModalSubtext = styled.p`
  font-family: "Articulat CF Medium";
  margin: 0;
  font-size: 1.25rem;
  color: #ececec;
`;

const ModalTextFieldOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (min-width: 992px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    gap: 1.112rem;
  }
`;
const ModalTextfieldContainer = styled.div`
  width: 100%;
  margin-top: 2.083rem;
`;

const ModalTextfield = styled.input`
  padding: 0.7rem;
  outline: none;
  background: inherit;
  width: 100%;
  border-radius: 0.55rem;
  border: 0.1388rem solid #909090;
  color: #ececec;
  font-size: 1.112rem;
  font-family: Articulat CF Medium;
  &::placeholder {
    font-family: Articulat CF Medium;
    color: #b0b0b0;
    font-size: 0.972rem;
  }
`;

const ModalButtonContainer = styled.div`
  padding-top: 4.167rem;
  position: static;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.39rem;
`;

const ModalStyledButton = styled.button`
  border: 1px solid #ececec;
  border-radius: 12px;
  background-color: inherit;
  padding: 5px 25px;
  color: #ececec;
  font-family: "Articulat CF Medium";
  font-size: 16px;
  font-weight: bold;
  max-width: calc(1.2 * 200px);
  min-width: calc(0.9 * 200px);
  min-height: 45px;
  &:disabled {
    opacity: 0.5;
  }
`;

const ModalWarningText = styled.p`
  font-family: "Articulat CF Medium";
  margin: 0;
  font-size: 14px;
  color: #ea3a3a;
`;

const StyledButton = styled.button`
  border: ${(props) =>
    props.btnType ? "1px solid transparent" : "1px solid #ececec"};
  border-radius: 12px;
  background-color: ${(props) =>
    props.btnType === "error" ? "#EA3A3A" : "inherit"};
  padding: 5px 25px;
  min-height: 45px;
  max-width: 204px;
  min-width: calc(0.8 * 200px);
  color: #ececec;
  font-family: Articulat CF Medium;
  font-size: 16px;
  font-weight: bold;

  &:disabled {
    opacity: 0.5;
  }
`;

const ModalTitle = styled.div`
  font-family: Articulat CF Medium;
  font-size: 24px;
  font-weight: 500;
  line-height: 15.6px;
  display: flex;
  align-items: center;
  color: #f6f6f6;
`;
const BorderLine = styled.div`
  border: 1px solid #9090903d;
`;
const ModalDescription = styled.div`
  font-family: Articulat CF Medium;
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  padding-top: 24px;
  display: flex;
  align-items: center;
  color: #ececec;
`;

const InputContainer = styled.div`
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const InputField = styled.input`
  padding: 16px 12px;
  border-radius: 12px;
  border: 2px solid #909090;
  background-color: #2f2f2f;
  font-family: "Articulat CF Medium";
  font-size: 14px;
  font-weight: 500;
  line-height: 15.6px;
  color: #fafafa;
  &::placeholder {
    color: #b0b0b0;
  }
`;

const RightFooterContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  gap: 24px;
  padding: 0 16px;
`;

const LeftFooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
  width: 100%;
  gap: 24px;
  padding: 0 16px;
`;

const Username = styled.div`
  font-family: "Articulat CF Medium";
  font-size: 12px;
  line-height: 15.6px;
  color: #f6f6f6;
  font-weight: bold;
`;

const UsernameContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

function ConfigApplication() {
  const history = useHistory();
  const location = useLocation();
  const [workSpaceId, setWorkSpaceId] = useState(
    parseInt(localStorage.getItem("selectedWorkSpaceId")),
  );
  const [selectedRepo, setSelectedRepo] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [allRepos, setAllRepos] = useState([]);
  const [gitUserName, setGitUserName] = useState(null);
  const [allBranches, setAllBranches] = useState([]);
  const [folderTree, setFolderTree] = useState(null);
  const [showCommitModal, setShowCommitModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modifiedFiles, setModifiedFiles] = useState([]);
  const [commitMessage, setCommitMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [bitbucketWorkSpaceName, setBitbucketWorkSpaceName] = useState(null);
  const [lastSavedTimeStamp, setLastSavedTimeStamp] = useState(null);

  const { customAppEditorData, updateCustomAppEditorData } = useContext(
    CustomAppEditorContext,
  );
  const { showMessage } = useContext(SnackbarContext);

  const [showBuildModal, setShowBuildModal] = useState(false);
  const [redirectToBuildPage, setRedirectToBuildPage] = useState(false);
  const [imageName, setImageName] = useState("");
  const [tagName, setTagName] = useState("");
  const [isImageTagValid, setImageTagValidity] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [podName, setPodName] = useState("");

  useEffect(() => {
    if (workSpaceId === null || workSpaceId === undefined) {
      console.log("WorkSpace Id must exist: ", workSpaceId);
      showMessage("WorkSpace Id must exist", "error");
    }

    if (location.state?.applicationId) {
      (async () => {
        try {
          let podData = await getPodDetails({
            applicationId: location.state.applicationId,
          });
          if (podData && podData.status === "Success") {
            let match = podData.data.podName.match(
              /devel-container-deployment-([^-\s]+)/,
            );
            if (match) {
              setPodName(match[1]);
            } else {
              showMessage("Could not find the pod name", "error");
            }
          } else {
            showMessage("Error fetching pod details", "error");
          }
        } catch (err) {
          console.log("getApplicationContainer", err);
          showMessage("Error fetching pod details", "error");
        }
      })();
    }
    fetchRepoData(workSpaceId, location.state?.applicationId)
      .then((res) => {
        setAllRepos(res.allRepos);
        setGitUserName(res.gitUsername);
      })
      .catch((err) => {
        console.error("Error:", err);
        showMessage("Error fetching repos", "error");
      });
    const saveInterval = setInterval(() => {
      handleSave();
    }, 300000);

    return () => clearInterval(saveInterval);
  }, []);

  useEffect(() => {
    updateCustomAppEditorData({
      ...customAppEditorData,
      editorFiles: {},
    });
    setFolderTree(null);
    const fetchData = async () => {
      if (selectedRepo) {
        const { repoId, repoName } = selectedRepo;
        try {
          const branches = await fetchBranchData(
            gitUserName,
            repoName,
            repoId,
            bitbucketWorkSpaceName,
            workSpaceId,
            location.state?.applicationId,
          );
          if (branches) {
            setAllBranches(branches);
          } else {
            setAllBranches([]);
          }
        } catch (error) {
          console.error("Error fetching branches:", error);
          showMessage("Error fetching branches", "error");
          setAllBranches([]);
        }
      }
    };

    fetchData();
  }, [selectedRepo, gitUserName]);

  useEffect(() => {
    const fetchData = async () => {
      if (gitUserName && selectedRepo && selectedBranch) {
        const reqObj = {
          username: gitUserName,
          repoName: selectedRepo.repoName,
          branchName: selectedBranch.branchName,
          workspaceId: workSpaceId,
          applicationId: location.state?.applicationId,
          workspaceName: bitbucketWorkSpaceName,
        };

        try {
          const response = await cloneRepo(reqObj);
          if (response?.data?.path) {
            const folderTreeReqObj = {
              workspaceId: workSpaceId,
              applicationId: location.state?.applicationId,
            };
            const res = await fetchFolderTree(folderTreeReqObj);
            setFolderTree(res.folderTree);
          }
        } catch (error) {
          console.error("Error fetching folder tree:", error);
          showMessage("Error fetching folder tree", "error");
          setFolderTree(null);
        }
      }
    };

    fetchData();
  }, [gitUserName, selectedRepo, selectedBranch, workSpaceId, setFolderTree]);

  const editorContainerRef = React.useRef(null);
  const editorContainerDimensions = useContainerDimensions(editorContainerRef);

  const [editorDimensions, setEditorDimensions] = useState({
    height: 0,
    width: 0,
  });
  const [terminalDimensions, setTerminalDimensions] = useState({
    height: 0,
    width: 0,
  });

  useEffect(() => {
    // console.log("Dimensions: ", editorContainerDimensions);
    if (customAppEditorData.showTerminal) {
      setTerminalDimensions({
        height: 300,
        width: editorContainerDimensions.width,
      });
      setEditorDimensions({
        height: editorContainerDimensions.height - 300,
        width: editorContainerDimensions.width,
      });
    } else {
      setEditorDimensions({
        height: editorContainerDimensions.height,
        width: editorContainerDimensions.width,
      });
      setTerminalDimensions({
        height: 0,
        width: editorContainerDimensions.width,
      });
    }
  }, [editorContainerDimensions, customAppEditorData.showTerminal]);

  // Making sure that this page is not opened directly through URL
  if (!Boolean(location.state?.isRedirected))
    return <Redirect to={{ pathname: "/" }} />;

  const handleSelectRepo = (item) => {
    setSelectedRepo(item);
    setBitbucketWorkSpaceName(item?.workspaceName);
    setSelectedBranch(null);
  };

  const handleSelectBranch = (item) => {
    setSelectedBranch(item);
  };

  const filterFunction = (items, filter) => {
    return items.filter((item) =>
      item.repoName.toLowerCase().includes(filter.toLowerCase()),
    );
  };

  const filterBranchFunction = (items, filter) => {
    return items.filter((item) =>
      item.branchName.toLowerCase().includes(filter.toLowerCase()),
    );
  };

  const checkValidDataAvailableForEditor = () => {
    if (
      selectedRepo &&
      selectedBranch &&
      Object.keys(customAppEditorData.editorFiles).length > 0 &&
      (customAppEditorData.selectedFile !== null ||
        customAppEditorData.selectedFile !== undefined)
    )
      return true;

    return false;
  };

  const toggleTerminal = () => {
    console.log("toggleTerminal: ", customAppEditorData.showTerminal);
    if (checkValidDataAvailableForEditor()) {
      if (customAppEditorData.showTerminal) {
        updateCustomAppEditorData({
          ...customAppEditorData,
          showTerminal: false,
        });
      } else {
        updateCustomAppEditorData({
          ...customAppEditorData,
          showTerminal: true,
        });
      }
    }
  };

  const handleCommitModal = () => {
    const modifiedFiles = Object.entries(customAppEditorData.editorFiles)
      .filter(([fileName, fileData]) => fileData.isModified)
      .map(([fileName, fileData]) => ({
        content: fileData.content,
        path: fileData.path.replace("root/", ""),
      }));

    setModifiedFiles(modifiedFiles);
    if (modifiedFiles.length === 0) {
      setModalTitle("No files to commit.");
      setShowCommitModal(true);
      return;
    }

    setModalTitle("Are you sure you want to commit these files?");
    setShowCommitModal(true);
  };

  const handleCommit = async () => {
    let data = {
      commitMessage: commitMessage,
      applicationId: location.state?.applicationId,
    };
    const response = await commitData(data);
    setLoading(true);
    if (response && response.status === "Success") {
      setCommitMessage("");
      setShowCommitModal(false);
      setModifiedFiles([]);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const toggleGenericModal = () => {
    setShowCommitModal(false);
  };

  const toggleLogoutModal = () => {
    setShowLogoutModal(!showLogoutModal);
  };

  const handleLogOut = async () => {
    try {
      await logout(workSpaceId, location.state?.applicationId);
      history.push("/applications");
    } catch (err) {
      console.error("Error:", err);
    }
  };

  // Redirect to Build page where all the require props are collected for build process and user clicks build in the Modal
  if (redirectToBuildPage) {
    return (
      <Redirect
        to={{
          pathname: "/buildLogs",
          state: {
            isRedirected: true,
            imageName: imageName,
            tagName: tagName,
            repoName: selectedRepo.repoName,
            branch: selectedBranch.branchName,
            username: gitUserName,
            commitName: commitMessage,
            applicationId: location.state?.applicationId,
          },
        }}
      />
    );
  }

  const getIcons = (item) => {
    switch (item) {
      case "github":
        return GithubIcon;
      case "bitbucket":
        return BitBucketIcon;
      case "gitlab":
        return GitlabIcon;
      default:
        return GithubIcon;
    }
  };

  const handleSave = async () => {
    console.log("Save");
    const filesToSave = Object.entries(customAppEditorData.editorFiles)
      .filter(([fileName, fileData]) => fileData.isModified)
      .map(([fileName, fileData]) => {
        const utf8encoder = new TextEncoder();
        const byteArray = utf8encoder.encode(fileData.content);
        const byteString = Array.from(byteArray, (byte) =>
          String.fromCharCode(byte),
        ).join("");
        const base64Content = btoa(byteString);

        return {
          data: base64Content,
          filePath: fileData.path.replace("root/", ""),
        };
      });

    const args = {
      workspaceId: workSpaceId,
      applicationId: location.state?.applicationId,
      files: filesToSave,
    };

    const response = await saveFileData(args);
    if (response && response.status === "Success") {
      setLastSavedTimeStamp(Date.now());
    }
    console.log("response", response);
  };

  const handlePushClick = async () => {
    try {
      const reqObj = {
        applicationId: location.state?.applicationId,
      };
      const response = await pushRepo(reqObj);
      if (response && response.status === "Success") {
        showMessage("Code pushed successfully", "success");
      }
    } catch (err) {
      console.log(err);
      showMessage("Failed to push the code to remote repository");
    }
  };

  return (
    <>
      <Container>
        <HeaderComponent>
          <TitleContainer>
            <Logo onClick={() => history.push("/createApplication")}>
              <img src={LeftArrow} alt="arrow" />
            </Logo>
            <Title>Configure your New Application</Title>
          </TitleContainer>
          {/* <ModalStyledButton
            style={{ backgroundColor: "#47CCD6" }}
            disabled={
              !Boolean(gitUserName) ||
              !Boolean(selectedRepo) ||
              !Boolean(selectedBranch) ||
              !Boolean(folderTree?.items["Dockerfile"])
            }
            onClick={async () => {
              try {
                let status = await gitStatus({
                  applicationId: location.state?.applicationId,
                });
                if (status) {
                  setShowBuildModal(true);
                } else {
                  showMessage(
                    "Seems like your codebase has uncommitted code or isn't in sync with the remote repository",
                  );
                }
              } catch (err) {
                console.log(err);
                showMessage(
                  "Failed to obtain the current status of the codebase",
                  "error",
                );
              }
            }}
          >
            Publish Application
          </ModalStyledButton> */}
          <StyledButtonPrimary
            style={{ width: "14.167rem" }}
            disabled={
              !Boolean(gitUserName) ||
              !Boolean(selectedRepo) ||
              !Boolean(selectedBranch) ||
              !Boolean(folderTree?.items["Dockerfile"])
            }
            onClick={async () => {
              try {
                let status = await gitStatus({
                  applicationId: location.state?.applicationId,
                });
                if (status) {
                  setShowBuildModal(true);
                } else {
                  showMessage(
                    "Seems like your codebase has uncommitted code or isn't in sync with the remote repository",
                  );
                }
              } catch (err) {
                console.log(err);
                showMessage(
                  "Failed to obtain the current status of the codebase",
                  "error",
                );
              }
            }}
          >
            Publish Application
          </StyledButtonPrimary>
        </HeaderComponent>
        <BorderLine />
        <ContentComponent>
          <div className="d-flex h-100 w-100">
            <div
              className="h-100 overflow-auto d-flex flex-column"
              style={{ minWidth: "20.83rem", width: "30%" }}
            >
              <div style={{ padding: "0 1.112rem" }}>
                <GenericSelection
                  icon={Repository}
                  dropdownItems={allRepos}
                  selectedItem={selectedRepo}
                  title="Select Repository"
                  placeholder="Search for Repository"
                  onSelect={handleSelectRepo}
                  nameFunction={(item) => item.repoName}
                  filterFunction={filterFunction}
                  // openOnHover={true}
                />
                <GenericSelection
                  icon={Branch}
                  dropdownItems={allBranches}
                  selectedItem={selectedBranch}
                  title="Select Branch"
                  placeholder="Search for Branch"
                  loading={
                    selectedRepo && allBranches.length === 0 ? true : false
                  }
                  onSelect={handleSelectBranch}
                  nameFunction={(item) => item.branchName}
                  filterFunction={filterBranchFunction}
                  disable={allBranches?.length === 0}
                  // openOnHover={true}
                />
              </div>
              <Divider />
              <div style={{ flexGrow: 1, flexBasis: 0, overflow: "auto" }}>
                <FileExplorer
                  data={folderTree}
                  workSpaceId={workSpaceId}
                  repoName={selectedRepo?.repoName}
                  applicationId={location.state?.applicationId}
                  loading={selectedBranch && !folderTree ? true : false}
                />
              </div>
            </div>
            <BorderLine style={{ display: "flex", flexDirection: "column" }} />
            <div
              className="h-100 w-100 overflow-hidden"
              ref={editorContainerRef}
            >
              {checkValidDataAvailableForEditor() ? (
                <>
                  <FileTabs />
                  <CodeEditor
                    height={editorDimensions.height - 70}
                    width={editorDimensions.width}
                  />
                  <CodeTerminal
                    height={terminalDimensions.height}
                    width={terminalDimensions.width}
                    show={customAppEditorData.showTerminal}
                    onToggle={toggleTerminal}
                    podName={podName}
                  />
                </>
              ) : (
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    padding: "1.67rem 2.225rem 1.67rem 1.67rem",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Title
                    style={{
                      fontSize: "48px",
                      lineHeight: "62.4px",
                    }}
                  >
                    {" "}
                    Get Started...
                  </Title>
                  <Content style={{ paddingTop: "16px" }}>
                    Select Repository and Branch to build and publish your first
                    custom application!
                  </Content>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      paddingTop: "27px",
                      height: "calc(100% - 149px)",
                    }}
                  >
                    <img src={Norepository} alt="no repo" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </ContentComponent>
        {checkValidDataAvailableForEditor() && (
          <FooterComponent>
            <RightFooterContainer className="d-flex align-items-center flex-nowrap w-100  px-2">
              <UsernameContainer>
                <img
                  src={getIcons(location.state?.gitProvider)}
                  alt="user"
                  width={16}
                  height={16}
                />
                <Username>{gitUserName}</Username>
              </UsernameContainer>
              <FooterButton onClick={() => handlePushClick()}>
                <img src={PushCodeIcon} alt="push code" /> Push Code
              </FooterButton>
              <FooterButton onClick={() => handleCommitModal()}>
                <img src={CommitCodeIcon} alt="commit code" /> Commit Code
              </FooterButton>
              <FooterButton color="red" onClick={() => toggleLogoutModal()}>
                <img src={LogoutIcon} alt="Logout Git" /> Logout Git
              </FooterButton>
            </RightFooterContainer>
            <LeftFooterContainer>
              <FooterButton onClick={() => toggleTerminal()}>
                <img src={OpenTerminalIcon} alt="terminal" />
                {customAppEditorData.showTerminal
                  ? "Close Terminal"
                  : "Open Terminal"}
              </FooterButton>
              <FooterButton>
                <img src={GenerateCodeIcon} alt="generate code" /> Generate Code
              </FooterButton>
              <FooterButton color="#47CCD6" onClick={() => handleSave()}>
                <img
                  src={SaveIcon}
                  style={{
                    color: "#47CCD6",
                  }}
                  alt="save"
                />{" "}
                Save
              </FooterButton>
              {lastSavedTimeStamp && (
                <FooterButton>
                  <div
                    style={{
                      background: "#47CCD6",
                      width: "10px",
                      height: "10px",
                      marginRight: "8px",
                      borderRadius: "50%",
                    }}
                  ></div>
                  Saved {moment(lastSavedTimeStamp).fromNow()}
                </FooterButton>
              )}
            </LeftFooterContainer>
          </FooterComponent>
        )}
        {!checkValidDataAvailableForEditor() && (
          <FooterComponent>
            <RightFooterContainer className="d-flex align-items-center flex-nowrap w-100  px-2">
              <UsernameContainer>
                <div>
                  <img
                    src={getIcons(location.state?.gitProvider)}
                    alt="user"
                    width={16}
                    height={16}
                  />
                </div>
                <Username>{gitUserName}</Username>
              </UsernameContainer>
              <FooterButton color="red" onClick={() => toggleLogoutModal()}>
                <img src={LogoutIcon} alt="Logout Git" /> Logout Git
              </FooterButton>
            </RightFooterContainer>
          </FooterComponent>
        )}
        <GenericModal show={showCommitModal} onClose={toggleGenericModal}>
          <ModalTitle>{modalTitle}</ModalTitle>
          <InputContainer>
            <InputField
              type="text"
              value={commitMessage}
              onChange={(e) => {
                setCommitMessage(e.target.value);
              }}
            />
          </InputContainer>
          <ModalButtonContainer>
            {/* <StyledButton onClick={() => toggleGenericModal()}>
              Cancel
            </StyledButton> */}
            <StyledButtonsecondary
              onClick={() => toggleGenericModal()}
              style={{ width: "204px" }}
            >
              Cancel
            </StyledButtonsecondary>
            {/* <StyledButton
              style={{ backgroundColor: "#47CCD6" }}
              disabled={
                modalTitle === "No files to commit." ||
                commitMessage.length === 0
              }
              onClick={() => handleCommit()}
            >
              {loading ? <Spinner size="12px" /> : "Commit Code"}
            </StyledButton> */}
            <StyledButtonPrimary
              style={{ width: "204px" }}
              disabled={
                modalTitle === "No files to commit." ||
                commitMessage.length === 0
              }
              onClick={() => handleCommit()}
            >
              {loading ? <Spinner size="12px" /> : "Commit Code"}
            </StyledButtonPrimary>
          </ModalButtonContainer>
        </GenericModal>
      </Container>
      <GenericModal
        show={showBuildModal}
        title={"Add Details to Build your Application"}
        onClose={() => setShowBuildModal(false)}
      >
        <ModalSubtext>
          One last step before we can build your application. We need a few
          details before we can build your application to start using this.
        </ModalSubtext>
        <ModalTextFieldOuterContainer>
          <ModalTextfieldContainer>
            <ModalTextfield
              placeholder="Enter the Name of Image *"
              value={imageName}
              onChange={(e) => {
                setImageName(e.target.value);
                setImageTagValidity(
                  IMAGE_TAG_REGEX.test(e.target.value + ":" + tagName),
                );
              }}
            />
          </ModalTextfieldContainer>
          <ModalTextfieldContainer>
            <ModalTextfield
              placeholder="Enter the Value of Tag *"
              value={tagName}
              onChange={(e) => {
                setTagName(e.target.value);
                setImageTagValidity(
                  IMAGE_TAG_REGEX.test(imageName + ":" + e.target.value),
                );
              }}
            />
          </ModalTextfieldContainer>
        </ModalTextFieldOuterContainer>
        {!isImageTagValid && (imageName.length || tagName.length) ? (
          <ModalTextfieldContainer
            style={{ marginTop: "16px", padding: "0px 10px" }}
          >
            <ModalWarningText>
              Combination of Image and Tag is not in accordance with standard
              docker image:tag format
            </ModalWarningText>
          </ModalTextfieldContainer>
        ) : null}
        <ModalButtonContainer>
          {/* <ModalStyledButton onClick={() => setShowBuildModal(false)}>
            Cancel
          </ModalStyledButton> */}
          <StyledButtonsecondary
            onClick={() => setShowBuildModal(false)}
            style={{ width: "204px" }}
          >
            Cancel
          </StyledButtonsecondary>
          {/* <ModalStyledButton
            style={{ backgroundColor: "#47CCD6" }}
            disabled={
              !isImageTagValid || imageName.length === 0 || tagName.length === 0
            }
            onClick={() => setRedirectToBuildPage(true)}
          >
            Build Application
          </ModalStyledButton> */}
          <StyledButtonPrimary
            style={{ width: "204px" }}
            disabled={
              !isImageTagValid || imageName.length === 0 || tagName.length === 0
            }
            onClick={() => setRedirectToBuildPage(true)}
          >
            Build Application
          </StyledButtonPrimary>
        </ModalButtonContainer>
      </GenericModal>
      <GenericModal show={showLogoutModal} onClose={toggleLogoutModal}>
        <ModalTitle>
          Are you sure you want to disconnect{" "}
          {capitalizeFirstLetter(location?.state?.gitProvider) ||
            "Version Control"}
          ?
        </ModalTitle>
        <ModalDescription>
          If you disconnect your{" "}
          {capitalizeFirstLetter(location?.state?.gitProvider) ||
            "Version Control"}{" "}
          account, we won't be able to access your Repositories and Branches.
          You will have to connect your account again in order to create
          applications.
        </ModalDescription>
        <ModalButtonContainer>
          {/* <StyledButton onClick={() => toggleLogoutModal()}>
            Cancel
          </StyledButton> */}
          <StyledButtonsecondary
            onClick={() => toggleLogoutModal()}
            style={{ width: "204px" }}
          >
            Cancel
          </StyledButtonsecondary>
          {/* <StyledButton
            btnType="error"
            // style={{ backgroundColor: "#EA3A3A" }}
            onClick={() => handleLogOut()}
          >
            Disconnect{" "}
            {capitalizeFirstLetter(location?.state?.gitProvider) ||
              "Version Control"}
          </StyledButton> */}
          <StyledPrimaryButton2
            btnType="error"
            onClick={() => handleLogOut()}
            style={{ width: "204px" }}
          >
            Disconnect{" "}
            {capitalizeFirstLetter(location?.state?.gitProvider) ||
              "Version Control"}
          </StyledPrimaryButton2>
        </ModalButtonContainer>
      </GenericModal>
    </>
  );
}

export default ConfigApplication;
