import React from "react";
import styled from "styled-components";
import Text from "../../../../components/common/_text";
import FlexContainer from "../../../../components/common/_flexContainer";
import Tooltip from "../../../../components/common/_tooltip";

const SquareChip = styled(FlexContainer)`
  width: 2.777rem;
  height: 2.777rem;
  border-radius: 0.277rem;
  background: #2f2f2f;
`;
const FlexItem = styled.div`
  width: 100%;
`;

const UserDetailsCell = ({ row }) => {
  return (
    <FlexContainer $width="100%" $justifyContent="flex-start" $gap="0.833rem">
      <SquareChip $justifyContent="center" $alignContent="center">
        <Text $fontWeight="bold" $fontSize="1.666rem" $color="#777777">
          {row.fullName.substring(0, 1).toUpperCase()}
        </Text>
      </SquareChip>
      <FlexContainer $width="calc(100% - 2.777rem - 0.833rem)" $gap="0.277rem">
        <FlexItem>
          <Tooltip title={row.fullName}>
            <Text $maxWidth="min-content" $fontWeight="bold" $noWrap>
              {row.fullName}
            </Text>
          </Tooltip>
        </FlexItem>
        <FlexItem>
          <Tooltip title={row.emailId}>
            <Text
              $maxWidth="min-content"
              $fontSize="0.833rem"
              $color="#777777"
              $noWrap
            >
              {row.emailId}
            </Text>
          </Tooltip>
        </FlexItem>
      </FlexContainer>
    </FlexContainer>
  );
};

export default UserDetailsCell;
