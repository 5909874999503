import styled from "styled-components";

const BreadcrumbsContainer = styled.div`
  border-left: 1px solid #2f2f2f;
  width: 100%;
  display: flex;
  padding: 6px 10px;
  flex-direction: row;
  background-color: #0d0d0d;
  border-top: 1px solid #2f2f2f;
  margin-top: -1px;
  font-size: 15px;
`;

const FileBreadcrumbs = ({ path }) => {
  const breadcrumbs = path.split("/");

  return (
    <BreadcrumbsContainer>
      {breadcrumbs.length > 1 &&
        breadcrumbs.map((breadcrumb, index) => {
          if (index !== breadcrumbs.length - 1) {
            return (
              <div key={index} className="px-2" style={{ color: "#777777" }}>
                {breadcrumb} &gt;{" "}
              </div>
            );
          } else {
            return (
              <div key={index} className="px-2" style={{ color: "#ececec" }}>
                {breadcrumb}
              </div>
            );
          }
        })}
    </BreadcrumbsContainer>
  );
};

export default FileBreadcrumbs;
