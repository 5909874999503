import React, { useContext, useMemo, useState } from "react";
import Dialog from "../../../components/common/_dialog";
import FlexContainer from "../../../components/common/_flexContainer";
import SearchBar from "./SearchBar";
import Text from "../../../components/common/_text";
import axiosAdapter from "../../../utils";
import { SnackbarContext } from "../../../layouts/Context/snackBarContext";
import Divider from "../../../components/common/_divider";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import WorkspaceMemberDetailCell from "./EditWorkspaceMembersTableComponents/WorkspaceMemberDetailCell";
import WorkspaceMemberPermissionCell from "./EditWorkspaceMembersTableComponents/WorkspaceMemberPermissionCell";
import WorkspaceMemberRevokeOrAddCell from "./EditWorkspaceMembersTableComponents/WorkspaceMemberRevokeOrAddCell";
import {
  Table,
  TableBody,
  TableData,
  TableRow,
  TableWrapper,
} from "../../../components/common/_table";
import { ReactComponent as InfoIcon } from "../../../assets/images/info_icon.svg";
import styled from "styled-components";
import {
  StyledButtonPrimary,
  StyledButtonsecondary,
} from "../../../components/common/_buttonNewOne";
import { env } from "../../../env";

const InfoIconStyled = styled(InfoIcon)`
  flex: none;
  width: 1.666rem;
  height: 1.666rem;
`;

const WorkspaceEditMembersDialog = ({
  isEditWorkspaceMembersDialogOpen,
  setIsEditWorkspaceMembersDialogOpen,
  selectedWorkspaceForEdit,
  refetchWorkspaces,
}) => {
  const [workspaceMembersData, setWorkspaceMembersData] = useState({
    membersCount: 0,
    membersList: [],
  });
  const [workspaceMembersDataLoading, setWorkspaceMembersDataLoading] =
    useState(true);
  const [workspaceMembersDataError, setWorkspaceMembersDataError] =
    useState(false);
  const { showMessage } = useContext(SnackbarContext);

  const columnHelper = useMemo(() => createColumnHelper(), []);
  const columns = useMemo(
    () => [
      columnHelper.accessor("fullName", {
        id: "workspaceMemberDetail",
        cell: (props) => <WorkspaceMemberDetailCell row={props.row.original} />,
        filterFn: "includesString",
        meta: { width: "calc(100% - 13.888rem - 2.777rem)" },
      }),
      columnHelper.display({
        id: "workspaceMemberPrivilege",
        cell: (props) => (
          <WorkspaceMemberPermissionCell
            row={props.row.original}
            setWorkspaceMembersData={setWorkspaceMembersData}
          />
        ),
        meta: { width: "13.888rem" },
      }),
      columnHelper.display({
        id: "workspaceMemberRevokeOrAdd",
        cell: (props) => (
          <WorkspaceMemberRevokeOrAddCell
            row={props.row.original}
            setWorkspaceMembersData={setWorkspaceMembersData}
          />
        ),
        meta: { width: "2.777rem" },
      }),
    ],
    [columnHelper],
  );
  const [columnFilters, setColumnFilters] = useState([]);
  const table = useReactTable({
    data: workspaceMembersData.membersList,
    columns,
    state: {
      columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });
  const workspaceMemberSearchValue = table
    .getColumn("workspaceMemberDetail")
    .getFilterValue();
  const setWorkspaceMemberSearchValue = table.getColumn(
    "workspaceMemberDetail",
  ).setFilterValue;

  useMemo(() => {
    const getAllWorkspaceMembers = async () => {
      const stringifiedRequestBody = JSON.stringify({
        workspaceId: parseInt(selectedWorkspaceForEdit.id),
      });

      try {
        const response = await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "user-management/getAllWorkspaceMembers",
          stringifiedRequestBody,
        );
        setWorkspaceMembersDataLoading(false);
        setWorkspaceMembersData(response.data.data);
      } catch (err) {
        setWorkspaceMembersDataError(true);
        setWorkspaceMembersDataLoading(false);
        if (err.response?.data?.remarks) {
          showMessage(err.response.data.remarks);
        } else {
          showMessage("Unable to fetch workspace members!");
        }
      }
    };
    getAllWorkspaceMembers();
  }, [selectedWorkspaceForEdit.id, showMessage]);

  const setAllWorkspaceMembers = async () => {
    const stringifiedRequestBody = JSON.stringify({
      workspaceId: parseInt(selectedWorkspaceForEdit.id),
      userList: workspaceMembersData.membersList.map((workspaceMember) => ({
        userId: parseInt(workspaceMember.userId),
        userPermission: workspaceMember.userPermission,
      })),
    });

    try {
      const response = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "user-management/setAllWorkspaceMembers",
        stringifiedRequestBody,
      );
      showMessage(response.data.remarks, "success");
      setIsEditWorkspaceMembersDialogOpen(false);
      refetchWorkspaces();
    } catch (err) {
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage("Unable to edit workspace members!");
      }
    }
  };

  const DialogContent = (
    <FlexContainer $gap="1.111rem" $padding="2.222rem">
      <FlexContainer
        $width="calc(100% - 17.361rem - 1.111rem)"
        $flexDirection="column"
        $justifyContent="flex-start"
        $alignItems="flex-start"
        $gap="0.555rem"
      >
        <Text $width="100%" $fontWeight="bold" $fontSize="1.666rem" $noWrap>
          Edit Workspace Members
        </Text>
        {!workspaceMembersDataLoading && !workspaceMembersDataError && (
          <Text $width="100%" $fontSize="0.833rem" $color="#47CCD6" $noWrap>{`${
            workspaceMembersData.membersCount
          } ${
            workspaceMembersData.membersCount !== 1 ? "Members" : "Member"
          }`}</Text>
        )}
      </FlexContainer>
      {!workspaceMembersDataLoading && !workspaceMembersDataError ? (
        <>
          <SearchBar
            value={workspaceMemberSearchValue}
            setValue={setWorkspaceMemberSearchValue}
            placeholderText="Search for Members"
            border="0.104rem solid #4B4B4B"
            color="#B0B0B0"
            width="17.361rem"
          />
          {table.getRowModel().rows.length ? (
            <TableWrapper $maxHeight="20.493rem">
              <Table $width="max(37.5rem, 100%)">
                <TableBody>
                  {table.getRowModel().rows.map((row) => (
                    <TableRow key={row.id} $border="0.104rem solid #4B4B4B">
                      {row.getVisibleCells().map((cell) => (
                        <TableData
                          key={cell.id}
                          $width={cell.column.columnDef.meta.width}
                          $padding="1.111rem 1.111rem 1.111rem 0"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </TableData>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableWrapper>
          ) : (
            <>
              <Divider $thickness="0.104rem" $color="#4B4B4B" />
              <Text $width="100%" $textAlign="center">
                {workspaceMembersData.membersList.length
                  ? "No workspace users found for the given search criteria"
                  : "No workspace users are created in this organisation"}
              </Text>
              <Divider $thickness="0.104rem" $color="#4B4B4B" />
            </>
          )}
          <FlexContainer
            $width="100%"
            $flexWrap="nowrap"
            $justifyContent="flex-start"
            $gap="0.555rem"
          >
            <InfoIconStyled />
            <Text $fontSize="0.972rem" $fontStyle="italic" $color="#47CCD6">
              These changes will apply only to this workspace. To change access
              at the organization level, review users & roles.
            </Text>
          </FlexContainer>
          <FlexContainer
            $width="100%"
            $justifyContent="flex-end"
            $gap="1.111rem"
          >
            <StyledButtonsecondary
              width="14.166rem"
              onClick={() => setIsEditWorkspaceMembersDialogOpen(false)}
            >
              Cancel
            </StyledButtonsecondary>
            <StyledButtonPrimary
              width="14.166rem"
              onClick={setAllWorkspaceMembers}
            >
              Save Changes
            </StyledButtonPrimary>
          </FlexContainer>
        </>
      ) : (
        <>
          <Divider $thickness="0.104rem" $color="#4B4B4B" />
          <Text $width="100%" $textAlign="center">
            {workspaceMembersDataLoading ? "Loading" : "Failed to obtain data"}
          </Text>
          <Divider $thickness="0.104rem" $color="#4B4B4B" />
          <FlexContainer
            $width="100%"
            $justifyContent="flex-end"
            $gap="1.111rem"
          >
            <StyledButtonsecondary
              width="14.166rem"
              onClick={() => setIsEditWorkspaceMembersDialogOpen(false)}
            >
              Close
            </StyledButtonsecondary>
          </FlexContainer>
        </>
      )}
    </FlexContainer>
  );

  return (
    <Dialog
      content={DialogContent}
      open={isEditWorkspaceMembersDialogOpen}
      onOpenChange={setIsEditWorkspaceMembersDialogOpen}
    />
  );
};

export default WorkspaceEditMembersDialog;
