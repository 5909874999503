import GeoFenceLeaflet from "./geoFenceLeaflet";
const GeoFenceMain = ({ configIdForGeoFence }) => {
  return (
    <div
      style={{
        height: "calc(100vh - 200px)",
        backgroundColor: "#171717",
        overflow: "auto",
      }}
    >
      {/* <div style={{ padding: "20px" }}>
        <h1 style={{ color: "white" }}>GeoFence Mapper</h1>
      </div> */}
      <GeoFenceLeaflet configIdForGeoFence={configIdForGeoFence} />
      {/* TODO: 1. Map Integration*/}
      {/* TODO: 1. Button to set this map component*/}
    </div>
  );
};

export default GeoFenceMain;
