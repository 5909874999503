import { Redirect } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { env } from "../../env";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import axiosAdapter from "../../utils";
import styled from "styled-components";
import loadingAnimation from "../../assets/images/Loading Animation.gif";

const LoadingAnimation = styled.img`
  width: 100%;
  bottom: 4px;
`;
const ScrollAnimationBar = styled.div`
  width: 100%;
  height: 4px;
  background-color: #2f2f2f;
  border-radius: 2px;
  box-shadow: -8px 4px 9px 0px #2f2f2f;
  position: absolute;
  bottom: 0;
  left: 0;
`;
const ParentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(90vw + 200px);
  height: 100vh;
  background-color: #171717;
`;
const AnimationContainer = styled.div`
  position: relative;
  width: 240px;
`;
const OauthAuthenticatePage = () => {
  const { showMessage } = useContext(SnackbarContext); // Snackbar
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isCodeNotPresent, setCodeIsNotPresent] = useState(false);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const authCode = urlParams.get("code");
    const provider = urlParams.get("provider");
    if (!authCode) {
      setCodeIsNotPresent(true);
      return;
    }
    (async () => {
      try {
        await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "version-control/generateAuthToken",
          {
            authCode,
            provider: provider || "github",
          },
        );
        setIsLoggedIn(true);
      } catch (err) {
        console.log("Error: generateAuthToken", err);
        if (err.response?.data?.remarks) {
          showMessage(err.response.data.remarks);
        } else {
          showMessage(
            "Failed to authenticate, please go back and try again later !!!",
          );
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (isCodeNotPresent) {
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  }
  if (isLoggedIn)
    return (
      <Redirect
        to={{
          pathname: "/settings",
        }}
      />
    );
  return (
    <ParentContainer>
      <AnimationContainer>
        <ScrollAnimationBar />
        <LoadingAnimation src={loadingAnimation} alt="Loading Animation" />
      </AnimationContainer>
    </ParentContainer>
  );
};

export default OauthAuthenticatePage;
