import styled from "styled-components";
import CustomRow from "../../components/common/_customRow";
import { CommonFirstPage } from "../LandingPages/CommonFirstPage";
import Column from "../../components/common/_customColumn";
import { useState, useContext } from "react";
import ShowPasswordIcon from "../../assets/images/show_password_icon.svg";
import DontShowPasswordIcon from "../../assets/images/dont_show_password_icon.svg";
import { StyledButtonPrimary } from "../../components/common/_buttonNewOne";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import axiosAdapter from "../../utils";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { env } from "../../env";

const USERNAME_REGEX = /^[a-z0-9._]{6,250}$/;

const PASSWORD_REGEX =
  /^(?!.*\\)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()\-_+=[\]{}|;:,.<>?/~]).{8,32}$/;

const CustomColumn = styled(Column)`
  margin-bottom: ${({ mb }) => (mb ? `${mb}rem` : "0rem")};
`;

const Text = styled.p`
  font-family: "Articulat CF Medium";
  margin: 0;
  color: #f6f6f6;
  font-size: 1.527rem;
  line-height: 2.166rem;
`;

const InputField = styled.input`
  font-family: "Articulat CF Medium";
  font-size: 1.111rem;
  line-height: 1.944rem;
  font-weight: 500;
  color: #f6f6f6;
  background-color: inherit;
  outline: none;
  border: none;
  width: 100%;
  caret-color: white;
  &::placeholder {
    color: #777777;
  }
`;

const InputFieldContainer = styled.div`
  padding: 0.625rem 1.111rem;
  width: 100%;
  border-radius: 0.555rem;
  border: none;
  background-color: #2f2f2f;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.069rem solid #2f2f2f;
`;

export const CreateNewAccount = () => {
  const location = useLocation();
  const history = useHistory();
  const { showMessage } = useContext(SnackbarContext);

  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [disableAllButtons, setDisableAllButtons] = useState(false);

  if (!Boolean(location.state?.isRedirected)) {
    history.push("/");
  }

  const onCreateAccountClick = async () => {
    setDisableAllButtons(true);
    setUsernameError("");
    setPasswordError("");
    setConfirmPasswordError("");
    let isUsernameValid = true;
    let isPasswordValid = true;
    let isConfirmPasswordValid = true;

    // Username validations
    if (!Boolean(username.trim())) {
      setUsernameError("Please enter a value for the username");
      isUsernameValid = false;
    } else if (!USERNAME_REGEX.test(username)) {
      setUsernameError(
        "Username must be between 6 and 250 characters and can include only include a-z, 0-9, . and _",
      );
      isUsernameValid = false;
    }

    // Password validations
    if (!Boolean(password.length)) {
      setPasswordError("Please enter a value for the password");
      isPasswordValid = false;
    } else if (!PASSWORD_REGEX.test(password)) {
      setPasswordError(
        `Password must be between 8 and 32 characters and must include at least one uppercase letter, one lowercase letter, one number and one special character among !@#$%^&*()-_+={}[]|;:,.<>?/~`,
      );
      isPasswordValid = false;
    }

    // Confirm Password validations
    if (isPasswordValid && password !== confirmPassword) {
      setConfirmPasswordError("Password and confirm password do not match");
      isConfirmPasswordValid = false;
    }

    if (!isUsernameValid || !isPasswordValid || !isConfirmPasswordValid) {
      setDisableAllButtons(false);
      return;
    }

    try {
      await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "user-management/checkUsernameAvailibility",
        {
          username,
        },
      );
    } catch (err) {
      console.log("Error: checkUsernameAvailibility", err);
      if (err.response?.data?.remarks) {
        setUsernameError(err.response.data.remarks);
      } else {
        showMessage(
          "Unable to check username, please try again later",
          "error",
        );
      }
      setDisableAllButtons(false);
      return;
    }

    try {
      await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "user-management/createAccount",
        {
          username,
          password,
          emailId: location.state?.emailId,
          otp: location.state?.otp,
          countryCode: location.state?.countryCode,
          phoneNumber: location.state?.phoneNumber,
          fullName: location.state?.fullName,
          organizationName: location.state?.organizationName,
          inviteCode: location.state?.inviteCode,
        },
      );

      showMessage("Account created successfully", "success");
      history.push("/signIn");
    } catch (err) {
      console.log("Error: createAccount", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage(
          "Unable to create account, please try again later",
          "error",
        );
      }
      setDisableAllButtons(false);
    }
  };

  return (
    <CommonFirstPage>
      <CustomRow>
        <CustomColumn mb={3.888}>
          <Text>
            To continue, please choose a username and set a password for your
            account
          </Text>
        </CustomColumn>
        <CustomColumn mb={1.111}>
          <InputFieldContainer
            style={
              usernameError
                ? { borderColor: "#EA3A3A", marginBottom: "0.555rem" }
                : {}
            }
          >
            <InputField
              placeholder="Enter Username"
              onChange={(e) => setUsername(e.target.value.trim().toLowerCase())}
              value={username}
              autoComplete="off"
            />
          </InputFieldContainer>
          {usernameError && (
            <Text
              style={{
                fontSize: "0.833rem",
                lineHeight: "1.388rem",
                color: "#EA3A3A",
              }}
            >
              {usernameError}
            </Text>
          )}
        </CustomColumn>
        <CustomColumn mb={1.111}>
          <InputFieldContainer
            style={
              passwordError
                ? { borderColor: "#EA3A3A", marginBottom: "0.555rem" }
                : {}
            }
          >
            <InputField
              placeholder="Enter Password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              type={showPassword ? "text" : "password"}
              autoComplete="off"
            />
            <img
              src={showPassword ? DontShowPasswordIcon : ShowPasswordIcon}
              alt="Show Password"
              onClick={() => {
                setShowPassword(!showPassword);
              }}
              style={{
                cursor: "pointer",
                marginLeft: "0.347rem",
                height: "1.25rem",
                width: "1.25rem",
              }}
            />
          </InputFieldContainer>
          {passwordError && (
            <Text
              style={{
                fontSize: "0.833rem",
                lineHeight: "1.388rem",
                color: "#EA3A3A",
              }}
            >
              {passwordError}
            </Text>
          )}
        </CustomColumn>
        <CustomColumn mb={2.777}>
          <InputFieldContainer
            style={
              confirmPasswordError
                ? { borderColor: "#EA3A3A", marginBottom: "0.555rem" }
                : {}
            }
          >
            <InputField
              placeholder="Confirm Password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
              type={showConfirmPassword ? "text" : "password"}
              autoComplete="off"
            />
            <img
              src={
                showConfirmPassword ? DontShowPasswordIcon : ShowPasswordIcon
              }
              alt="Show Password"
              onClick={() => {
                setShowConfirmPassword(!showConfirmPassword);
              }}
              style={{
                cursor: "pointer",
                marginLeft: "0.347rem",
                height: "1.25rem",
                width: "1.25rem",
              }}
            />
          </InputFieldContainer>
          {confirmPasswordError && (
            <Text
              style={{
                fontSize: "0.833rem",
                lineHeight: "1.388rem",
                color: "#EA3A3A",
              }}
            >
              {confirmPasswordError}
            </Text>
          )}
        </CustomColumn>
        <CustomColumn>
          <StyledButtonPrimary
            style={{ width: "100%", height: "3.888rem" }}
            onClick={onCreateAccountClick}
            disabled={disableAllButtons}
          >
            Create Account
          </StyledButtonPrimary>
        </CustomColumn>
      </CustomRow>
    </CommonFirstPage>
  );
};
