// External imports
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import "../../scss/PreBuiltConnectUpdated.scss";
import { ConnectorsContext } from "../../pages/WorkSpaceConnectors/WorkSpaceConnectorsMainPage";
import { useContext, useState } from "react";
import ConnectorIcon from "../../assets/images/Connectors.svg";

const truncateContent = (content, maxLength = 50) => {
  return content.length > maxLength
    ? `${content.slice(0, maxLength)}...`
    : content;
};
const hideContent = (content, maxLength = 25) => {
  return content.length > maxLength
    ? `${content.slice(0, maxLength)} `
    : content;
};

// Styled components
const Container = styled.div`
  display: flex;
  background-color: #2f2f2f;
  border-radius: 0.84rem;
  color: #fafafa;
  font-family: "Articulat CF Medium";
`;

const LeftContent = styled.div`
  display: flex;
  width: 10%;
  align-items: center;
  justify-content: center;
`;

const RightContent = styled.div`
  width: 90%;
  padding-top: 1.1rem;
`;

const Line = styled.div`
  width: 0.3rem;
  height: 9.9rem;
  background-color: ${(props) => getConnectorColor(props.connectorType)};
  border-radius: 0.35rem;
`;

const ConnectorNameArea = styled.div`
  display: flex;
  align-items: center;
`;

const StyledIcon = styled.img`
  max-height: 1.3rem;
  max-width: 1.3rem;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const LogoArea = styled.div`
  height: 2.25rem;
  width: 2.25rem;
  border-radius: 0.35rem;
  background-color: #4b4b4b;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CategoryDiv = styled.div`
  display: flex;
  margin-top: 1.39rem;
`;

const CategoryArea = styled.div`
  display: flex;
  flex-direction: column;
`;

const CategoryTitle = styled.div`
  font-size: 0.84rem;
  color: #777777;
`;

const CategoryContent = styled.div`
  font-size: 0.84rem;
  color: #47ccd6;
`;

const Title = styled.div`
  font-size: 1.115rem;
  font-family: "Articulat CF Medium";
  font-weight: bold;
  padding-left: 0.6rem;
`;

const Description = styled.div`
  font-family: "Articulat CF Medium";
  font-size: 0.84rem;
  margin-top: 1.39rem;
  margin-bottom: 0.84rem;
  height: 2.1rem;
  line-height: 1rem;
`;

const getConnectorColor = (connectionType) => {
  switch (connectionType) {
    case "Input":
      return "#47CCD6";
    case "INPUT":
      return "#47CCD6";
    case "Output":
      return "#F6F6F6";
    case "OUTPUT":
      return "#F6F6F6";
    default:
      return "#C7A6F1";
  }
};

/**
 * PreBuiltCard component renders a card displaying information about a connector.
 *
 * @param {string} props.title - The title of the connector.
 * @param {string} props.category - The category of the connector.
 * @param {string} props.type - The type of the connector.
 * @param {string} props.description - The description of the connector.
 * @param {} icon - The link of icon of that particular type connector.
 *
 * @returns {JSX.Element} The rendered PreBuiltCard component.
 */

const PreBuiltCard = ({
  title = "NA",
  category = "NA",
  type = "NA",
  description = "NA",
  icon,
  id,
  isAvailable,
}) => {
  const { handlePreConnectorClick } = useContext(ConnectorsContext);

  return (
    <Container
      onClick={() =>
        handlePreConnectorClick(
          title ? title : title,
          type ? type : "INPUT",
          id,
          category,
          description,
        )
      }
    >
      <LeftContent>
        <Line connectorType={type} />
      </LeftContent>
      <RightContent>
        <ConnectorNameArea>
          <LogoArea>
            <StyledIcon src={icon || ConnectorIcon} alt="Connector Icon" />
          </LogoArea>
          <Title>{title}</Title>
        </ConnectorNameArea>
        <CategoryDiv>
          <CategoryArea style={{ width: "60%" }}>
            <CategoryTitle>Category</CategoryTitle>
            <CategoryContent>{category}</CategoryContent>
          </CategoryArea>
          <CategoryArea style={{ width: "40%" }}>
            <CategoryTitle>Type</CategoryTitle>
            <CategoryContent>{type}</CategoryContent>
          </CategoryArea>
        </CategoryDiv>
        <Description>{truncateContent(description)}</Description>
      </RightContent>
    </Container>
  );
};

// Prop types
PreBuiltCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
};

export default PreBuiltCard;
