import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";

const StepperContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  /* margin-right: 50px; */
`;

const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

const StepNumberContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StepNumber = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.55rem;
  background-color: ${(props) => (props.active ? "#00BCD4" : "#666")};
  color: white;
  display: flex;
  justify-content: center;
  font-family: "Articulat CF Medium";
  align-items: center;
  font-weight: bold;
  margin-right: 0.7rem;
  transition: background-color 0.3s ease;
`;

const StepDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StepText = styled.div`
  font-size: 0.972rem;
  color: ${(props) => (props.active ? "#fafeff" : "#666")};
  transition: color 0.3s ease;
  font-family: "Articulat CF Medium";
  font-weight: bold;
`;

const StepStatus = styled.div`
  font-size: 0.7rem;
  color: ${(props) => (props.active ? "#00BCD4" : "#00BCD4")};
  transition: color 0.3s ease;
  font-family: "Articulat CF Medium";
`;

const StepStatus2 = styled.div`
  font-size: 0.7rem;
  color: ${(props) => (props.active ? "#00BCD4" : "#666")};
  transition: color 0.3s ease;
  font-family: "Articulat CF Medium";
`;

const progressAnimation = keyframes`
  0% { width: 0; }
  100% { width: 100%; }
`;

const Line = styled.div`
  flex: 0.5;
  height: 0.13rem;
  background-color: #666;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #00bcd4;
    width: ${(props) => (props.active ? "100%" : "0")};
    animation: ${(props) => (props.animating ? progressAnimation : "none")} 0.5s
      linear forwards;
  }
`;

const Stepper = ({
  currentStep,
  setCurrentStep,
  setAnimatingLine,
  animatingLine,
}) => {
  // const [currentStep, setCurrentStep] = useState(1);
  // const [animatingLine, setAnimatingLine] = useState(null);

  // const nextStep = () => {
  //   if (currentStep < 3) {
  //     setAnimatingLine(currentStep);
  //     setCurrentStep((prev) => prev + 1);
  //   }
  // };

  useEffect(() => {
    setCurrentStep(1);
  }, []);

  return (
    <StepperContainer>
      <Step>
        <StepNumberContainer style={{ paddingLeft: "3rem" }}>
          <StepNumber active={currentStep >= 1}>1</StepNumber>
          <StepDetails>
            <StepText active={currentStep >= 1}>Select Connector</StepText>
            <StepStatus active={currentStep === 1}>Completed</StepStatus>
          </StepDetails>
        </StepNumberContainer>
      </Step>
      <Line active={currentStep >= 2} animating={animatingLine === 1} />
      <Step>
        <StepNumberContainer style={{ paddingRight: "3rem" }}>
          <StepNumber active={currentStep >= 2}>2</StepNumber>
          <StepDetails>
            <StepText active={currentStep >= 2}>Configure & Deploy</StepText>
            <StepStatus2 active={currentStep === 2}>In Progress</StepStatus2>
          </StepDetails>
        </StepNumberContainer>
      </Step>
      {/* <Line active={currentStep >= 3} animating={animatingLine === 2} />
      <Step>
        <StepNumberContainer>
          <StepNumber active={currentStep >= 3}>3</StepNumber>
          <StepDetails>
            <StepText active={currentStep >= 3}>Publish</StepText>
            <StepStatus active={currentStep === 3}>Pending</StepStatus>
          </StepDetails>
        </StepNumberContainer>
      </Step> */}
      {/* <button onClick={nextStep}>Next</button> */}
    </StepperContainer>
  );
};

export default Stepper;
