import styled from "styled-components";
import CustomRow from "../../components/common/_customRow";
import { CommonFirstPage } from "../LandingPages/CommonFirstPage";
import Column from "../../components/common/_customColumn";
import { useState } from "react";
import { CountryCodeSelector } from "../../components/common/_countryCodeSelector";
import { StyledButtonPrimary } from "../../components/common/_buttonNewOne";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import { useContext } from "react";
import { useEffect } from "react";
import axiosAdapter from "../../utils";
import { env } from "../../env";

const EMAIL_REGEX = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

const ORG_NAME_REGEX = /^[a-zA-Z0-9.,&-]+(?: [a-zA-Z0-9.,&-]+)*$/;

const FULL_NAME_REGEX =
  /^[a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńñòóôöõøśšùúûüųūýÿżźžÀÁÂÄÃÅĄČĆĘÈÉÊËĖĮÌÍÎÏŁŃÑÒÓÔÖÕØŚŠÙÚÛÜŲŪÝŸŻŹŽ.,'’-]+(?: [a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńñòóôöõøśšùúûüųūýÿżźžÀÁÂÄÃÅĄČĆĘÈÉÊËĖĮÌÍÎÏŁŃÑÒÓÔÖÕØŚŠÙÚÛÜŲŪÝŸŻŹŽ.,'’-]+)*$/;

const CustomColumn = styled(Column)`
  margin-bottom: ${({ mb }) => (mb ? `${mb}rem` : "0rem")};
`;

const Text = styled.p`
  font-family: "Articulat CF Medium";
  margin: 0;
  color: #f6f6f6;
  font-size: 1.527rem;
  line-height: 2.166rem;
`;

const InputField = styled.input`
  font-family: "Articulat CF Medium";
  font-size: 1.111rem;
  line-height: 1.944rem;
  font-weight: 500;
  color: #f6f6f6;
  background-color: inherit;
  outline: none;
  border: none;
  width: 100%;
  caret-color: white;
  &::placeholder {
    color: #777777;
  }
`;

const InputFieldContainer = styled.div`
  padding: 0.625rem 1.111rem;
  width: 100%;
  border-radius: 0.555rem;
  border: none;
  background-color: #2f2f2f;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.069rem solid #2f2f2f;
`;

export const SignUp = () => {
  const history = useHistory();
  const { showMessage } = useContext(SnackbarContext);

  const [fullName, setFullName] = useState("");
  const [fullNameError, setFullNameError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [countryCode, setCountryCode] = useState("91");
  const [disableFullName, setDisableFullName] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [emailAddressError, setEmailAddressError] = useState("");
  const [disableEmailAddress, setDisableEmailAddress] = useState(false);
  const [orgName, setOrgName] = useState("");
  const [orgNameError, setOrgNameError] = useState("");
  const [disableOrgName, setDisableOrgName] = useState(false);
  const [disableAllButtons, setDisableAllButtons] = useState(false);
  const [inviteHash, setInviteHash] = useState(null);

  // If there is an invite code in the URL, prefill the invitation details
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const inviteCode = urlParams.get("invitationCode");
    if (inviteCode) {
      setInviteHash(inviteCode);
      (async () => {
        try {
          const response = await axiosAdapter(
            "POST",
            env.REACT_APP_URL + "user-management/getInvitationDetails",
            {
              inviteCode,
            },
          );
          showMessage(response.data.remarks, "success");
          setEmailAddress(response.data.data.email);
          setDisableEmailAddress(true);
          setFullName(response.data.data.fullName);
          setDisableFullName(true);
          setOrgName(response.data.data.orgName);
          setDisableOrgName(true);
        } catch (err) {
          console.log("Error: getInvitationDetails", err);
          showMessage(
            err?.response?.data?.remarks ||
              "Unable to obtain the invitation details. Please try again later",
            "error",
          );
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle create account click function
  const handleCreateAccountClick = async () => {
    setDisableAllButtons(true);
    setFullNameError("");
    setEmailAddressError("");
    setOrgNameError("");
    setPhoneNumberError("");

    let isFullnameValid = true;
    let isOrgNameValid = true;
    let isEmailIdValid = true;
    let isPhoneNumberValid = true;

    let tempFullName = fullName.trim();
    let tempOrgName = orgName.trim();

    // Full name validations
    if (!Boolean(tempFullName)) {
      setFullNameError("Please enter a value for the full name");
      isFullnameValid = false;
    } else if (
      !Boolean(tempFullName.length >= 3 && tempFullName.length <= 250)
    ) {
      setFullNameError(
        "Full name length should be between 3 and 250 characters",
      );
      isFullnameValid = false;
    } else if (!FULL_NAME_REGEX.test(tempFullName)) {
      setFullNameError(
        "Full name can only include alphanumerics, accented alphaets and special characters like .'’-,",
      );
      isFullnameValid = false;
    } else {
      setFullName(tempFullName);
    }

    // Organization name validations
    if (!Boolean(tempOrgName)) {
      setOrgNameError("Please enter a value for the Organization name");
      isOrgNameValid = false;
    } else if (!Boolean(tempOrgName.length >= 3 && tempOrgName.length <= 250)) {
      setOrgNameError(
        "Organization name length should be between 3 and 250 characters",
      );
      isOrgNameValid = false;
    } else if (!ORG_NAME_REGEX.test(tempOrgName)) {
      setOrgNameError(
        "Organization name can only include a-z, A-Z, 0-9 and .,&-",
      );
      isOrgNameValid = false;
    } else {
      setOrgName(tempOrgName);
    }

    // Email validations
    if (!Boolean(emailAddress)) {
      setEmailAddressError("Please enter a value for the email address");
      isEmailIdValid = false;
    } else if (emailAddress.length > 320) {
      setEmailAddressError("Email length should be less than 320 characters");
      isEmailIdValid = false;
    } else if (!EMAIL_REGEX.test(emailAddress)) {
      setEmailAddressError("Please enter a valid email address");
      isEmailIdValid = false;
    }

    // Phone number validations
    if (!Boolean(phoneNumber)) {
      setPhoneNumberError("Please enter a value for the phone number");
      isPhoneNumberValid = false;
    }

    // If any of the validations fail, return
    if (
      !isFullnameValid ||
      !isOrgNameValid ||
      !isEmailIdValid ||
      !isPhoneNumberValid
    ) {
      setDisableAllButtons(false);
      return;
    }

    try {
      await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "user-management/checkPhoneNumberAvailibility",
        {
          phoneNumber: phoneNumber,
          countryCode: countryCode,
        },
      );
    } catch (err) {
      console.log("Error: checkPhoneNumberAvailibility", err);
      if (err.response?.data?.remarks) {
        setPhoneNumberError(err.response.data.remarks);
      } else {
        showMessage(
          "Unable to check the phone number availability, please try again later",
          "error",
        );
      }
      setDisableAllButtons(false);
      return;
    }

    // Check if organization name is available if creating account without invitation
    if (!disableOrgName) {
      try {
        await axiosAdapter(
          "POST",
          env.REACT_APP_URL +
            "user-management/checkOrganizationNameAvailibility",
          {
            organizationName: tempOrgName,
          },
        );
      } catch (err) {
        console.log("Error: checkOrganizationNameAvailibility", err);
        if (err.response?.data?.remarks) {
          setOrgNameError(err.response.data.remarks);
        } else {
          showMessage(
            "Unable to check the organization name availability, please try again later",
            "error",
          );
        }
        setDisableAllButtons(false);
        return;
      }
    }

    // TODO: Call API to generate OTP, if successful, move to OTP verification page
    try {
      await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "user-management/generateOtp",
        {
          emailId: emailAddress,
          isNewAccount: true,
        },
      );

      // As OTP is generated, move to OTP verification page
      history.push("/otpVerification", {
        isRedirected: true,
        isSignUp: true,
        emailId: emailAddress,
        countryCode: countryCode,
        phoneNumber: phoneNumber,
        fullName: tempFullName,
        organizationName: tempOrgName,
        inviteCode: inviteHash,
      });
    } catch (err) {
      console.log("Error: generateOtp", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage("Unable to generate OTP, please try again later", "error");
      }
      setDisableAllButtons(false);
      return;
    }
  };

  return (
    <CommonFirstPage>
      <CustomRow>
        <CustomColumn mb={2.222}>
          <Text>Welcome to Condense! Create an account to continue</Text>
        </CustomColumn>
        <CustomColumn mb={1.111}>
          <InputFieldContainer
            style={
              fullNameError
                ? { borderColor: "#EA3A3A", marginBottom: "0.555rem" }
                : {}
            }
          >
            <InputField
              placeholder="Full Name"
              name="fullName"
              onChange={(e) => setFullName(e.target.value)}
              value={fullName}
              readOnly={disableFullName}
            />
          </InputFieldContainer>
          {fullNameError && (
            <Text
              style={{
                fontSize: "0.833rem",
                lineHeight: "1.388rem",
                color: "#EA3A3A",
              }}
            >
              {fullNameError}
            </Text>
          )}
        </CustomColumn>
        <CustomColumn mb={1.111}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              columnGap: "1.111rem",
              ...(phoneNumberError && { marginBottom: "0.555rem" }),
            }}
          >
            <CountryCodeSelector
              selectedContryCode={countryCode}
              onContryCodeSelect={(item) => setCountryCode(item.code)}
            />
            <InputFieldContainer
              style={phoneNumberError ? { borderColor: "#EA3A3A" } : {}}
            >
              <InputField
                placeholder="Phone Number"
                name="phoneNumber"
                onChange={(e) =>
                  setPhoneNumber(e.target.value.trim().replace(/\D/g, ""))
                }
                value={phoneNumber}
              />
            </InputFieldContainer>
          </div>
          {phoneNumberError && (
            <Text
              style={{
                fontSize: "0.833rem",
                lineHeight: "1.388rem",
                color: "#EA3A3A",
                marginTop: "0.555rem",
              }}
            >
              {phoneNumberError}
            </Text>
          )}
        </CustomColumn>
        <CustomColumn mb={1.111}>
          <InputFieldContainer
            style={
              emailAddressError
                ? { borderColor: "#EA3A3A", marginBottom: "0.555rem" }
                : {}
            }
          >
            <InputField
              placeholder="Work Email Address"
              name="emailAddress"
              onChange={(e) =>
                setEmailAddress(e.target.value.toLowerCase().trim())
              }
              value={emailAddress}
              readOnly={disableEmailAddress}
            />
          </InputFieldContainer>
          {emailAddressError && (
            <Text
              style={{
                fontSize: "0.833rem",
                lineHeight: "1.388rem",
                color: "#EA3A3A",
              }}
            >
              {emailAddressError}
            </Text>
          )}
        </CustomColumn>
        <CustomColumn mb={2.222}>
          <InputFieldContainer
            style={
              orgNameError
                ? { borderColor: "#EA3A3A", marginBottom: "0.555rem" }
                : {}
            }
          >
            <InputField
              placeholder="Organization Name"
              name="organizationName"
              onChange={(e) => setOrgName(e.target.value)}
              value={orgName}
              readOnly={disableOrgName}
            />
          </InputFieldContainer>
          {orgNameError && (
            <Text
              style={{
                fontSize: "0.833rem",
                lineHeight: "1.388rem",
                color: "#EA3A3A",
              }}
            >
              {orgNameError}
            </Text>
          )}
        </CustomColumn>
        <CustomColumn mb={1.111}>
          <StyledButtonPrimary
            style={{ width: "100%", height: "3.888rem" }}
            onClick={handleCreateAccountClick}
            disabled={
              disableAllButtons ||
              !fullName ||
              !phoneNumber ||
              !emailAddress ||
              !orgName ||
              !countryCode
            }
          >
            Create Account
          </StyledButtonPrimary>
        </CustomColumn>
        <CustomColumn>
          <Text
            style={{
              color: "#777777",
              fontSize: "1.111rem",
              lineHeight: "1.388rem",
            }}
          >
            Already have an account?
            <span
              style={{
                color: "#CCCCCC",
                fontWeight: "bold",
                textDecoration: "underline",
                cursor: "pointer",
                fontSize: "1.111rem",
                marginLeft: "0.277rem",
              }}
              onClick={() => !disableAllButtons && history.push("/signIn")}
            >
              Login Now
            </span>
          </Text>
        </CustomColumn>
      </CustomRow>
    </CommonFirstPage>
  );
};
