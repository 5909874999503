import React, { useRef } from "react";
import { env } from "../../env";

const token = localStorage.getItem("token");

const CodeTerminal = ({ height, width, show, onToggle, podName }) => {
  const iframeRef = useRef(null);
  const customUrl =
    env.REACT_APP_URL +
    `terminal/tty/${podName}` +
    "?arg=" +
    encodeURIComponent(token);

  return (
    <div
      style={{
        height: height,
        width: width,
        borderTop: "1px solid #2f2f2f",
        borderLeft: "1px solid #2f2f2f",
      }}
    >
      {show && (
        <iframe
          style={{
            overflow: "hidden",
            scrollbarColor: "black",
          }}
          title="terminal"
          ref={iframeRef}
          src={customUrl}
          height={height - 1}
          width={width - 1}
        />
      )}
    </div>
  );
};

export default CodeTerminal;
