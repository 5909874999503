import React, { useMemo } from "react";
import Select from "../../../../components/common/_select";
import FlexContainer from "../../../../components/common/_flexContainer";
import IconButton from "../../../../components/common/_iconButton";
import { ReactComponent as RevokeInviteIcon } from "../../../../assets/images/revoke_invite_icon.svg";
import { ReactComponent as DeleteUserIcon } from "../../../../assets/images/delete_user_icon.svg";
import styled from "styled-components";

const RevokeInviteIconStyled = styled(RevokeInviteIcon)`
  width: 1.666rem;
  height: 1.666rem;
`;
const DeleteUserIconStyled = styled(DeleteUserIcon)`
  width: 1.666rem;
  height: 1.666rem;
`;

const UserControlCell = ({ row, editUserRole, revokeUserInvite }) => {
  const options = useMemo(
    () => [
      { label: "Admin", value: "ADMIN" },
      { label: "User", value: "USER" },
      ...(row?.isJustInvite ? [{ label: "Pending...", value: "PENDING" }] : []),
    ],
    [row?.isJustInvite],
  );

  const role = row.isJustInvite
    ? options[2]
    : row.role === "ADMIN"
    ? options[0]
    : options[1];

  return (
    <FlexContainer $justifyContent="flex-end" $gap="1.111rem">
      <Select
        disabled={row.isJustInvite}
        value={role}
        setValue={(newRole) => editUserRole(row, newRole)}
        options={options}
        renderValue={(option) => option.label}
        alwaysShowBorder
        border="0.069rem solid #2f2f2f"
        width="8.333rem"
      />
      <IconButton>
        {row.isJustInvite ? (
          <RevokeInviteIconStyled onClick={() => revokeUserInvite(row)} />
        ) : (
          <DeleteUserIconStyled />
        )}
      </IconButton>
    </FlexContainer>
  );
};

export default UserControlCell;
