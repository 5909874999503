import { Editor } from "@monaco-editor/react";
import styled from "styled-components";
import React, { useContext, useEffect } from "react";
import CustomAppEditorContext from "../../context/customApp/CustomAppEditorContext";
import FileTabs from "./FileTabs";
// --------- Fix for Window Resize Observer issue ---------

// Save a reference to the original ResizeObserver
const OriginalResizeObserver = window.ResizeObserver;

// Create a new ResizeObserver constructor
window.ResizeObserver = function (callback) {
  const wrappedCallback = (entries, observer) => {
    window.requestAnimationFrame(() => {
      callback(entries, observer);
    });
  };

  // Create an instance of the original ResizeObserver
  // with the wrapped callback
  return new OriginalResizeObserver(wrappedCallback);
};

// Copy over static methods, if any
for (let staticMethod in OriginalResizeObserver) {
  if (OriginalResizeObserver.hasOwnProperty(staticMethod)) {
    window.ResizeObserver[staticMethod] = OriginalResizeObserver[staticMethod];
  }
}

// --------- End of Fix for Window Resize Observer issue ---------

// const EditorClass = styled.div`
//   font-family: "Fira-Code";
// `;
// const Container = styled.div`
//   width: 100%;
//   height: 100%;
//   display: flex;
//   flex-direction: column;
// `;

// const CodeEditorContainer = styled.div`
//   border-left: 1px solid #2f2f2f;
//   width: 100%;
//   flex-grow: 1;
// `;

const CodeEditor = ({ height, width }) => {
  const { customAppEditorData, updateCustomAppEditorData } = useContext(
    CustomAppEditorContext,
  );

  const handleEditorDidMount = (editor, monaco) => {
    console.log("onMount: the editor instance:", editor);
    console.log("onMount: the monaco instance:", monaco);

    const selectedFile = customAppEditorData.selectedFile;
    const fileContent =
      customAppEditorData.editorFiles[selectedFile]?.content || "";

    // Save the initial content when the editor is mounted
    updateCustomAppEditorData({
      ...customAppEditorData,
      editorFiles: {
        ...customAppEditorData.editorFiles,
        [selectedFile]: {
          ...customAppEditorData.editorFiles[selectedFile],
          initialContent: fileContent,
        },
      },
    });
  };

  const handleEditorChange = (value, event) => {
    console.log("value: ", value);
    console.log("event: ", event);

    const selectedFile = customAppEditorData.selectedFile;
    const initialContent =
      customAppEditorData.editorFiles[selectedFile]?.initialContent;

    const newChanges = {
      ...customAppEditorData,
      editorFiles: {
        ...customAppEditorData.editorFiles,
        [selectedFile]: {
          ...customAppEditorData.editorFiles[selectedFile],
          content: value,
          cursorPosition: {
            line: event.changes[0].range.endLineNumber,
            ch: event.changes[0].range.endColumn,
          },
          eol: event.eol === "\n" ? "LF" : "CRLF",
          isModified: value !== initialContent,
        },
      },
    };

    updateCustomAppEditorData(newChanges);
  };

  useEffect(() => {
    console.log("customAppEditorData: ", customAppEditorData);
    // eslint-disable-next-line
  }, [customAppEditorData]);

  // console.log(
  //   "=====",
  //   customAppEditorData.editorFiles[customAppEditorData.selectedFile]?.language,
  // );

  return (
    <div
      style={{
        height,
        width,
        borderLeft: "1px solid #2f2f2f",
      }}
    >
      <Editor
        height={height}
        width={width - 1}
        theme="vs-dark"
        fontSize="20"
        defaultLanguage={"plaintext"}
        language={
          customAppEditorData.editorFiles[customAppEditorData.selectedFile]
            ?.language || "plaintext"
        }
        onMount={handleEditorDidMount}
        onChange={handleEditorChange}
        value={
          customAppEditorData.editorFiles[customAppEditorData.selectedFile]
            ?.content || ""
        }
        options={{
          fontSize: 17,
          fontFamily: "'Fira Code', Consolas, 'Courier New', monospace",
        }}
      />
    </div>
  );
};

export default CodeEditor;
