import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import { AuthProvider } from "./AuthContext";
import { GrafanaCredentialsProvider } from "./context/GrafanaUserCredentialsContext";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <AuthProvider>
    <GrafanaCredentialsProvider>
      <App />
    </GrafanaCredentialsProvider>
  </AuthProvider>,
);
