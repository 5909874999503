import React from "react";
import IconButton from "../../../../components/common/_iconButton";
import FlexContainer from "../../../../components/common/_flexContainer";
import { ReactComponent as AddUserIcon } from "../../../../assets/images/add_user_icon.svg";
import { ReactComponent as DeleteUserIcon } from "../../../../assets/images/delete_user_icon.svg";
import styled from "styled-components";

const AddUserIconStyled = styled(AddUserIcon)`
  width: 1.666rem;
  height: 1.666rem;
`;
const DeleteUserIconStyled = styled(DeleteUserIcon)`
  width: 1.666rem;
  height: 1.666rem;
`;

const WorkspaceMemberRevokeOrAddCell = ({ row, setWorkspaceMembersData }) => {
  const addOrRevokeMember = () => {
    setWorkspaceMembersData((oldWorkspaceMembersData) => {
      const newWorkspaceMembersData = JSON.parse(
        JSON.stringify(oldWorkspaceMembersData),
      );
      newWorkspaceMembersData.membersList.forEach((workspaceMember) => {
        if (workspaceMember.userId === row.userId && row.userPermission) {
          newWorkspaceMembersData.membersCount--;
          workspaceMember.userPermission = null;
        } else if (
          workspaceMember.userId === row.userId &&
          !row.userPermission
        ) {
          newWorkspaceMembersData.membersCount++;
          workspaceMember.userPermission = "VIEW";
        }
      });
      return newWorkspaceMembersData;
    });
  };

  return (
    <FlexContainer $width="100%" $justifyContent="flex-end">
      <IconButton $border="0.104rem solid #4B4B4B" onClick={addOrRevokeMember}>
        {row.userPermission ? <DeleteUserIconStyled /> : <AddUserIconStyled />}
      </IconButton>
    </FlexContainer>
  );
};

export default WorkspaceMemberRevokeOrAddCell;
