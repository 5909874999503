import styled from "styled-components";
import { useRef, useContext } from "react";

import FileBreadcrumbs from "./FileBreadcrumbs";
import CustomAppEditorContext from "../../context/customApp/CustomAppEditorContext";
import { type } from "@testing-library/user-event/dist/type";

const ScrollableRow = styled.div`
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const CustomTab = styled.div`
  z-index: 2;
  display: flex;
  padding: 3px 10px 6px 10px;
  font-size: 14px;
  font-weight: 500;
  height: 100%;
  background-color: #0d0d0d;
  border: 1px solid #2f2f2f;
  //   border: 1px solid #2fff;
  border-radius: 10px 10px 0px 0px;
  color: #ececec;
  cursor: pointer;
  &:hover {
    background-color: #333;
    border-bottom: 1px solid #333;
  }
`;

const TabNavButton = styled.div`
  border: 0;
  font-size: 16px;
  margin-bottom: 1px;
  padding: 2px 10px 2px 10px;
  background-color: #161818;
  color: #ececec;
  cursor: pointer;
  &:hover {
    background-color: #333;
  }
`;

const FileTabs = () => {
  const ref = useRef(null);
  const { customAppEditorData, updateCustomAppEditorData } = useContext(
    CustomAppEditorContext,
  );

  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };

  const changeSelectedFile = (key) => {
    console.log("changing Selected File: ", key, typeof key);
    if (customAppEditorData.selectedFileKeyName === key) return;

    updateCustomAppEditorData({
      ...customAppEditorData,
      selectedFile: key,
    });
  };

  const closeFile = (key) => {
    console.log("closing File: ", key, typeof key);
    console.log(customAppEditorData.selectedFile, key);

    const currKey = customAppEditorData.selectedFile;
    const keyArr = Object.keys(customAppEditorData.editorFiles);

    let selectedFile = key;
    if (key === currKey) {
      let index = keyArr.indexOf(key);
      if (index === 0) {
        if (keyArr.length > 1) {
          selectedFile = keyArr[index + 1];
        }
      } else {
        selectedFile = keyArr[index - 1];
      }

      console.log(" new selectedFile: ", selectedFile);
      // updateCustomAppEditorData({
      //   ...customAppEditorData,
      //   selectedFile,
      // });
    }

    let editorFiles = { ...customAppEditorData.editorFiles };
    console.log("closing File: ", editorFiles[key]);
    delete editorFiles[key];

    updateCustomAppEditorData({
      ...customAppEditorData,
      editorFiles,
      selectedFile,
    });
  };

  return (
    <div>
      <div
        className="d-flex flex-nowrap w-100 flex-row-reverse"
        style={{ height: "35px" }}
      >
        <div className="d-flex flex-nowrap pe-4">
          <TabNavButton onClick={() => scroll(-100)}>
            &lt;
            {/* <img src={ChevronLeft} height={"15px"} alt="leftArrow" /> */}
          </TabNavButton>
          <TabNavButton onClick={() => scroll(100)}>
            &gt;
            {/* <img src={ChevronRight} height={"15px"} alt="rightArrow" /> */}
          </TabNavButton>
        </div>
        <ScrollableRow
          ref={ref}
          style={{
            flexGrow: 1,
            height: "100%",
            alignItems: "flex-end",
          }}
        >
          <div className="d-flex flex-nowrap h-100">
            {Object.entries(customAppEditorData.editorFiles).map(
              ([key, file]) => {
                return (
                  <CustomTab
                    key={key}
                    onClick={(e) =>
                      e.stopPropagation() || changeSelectedFile(key)
                    }
                    style={{
                      borderBottom:
                        customAppEditorData.selectedFile === key
                          ? "1px solid #0d0d0d"
                          : "",
                    }}
                  >
                    <div className="d-flex flex-nowrap">
                      <div className="text-nowrap pe-5">{file.name}</div>
                      <div
                        className="hover:text-red-500"
                        onClick={(e) => e.stopPropagation() || closeFile(key)}
                      >
                        x
                      </div>
                    </div>
                  </CustomTab>
                );
              },
            )}
          </div>
        </ScrollableRow>
      </div>
      <FileBreadcrumbs
        path={
          customAppEditorData.editorFiles[customAppEditorData.selectedFile]
            ?.path || ""
        }
      />
    </div>
  );
};

export default FileTabs;
