// import React, { useContext, useEffect, useState } from "react";
// import { SnackbarContext } from "../../layouts/Context/snackBarContext";
// import { Col, Row, Container } from "reactstrap";
// import styled from "styled-components";
// import Typography from "./_typography";
// import ErrorIcon from "../../assets/images/Vector copy.svg";

// const StyledTopSnackBar = styled.div`
//   position: absolute;
//   font-family: "Articulat CF Medium";
//   align-items: center;
//   border-radius: 8px;
//   width: 320px;
//   height: 96px;
//   left: 16px;
//   bottom: 16px;
//   background: ${(props) => (props.type === "success" ? "#2D4135" : "#4d3131")};
//   z-index: 2;
//   border: 1.5px solid
//     ${(props) => (props.type === "success" ? "#259D55" : "#ea3a3a")};
// `;

// const TopSnackBar = (props) => {
//   const { message, showMessage, messageType } = useContext(SnackbarContext);

//   const [open, setOpen] = useState(false);

//   useEffect(() => {
//     if (message) {
//       setOpen(true);
//       const timer = setTimeout(() => {
//         setOpen(false);
//         showMessage("");
//       }, 3000);
//       return () => clearTimeout(timer);
//     }
//   }, [message, showMessage]);

//   return (
//     <StyledTopSnackBar
//       style={{ display: open ? "block" : "none" }}
//       type={messageType}
//     >
//       <Container>
//         <Row>
//           <Col
//             className="snackbar-container"
//             xs={12}
//             style={{ margin: "16px" }}
//           >
//             <Col
//               className="Icon-message"
//               style={{
//                 display: "flex",
//                 marginBottom: "4px",
//                 gap: "5px",
//               }}
//             >
//               <img src={ErrorIcon} alt="error" />
//               <Typography text="Error" color={"#F6F6F6"} tag={"p-bold-large"} />
//             </Col>
//             <Col style={{ fontSize: "12px" }}>
//               <Typography text={message} color={"#F6F6F6"} tag={"p-large"} />
//             </Col>
//           </Col>
//         </Row>
//       </Container>
//     </StyledTopSnackBar>
//   );
// };

// export default TopSnackBar;

import React, { useContext, useEffect, useState } from "react";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import styled from "styled-components";
import Typography from "./_typography";
import ErrorIcon from "../../assets/images/Vector copy.svg";
import SuccessIcon from "../../assets/images/Frame.svg";
import CloseIcon from "../../assets/images/Close.svg";

const StyledTopSnackBar = styled.div`
  position: fixed;
  font-family: "Articulat CF Medium";
  border-radius: 0.55rem;
  width: 22.22rem;
  max-width: 26.388rem;
  min-height: 6.67rem;
  left: 1.112rem;
  bottom: 1.112rem;
  background: ${(props) => (props.type === "success" ? "#2D4135" : "#4d3131")};
  z-index: 2000;
  border: 0.104rem solid
    ${(props) => (props.type === "success" ? "#259D55" : "#ea3a3a")};
  padding: 1.112rem;
  display: ${(props) => (props.open ? "flex" : "none")};
  flex-direction: column;
`;

const IconMessageContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.55rem;
  position: relative;
  justify-content: space-between;
`;

const IconMessageDiv = styled.div`
  display: flex;
`;

const CloseIconWrapper = styled.div`
  /* position: absolute; */
  /* margin-left: 45px; */
  cursor: pointer;
`;

const MessageContainer = styled.div`
  word-wrap: break-word;
`;

const TopSnackBar = () => {
  const { message, showMessage, messageType } = useContext(SnackbarContext);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (message) {
      setOpen(true);
      const timer = setTimeout(() => {
        setOpen(false);
        showMessage("");
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [message, showMessage]);

  const handleClose = () => {
    setOpen(false);
    showMessage("");
  };

  return (
    <StyledTopSnackBar open={open} type={messageType}>
      <IconMessageContainer>
        <IconMessageDiv>
          <img
            src={messageType === "success" ? SuccessIcon : ErrorIcon}
            alt={messageType === "success" ? "success" : "error"}
            style={{
              marginRight: "0.7rem",
              height: "1.112rem",
              width: "1.112rem",
            }}
          />
          <Typography
            text={
              messageType === "success" ? "Way to Go!" : "Something went wrong!"
            }
            color={"#F6F6F6"}
            tag={"p-bold-large"}
          />
        </IconMessageDiv>
        <CloseIconWrapper>
          <img
            style={{
              marginBottom: "0.972rem",
              height: "0.84rem",
              width: "0.84rem",
            }}
            src={CloseIcon}
            alt="Close"
            onClick={handleClose}
          />
        </CloseIconWrapper>
      </IconMessageContainer>
      <MessageContainer>
        <Typography text={message} color={"#F6F6F6"} tag={"p-small"} />
      </MessageContainer>
    </StyledTopSnackBar>
  );
};

export default TopSnackBar;
