import { useCallback, useContext, useEffect, useState } from "react";
import { ConnectorsContext } from "./WorkSpaceConnectorsMainPage";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import MoreInfoPart from "../../components/ConfigForm/moreInfoPart";
import ConfigForm from "../../components/ConfigForm/newConfigForm";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { keyframes } from "styled-components";
import backIcon from "../../assets/images/left_arrow_icon.svg";
import styled from "styled-components";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import GenericModal from "../../components/common/_genericModal";
import Stepper from "../../pages/WorkSpaceConnectors/Stepper";
import { getNodesAndEdgesWithNewnode } from "./utils/getNodesAndEdges";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import ConfigBottomNavbar from "../../components/ConfigForm/configFormBottomNav";
import {
  StyledButtonPrimary,
  StyledButtonsecondary,
  StyledDeleteButton,
} from "../../components/common/_buttonNewOne";
import GridBackground from "../../assets/images/grid 1 (1).png";
import ReactFlowNodeCard from "./ReactFlowNodeCard";
import Column from "../../components/common/_customColumn";
import CustomRow from "../../components/common/_customRow";

const ConnectorConfigBottomButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1.112rem 0px;
`;

const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fafafa;
  font-family: "Articulat CF Medium";
  margin-top: 0.277rem;
  margin-bottom: 1.39rem;
`;

const NavbarLeftConnect = styled.div`
  display: flex;
  gap: 1.112rem;
  font-size: 1.67rem;
  font-weight: bold;
`;

const NavbarRightConnect = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 18px;
  font-weight: bold;
`;

const Icon = styled.div`
  height: 2.225rem;
  width: 2.225rem;
  margin-bottom: 5px;
  cursor: pointer;
`;

const SelectIcon = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: #41bac3;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  font-family: "Articulat CF Medium";
  font-weight: bold;
`;

const ConfigRightConnectSelect = styled.div`
  display: flex;
  font-size: 18px;
  align-items: center;
  gap: 16px;
`;

const ConfigRightConnectDeploy = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  gap: 16px;
`;

const ModalDescription = styled.div`
  font-family: "Articulat CF Medium";
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.555rem;
  color: #ececec;
  opacity: 0.7;
  margin-bottom: 2.084rem;
  display: flex;
`;

const StepperStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fafafa;
  font-family: "Articulat CF Medium";
`;

const ModalButtonContainer = styled.div`
  margin-top: 2.084rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.39rem;
`;

const PreviewContainer = styled.div`
  background-color: #2f2f2f;
  height: 100%;
  border-radius: 0.84rem;
  background-image: url(${GridBackground});
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  margin-left: 1.112rem;
`;

const PreviewTitle = styled.div`
  font-family: Articulat CF Medium;
  font-size: 1.67rem;
  color: #f6f6f6;
  position: absolute;
  top: 1.112rem;
  left: 1.67rem;
  font-weight: bold;
`;

const Text = styled.p`
  font-family: "Articulat CF Medium";
  margin: 0;
  color: #f6f6f6;
`;

const Label = styled.div`
  color: #f6f6f6;
  font-family: "Articulat CF Medium";
  font-size: 0.7rem;
  line-height: 0.84rem;
  margin-bottom: 0.417rem;
`;

const StyledInput = styled.input`
  padding: 0.7rem;
  font-size: 0.972rem;
  line-height: 1.112rem;
  color: #f6f6f6;
  font-family: "Articulat CF Medium";
  border: 0.138rem solid #f6f6f6;
  border-radius: 0.84rem;
  outline: none;
  background: #2f2f2f;
  margin-right: 0.7rem;
  width: 72%;
  max-width: 72%;
  &::placeholder {
    font-family: Articulat CF Medium;
    color: #b0b0b0;
    font-size: 0.972rem;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
`;

const getButtonName = (step, isTestConnection) => {
  switch (step) {
    case 1:
      return isTestConnection ? "Test Connection" : "Configure";
    case 2:
      return "Deploy";
    default:
      return "";
  }
};

const filterValidInterfaces = (arr) => {
  return arr.filter(Boolean);
};

const ConnectorsConfigurationPage = (props) => {
  const location = useLocation();
  const history = useHistory();
  const {
    selectedConnectorName = "",
    selectedConnectorType = "",
    selectedConnectorId = "",
    selectedConnectorCategory = "",
    selectedConnectorDescription = "",
    workspaceLayout = null,
    selectedWorkspaceId = "",
    customConnectorVersion = "",
  } = location.state || {};
  const { showMessage } = useContext(SnackbarContext);
  const { handleBackClick } = useContext(ConnectorsContext);
  const [deviceData, setDeviceData] = useState([]);
  const [configs, setConfigs] = useState([]);
  const [allTopics, setAllTopics] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [isTestConnectionApplicable, setTestConnectionAppicable] =
    useState(false);
  const [isConfigureProcessing, setConfigureProcessing] = useState(false);
  const [configurationId, setConfigurationId] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalTitleText, setModalTitleText] = useState("");
  const [previewData, setPreviewData] = useState({});
  const [content, setContent] = useState("");
  const [connectorVersionList, setConnectorVersionList] = useState([]);
  const [animatingLine, setAnimatingLine] = useState(null);
  const [selectedConnectorVersion, setSelectedConnectorVersion] = useState(
    customConnectorVersion || "",
  );
  const [reqResources, setReqResources] = useState({
    cpu: null,
    memory: null,
  });

  // Function to close the success/fail modal
  const handleModalClose = () => {
    setModalOpen(false);
  };
  const handleConnectorsClick = () => {
    history.push("/connectors");
  };
  // Function to handle the right side button of modal based on the current stepper
  const handleModalButton = () => {
    if (currentStep === 1) {
      handleModalClose();
    } else {
      // Successful redirect to playground page
      if (modalTitleText === "Deployment Successful!") {
        history.push({
          pathname: "connectorsPlayground",
          selectedWorkspace: localStorage.getItem("selectedWorkSpaceId"),
        });
      } else {
        handleModalClose();
      }
    }
  };

  const setConnectorConfigsAndTestFunction = async (configArg) => {
    let tempConfigurationId;
    try {
      let stringifiedData = JSON.stringify(configArg);
      let setConfigsResponse = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/setConnectorConfigsNew",
        stringifiedData,
      );
      tempConfigurationId = setConfigsResponse.data.data.id;
      setConfigurationId(tempConfigurationId);
    } catch (err) {
      setModalOpen(true);
      setModalTitleText("Setting Configuration Failed");
      err.code = "CONFIG_ERROR";
      throw err;
    }
    if (isTestConnectionApplicable) {
      try {
        let stringifiedData = JSON.stringify({
          id: tempConfigurationId,
          connectorName: selectedConnectorName,
        });
        await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "pipeline/testConnectorConfigsNew",
          stringifiedData,
        );
      } catch (err) {
        setModalOpen(true);
        setModalTitleText("Connection Failed");
        err.code = "TEST_ERROR";
        throw err;
      }
    }
  };

  const deployAndSetWorkspace = async (configId, nodes, edges) => {
    try {
      let stringifiedData = JSON.stringify({
        connectorId: configId,
        isDevice: selectedConnectorCategory === "Telematics Device",
      });
      await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/setTransformDeploymentNew",
        stringifiedData,
      );
    } catch (err) {
      setModalOpen(true);
      setModalTitleText("Deployment Failed");
      err.code = "DEPLOY_ERROR";
      throw err;
    }
    try {
      let stringifyData = {
        id: localStorage.getItem("selectedWorkSpaceId"),
        layout: {
          node: nodes,
          edges: edges,
        },
      };
      await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/setWorkSpace",
        stringifyData,
      );
    } catch (err) {
      setModalOpen(true);
      setModalTitleText("Setting Workspace Failed");
      err.code = "WORKSPACE_ERROR";
      throw err;
    }
  };

  const goBackClickHandler = () => {
    if (currentStep === 1) {
      history.push("/connectors");
    } else if (currentStep === 2) {
      setCurrentStep(1);
    }
  };

  const handleNextClick = async () => {
    // if (currentStep < 3) {
    //   setAnimatingLine(currentStep);
    //   setCurrentStep((prev) => prev + 1);
    // }
    if (currentStep === 1) {
      console.log("Im in...");
      try {
        setConfigureProcessing(true);
        let configArg,
          configObj = {};
        if (selectedConnectorCategory === "Telematics Device") {
          if (!configs.length) {
            let error = new Error(
              "Please select a device before configuration",
            );
            error.code = "INPUT_ERROR";
            throw error;
          }
          for (let [index, item] of configs.entries()) {
            if (index === 0) continue;
            if (item.type === "topic") {
              if (item.isRequired && (!item.value || !item.value.name)) {
                let error = new Error(
                  `Please select or create a topic for the field ${item.label} (${item.direction})`,
                );
                error.code = "INPUT_ERROR";
                throw error;
              }
              configObj[item.name] = item.value.name;
            } else {
              if (item.isRequired && !item.value) {
                let error = new Error(
                  `Please enter value for the field '${item.label}'`,
                );
                error.code = "INPUT_ERROR";
                throw error;
              }
              configObj[item.name] = item.value;
            }
          }
          configArg = {
            connectorName: configs[0].value,
            type: "Input",
          };
          configArg.config = configObj;
        } else {
          console.log("Im in 2...");
          configArg = {
            connectorName: selectedConnectorName,
            type: selectedConnectorType,
          };
          for (let item of configs) {
            if (item.type === "topic") {
              if (item.isRequired && (!item.value || !item.value.name)) {
                let error = new Error(
                  `Please select or create a topic for the field ${item.label} (${item.direction})`,
                );
                error.code = "INPUT_ERROR";
                throw error;
              }
              configObj[item.name] = item.value.name;
            } else {
              if (item.isRequired && !item.value) {
                let error = new Error(
                  `Please enter value for the field '${item.label}'`,
                );
                error.code = "INPUT_ERROR";
                throw error;
              }
              if (item.type === "port") {
                if (
                  !Boolean(
                    parseInt(item.value) >= item.min &&
                      parseInt(item.value) <= item.max,
                  )
                ) {
                  let error = new Error(
                    `Please enter port number between ${item.min} and ${item.max} range`,
                  );
                  error.code = "INPUT_ERROR";
                  throw error;
                }
              }
              configObj[item.name] = item.value;
            }
          }
          configArg.config = configObj;
          if (selectedConnectorType === "Custom Connector") {
            configArg.customConnectorId = selectedConnectorId;
            configArg.customConnectorVersion = selectedConnectorVersion;
            if (reqResources.cpu) {
              if (reqResources.memory) {
                if (
                  parseInt(reqResources.cpu) > 0 &&
                  parseInt(reqResources.memory) > 0 &&
                  parseInt(reqResources.cpu) <= 2000 &&
                  parseInt(reqResources.memory) <= 2000
                ) {
                  configArg.resource = {
                    cpu: parseInt(reqResources.cpu),
                    memory: parseInt(reqResources.memory),
                  };
                } else {
                  let error = new Error(
                    `Please enter Request Limit Configuration values between 1 and 2000`,
                  );
                  error.code = "INPUT_ERROR";
                  throw error;
                }
              } else {
                let error = new Error(
                  `Please either fill both the values of empty both for default in Request Limit Configuration`,
                );
                error.code = "INPUT_ERROR";
                throw error;
              }
            } else if (reqResources.memory) {
              if (reqResources.cpu) {
                if (
                  parseInt(reqResources.cpu) > 0 &&
                  parseInt(reqResources.memory) > 0 &&
                  parseInt(reqResources.cpu) <= 2000 &&
                  parseInt(reqResources.memory) <= 2000
                ) {
                  configArg.resource = {
                    cpu: parseInt(reqResources.cpu),
                    memory: parseInt(reqResources.memory),
                  };
                } else {
                  let error = new Error(
                    `Please enter Request Limit Configuration values between 1 and 2000`,
                  );
                  error.code = "INPUT_ERROR";
                  throw error;
                }
              } else {
                let error = new Error(
                  `Please either fill both the values of empty both for default in Request Limit Configuration`,
                );
                error.code = "INPUT_ERROR";
                throw error;
              }
            }
          }
        }
        await setConnectorConfigsAndTestFunction(configArg);
        setCurrentStep(2);
        setModalOpen(true);
        setModalTitleText(
          selectedConnectorCategory === "Telematics Device"
            ? "Configuration Successful!"
            : "Connection Successful!",
        );
        setPreviewData((prevData) => {
          let tempInterfaces = filterValidInterfaces(prevData.interfaces);
          return {
            ...prevData,
            interfaces: tempInterfaces,
            firstNode: tempInterfaces.every((obj) => obj.direction !== "input"),
          };
        });
        setConfigureProcessing(false);
        // props.nextStep();
      } catch (err) {
        if (err.code === "INPUT_ERROR") {
          showMessage(err.message);
        } else if (err.code === "CONFIG_ERROR") {
          showMessage("Failed to set the configuration of this connector");
        } else if (err.code === "TEST_ERROR") {
          showMessage(
            "Failed during Connection Test of the Connector Configurations",
          );
        } else {
          showMessage("Failed to configure the selected connector");
        }
        setConfigureProcessing(false);
        console.log("err handleNextClick 1", err);
      }
    }

    if (currentStep === 2) {
      if (!workspaceLayout) {
        showMessage("Unable to obtain the data for workspace");
        return;
      }
      setConfigureProcessing(true);
      let { nodes, edges } = getNodesAndEdgesWithNewnode(
        workspaceLayout.node,
        workspaceLayout.edges,
        {
          id: configurationId.toString(),
          type: selectedConnectorType,
          data: {
            ...previewData,
            configId: configurationId,
          },
          targetPosition: "left",
          sourcePosition: "right",
          position: {
            x: null,
            y: null,
          },
        },
      );
      try {
        await deployAndSetWorkspace(configurationId, nodes, edges);
        setConfigureProcessing(false);
        setModalOpen(true);
        setModalTitleText("Deployment Successful!");
      } catch (err) {
        if (err.code === "DEPLOY_ERROR") {
          // console("Failed to deploy this connector");
        } else if (err.code === "WORKSPACE_ERROR") {
          showMessage(
            "Failed during Setting of Workspace with the deployed connector",
          );
        } else {
          showMessage(
            "Failed to Deploy and Set the workspace for the selected connector",
          );
        }
        setConfigureProcessing(false);
        console.log("err handleNextClick 2", err);
      }
    }
  };

  const fetchAllTopics = async () => {
    let allTopicsResponse = await axiosAdapter(
      "GET",
      env.REACT_APP_URL + "kafkaadmin/getAllTopics",
    );
    return allTopicsResponse.data;
  };

  const fetchCustomConnectorUserConfigs = useCallback(
    async (customConnectorId, customConnectorVersion) => {
      let stringifiedData = JSON.stringify({
        customConnectorId,
        customConnectorVersion,
      });
      let fetchConfigResponse = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/getCustomConnectorUserConfigs",
        stringifiedData,
      );
      setConfigs(
        fetchConfigResponse.data.data.map((obj) => ({
          value: null,
          ...obj,
        })),
      );
    },
    [],
  );

  // Function to fetch the devices for the manufacturer id
  const fetchDevicesforManfacturer = async () => {
    try {
      let stringifiedData = JSON.stringify({
        manufacturerId: selectedConnectorId,
      });

      let fetchedDevicesforManufacturer = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "admin/getAllTelematicsDevicesForManufacturer",
        stringifiedData,
      );

      setDeviceData(fetchedDevicesforManufacturer.data.data);

      setPreviewData((prev) => ({
        ...prev,
        connectionType: selectedConnectorType,
        category: selectedConnectorCategory,
        name: selectedConnectorName,
        description: selectedConnectorDescription,
        interfaces: [],
      }));

      let allTopics = await fetchAllTopics();

      setAllTopics(allTopics.data.topics);
    } catch (err) {
      console.log("fetchDevicesforManfacturer", err);
      showMessage("Unable to fetch the devices for the selected connector");
      setDeviceData([]);
    }
  };

  // Function to fetch the configs and topics for non-telematics devices
  const fetchConfigsAndTopics = async () => {
    try {
      let stringifiedData = JSON.stringify({
        connectorName: selectedConnectorName,
        type: selectedConnectorType,
      });

      let fetchConfigResponse = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/getConnectorConfigs",
        stringifiedData,
      );
      setContent(fetchConfigResponse.data.data.templateContent);
      setConfigs(
        fetchConfigResponse.data.data.configJson.config.map((obj) => ({
          value: null,
          ...obj,
        })),
      );

      setPreviewData((prev) => ({
        ...prev,
        connectionType: selectedConnectorType,
        category: selectedConnectorCategory,
        name: selectedConnectorName,
        description: selectedConnectorDescription,
        interfaces: [],
      }));

      let allTopics = await fetchAllTopics();

      setAllTopics(allTopics.data.topics);

      if (fetchConfigResponse.data.data.configJson.testConnection === true) {
        setTestConnectionAppicable(true);
      }
    } catch (err) {
      console.log("fetchConfigsAndTopics", err);
      showMessage("Unable to fetch the configs for the selected connector");
      setConfigs([]);
    }
  };

  const fetchCustomConnectorConfigs = async () => {
    try {
      await fetchCustomConnectorUserConfigs(
        selectedConnectorId,
        selectedConnectorVersion,
      );

      setPreviewData((prev) => ({
        ...prev,
        connectionType: selectedConnectorType,
        category: selectedConnectorCategory,
        name: selectedConnectorName,
        description: selectedConnectorDescription,
        interfaces: [],
      }));

      let allTopics = await fetchAllTopics();

      setAllTopics(allTopics.data.topics);

      const getAllCustomConnectorVersionsResponse = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/getAllCustomConnectorVersions",
        JSON.stringify({
          customConnectorId: selectedConnectorId,
        }),
      );

      setConnectorVersionList(
        getAllCustomConnectorVersionsResponse.data.data.versions,
      );
    } catch (err) {
      console.log("fetchConfigsAndTopics", err);
      showMessage("Unable to fetch the configs for the selected connector");
      setConfigs([]);
      setConnectorVersionList([]);
    }
  };

  useEffect(() => {
    if (!location.state) {
      showMessage(
        "Missing configuration data. Redirecting to onboarding page.",
      );
      history.push("/onBoarding");
      return;
    }

    // Check for required fields
    const requiredFields = [
      "selectedConnectorName",
      "selectedConnectorType",
      "selectedConnectorId",
      "selectedConnectorCategory",
    ];

    const missingFields = requiredFields.filter(
      (field) => !location.state[field],
    );

    if (missingFields.length > 0) {
      showMessage(
        `Missing required data: ${missingFields.join(
          ", ",
        )}. Redirecting to onboarding page.`,
      );
      history.push("/onBoarding");
      return;
    }

    // If all required fields are present, proceed with initialization
    if (selectedConnectorCategory === "Telematics Device") {
      fetchDevicesforManfacturer();
    } else if (selectedConnectorCategory === "Application") {
      fetchCustomConnectorConfigs();
    } else {
      fetchConfigsAndTopics();
    }
  }, [location, history, showMessage]);

  useEffect(() => {
    if (selectedConnectorVersion && selectedConnectorId) {
      fetchCustomConnectorUserConfigs(
        selectedConnectorId,
        selectedConnectorVersion,
      );
    }
  }, [
    selectedConnectorVersion,
    selectedConnectorId,
    fetchCustomConnectorUserConfigs,
  ]);

  return (
    <div
      style={{
        backgroundColor: "#171717",
        height: "calc(100vh - 3rem)",
        padding: "1.112rem",
      }}
    >
      <CustomRow style={{ display: "flex", alignItems: "center" }}>
        <Column xs={6}>
          <NavbarContainer>
            <NavbarLeftConnect>
              <Icon onClick={handleConnectorsClick}>
                <img
                  src={backIcon}
                  alt="Back Icon"
                  style={{ width: "2.225rem", height: "2.225rem" }}
                />
              </Icon>
              <Title>{`Configure ${
                selectedConnectorName || "your connector"
              }`}</Title>
            </NavbarLeftConnect>
          </NavbarContainer>
        </Column>
        <Column xs={5} style={{ marginBottom: "1.112rem" }}>
          <Stepper
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            setAnimatingLine={setAnimatingLine}
            animatingLine={animatingLine}
          />
        </Column>
      </CustomRow>
      <CustomRow>
        <Column xs={4.1} className="ConnectorConfigPageColumn">
          <ConfigForm
            deviceData={deviceData}
            configs={configs}
            setConfigs={setConfigs}
            allTopics={allTopics}
            setAllTopics={setAllTopics}
            setPreviewData={setPreviewData}
            connectorVersionList={connectorVersionList}
            setSelectedConnectorVersion={setSelectedConnectorVersion}
            selectedConnectorVersion={selectedConnectorVersion}
          />
        </Column>
        <Column xs={7.9} className="ConnectorConfigPageColumn addFlex">
          <MoreInfoPart content={content} />
          <CustomRow style={{ height: "35.5%" }}>
            {selectedConnectorCategory === "Application" && (
              <Column
                xs={4}
                style={{
                  height: "100%",
                  borderRadius: "0.84rem",
                  backgroundColor: "#2F2F2F",
                  padding: "1.112rem",
                  overflow: "auto",
                  marginLeft: "1.07rem",
                }}
              >
                <CustomRow style={{ rowGap: "0.55rem" }}>
                  <Column>
                    <Text
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.25rem",
                        lineHeight: "1.39rem",
                      }}
                    >
                      Configure Request Limits
                    </Text>
                  </Column>
                  <Column
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "baseline",
                    }}
                  >
                    <Label>Memory</Label>
                    <InputContainer>
                      <StyledInput
                        placeholder="Enter Memory Value"
                        type="number"
                        value={reqResources.memory}
                        onChange={(e) => {
                          setReqResources((prev) => ({
                            ...prev,
                            memory: e.target.value,
                          }));
                        }}
                        max={2000}
                      />
                      <Text style={{ fontSize: "0.972rem" }}>in MB</Text>
                    </InputContainer>
                  </Column>
                  <Column
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "baseline",
                    }}
                  >
                    <Label>CPU</Label>
                    <InputContainer>
                      <StyledInput
                        placeholder="Enter CPU Value"
                        type="number"
                        value={reqResources.cpu}
                        onChange={(e) => {
                          setReqResources((prev) => ({
                            ...prev,
                            cpu: e.target.value,
                          }));
                        }}
                        max={2000}
                      />
                      <Text style={{ fontSize: "0.972rem" }}>in mCPU</Text>
                    </InputContainer>
                  </Column>
                </CustomRow>
              </Column>
            )}
            <Column
              xs={selectedConnectorCategory === "Application" ? 7.8 : 12}
              style={{ height: "100%" }}
            >
              <PreviewContainer>
                <PreviewTitle>Preview</PreviewTitle>
                <ReactFlowNodeCard data={previewData} />
              </PreviewContainer>
            </Column>
          </CustomRow>
        </Column>
      </CustomRow>
      <ConnectorConfigBottomButtonContainer>
        <StyledButtonsecondary
          onClick={goBackClickHandler}
          style={{ width: "14.167rem" }}
        >
          Go Back
        </StyledButtonsecondary>
        <StyledButtonPrimary
          style={{ width: "14.167rem", marginLeft: "1.39rem" }}
          disabled={
            (!configs.length && !deviceData.length) ||
            isConfigureProcessing ||
            (selectedConnectorType === "Custom Connector" &&
              !selectedConnectorVersion.length)
          }
          onClick={() => handleNextClick()}
        >
          {getButtonName(currentStep, isTestConnectionApplicable)}
        </StyledButtonPrimary>
      </ConnectorConfigBottomButtonContainer>
      <GenericModal
        onClose={() => handleModalClose()}
        show={isModalOpen}
        title={modalTitleText}
      >
        <ModalDescription>
          {modalTitleText === "Configuration Successful!"
            ? "You have successfully configured your connector, Please click continue."
            : modalTitleText === "Configuration Failed"
            ? "Oops! Your configuration has failed. Try again."
            : modalTitleText === "Connection Successful!"
            ? "You have successfully configured your connector, Please click continue."
            : modalTitleText === "Deployment Successful!"
            ? "You have successfully deployed your connector. Please click continue."
            : modalTitleText === "Deployment Failed"
            ? "Oops! Your deployment has failed. Try again."
            : "An unexpected error occurred."}
        </ModalDescription>
        <ModalButtonContainer>
          <StyledButtonsecondary
            onClick={() => handleModalClose()}
            disabled={modalTitleText === "Deployment Successful!"}
            style={{ width: "14.167rem" }}
          >
            Cancel
          </StyledButtonsecondary>
          {modalTitleText === "Deployment Failed" ? (
            <StyledDeleteButton
              style={{
                width: "14.167rem",
              }}
              onClick={() => handleModalButton()}
            >
              Try Again
            </StyledDeleteButton>
          ) : (
            <StyledButtonPrimary
              style={{
                width: "14.167rem",
              }}
              onClick={() => handleModalButton()}
            >
              {modalTitleText === "Connection Successful!" ||
              modalTitleText === "Configuration Successful!" ||
              modalTitleText === "Deployment Successful!"
                ? "Continue"
                : "Try Again"}
            </StyledButtonPrimary>
          )}
        </ModalButtonContainer>
      </GenericModal>
    </div>
  );
};

export default ConnectorsConfigurationPage;
