import React from "react";
import styled from "styled-components";
import PauseButton from "../../assets/images/pause.png";
import playButton from "../../assets/images/playButton.png";
import StopButton from "../../assets/images/stopButton.png";

const ButtonWrapper = styled.div`
  perspective: 1000px;
`;

const Button = styled.div`
  width: 100%;
  height: 100%;
  transition: transform 0.5s;
  transform-style: preserve-3d;
  cursor: pointer;
  &:hover {
    transform: scale(0.9);
  }
`;

const StyledPauseButton = ({ onClick, iconType }) => {
  let iconValue;
  switch (iconType) {
    case "pause":
      iconValue = PauseButton;
      break;
    case "play":
      iconValue = playButton;
      break;
    case "stop":
      iconValue = StopButton;
      break;
  }
  return (
    <ButtonWrapper>
      <Button onClick={onClick}>
        <img src={iconValue} height="28px" />
      </Button>
    </ButtonWrapper>
  );
};

export default StyledPauseButton;
