import React, { useState } from "react";
import styled from "styled-components";
import Text from "./_text";
import { ReactComponent as SelectOpenIcon } from "../../assets/images/select_open_icon.svg";
import { ReactComponent as SelectCloseIcon } from "../../assets/images/select_close_icon.svg";
import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import FlexContainer from "./_flexContainer";
import { remToPx } from "../../utils";

const SelectInput = styled(FlexContainer)`
  padding: 0.597rem 0.833rem;
  background: #2f2f2f;
  border: ${({ $open, $alwaysShowBorder, $border }) =>
    $open ? "0.069rem solid #b0b0b0" : $alwaysShowBorder ? $border : ""};
  border-radius: 0.555rem;
  cursor: ${({ $disabled }) => ($disabled ? "" : "pointer")};
  pointer-events: ${({ $disabled }) => ($disabled ? "none" : "")};
  color: ${({ $disabled }) => ($disabled ? "#777777" : "")};
`;
const SelectMenu = styled(FlexContainer)`
  position: relative;
  z-index: 1;
  padding: 0.694rem;
  justify-content: flex-start;
  background: #2f2f2f;
  border: ${({ $open, $alwaysShowBorder, $border }) =>
    $open ? "0.069rem solid #b0b0b0" : $alwaysShowBorder ? $border : ""};
  border-radius: 0.555rem;
  font-family: "Articulat CF Medium";
  font-weight: 500;
  font-size: 1.111rem;
  line-height: 1.3;
  color: #f6f6f6;
  text-align: start;
  & > div {
    padding: 0.694rem;
    cursor: pointer;
    border-radius: 0.555rem;
    &:hover {
      background: #4b4b4b;
    }
  }
`;
const SelectOpenIconStyled = styled(SelectOpenIcon)`
  width: 1.388rem;
  height: 1.388rem;
`;
const SelectCloseIconStyled = styled(SelectCloseIcon)`
  width: 1.388rem;
  height: 1.388rem;
`;

const Select = ({
  disabled,
  value,
  setValue,
  options,
  renderValue,
  width,
  alwaysShowBorder,
  border,
}) => {
  const [open, setOpen] = useState(false);
  const { refs, floatingStyles, context } = useFloating({
    open,
    onOpenChange: setOpen,
    middleware: [
      offset(remToPx(0.277)),
      flip(),
      shift(),
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
          });
        },
      }),
    ],
    whileElementsMounted: autoUpdate,
    placement: "bottom-end",
  });
  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ]);

  return (
    <>
      <SelectInput
        $open={open}
        $disabled={disabled}
        $width={width}
        $alwaysShowBorder={alwaysShowBorder}
        $border={border}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        <Text>{renderValue ? renderValue(value) : value}</Text>
        {open && !disabled ? (
          <SelectOpenIconStyled />
        ) : !disabled ? (
          <SelectCloseIconStyled />
        ) : null}
      </SelectInput>
      {open && (
        <FloatingPortal>
          <SelectMenu
            $open={open}
            $alwaysShowBorder={alwaysShowBorder}
            $border={border}
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            {options.map((option, index) => (
              <Text
                key={index}
                $width="100%"
                onClick={() => {
                  setValue(option);
                  setOpen(false);
                }}
              >
                {renderValue ? renderValue(option) : option}
              </Text>
            ))}
          </SelectMenu>
        </FloatingPortal>
      )}
    </>
  );
};

export default Select;
