import styled from "styled-components";
import FlexContainer from "./_flexContainer";

const Chip = styled(FlexContainer)`
  place-content: center;
  width: 2.222rem;
  height: 2.222rem;
  border: 0.138rem solid #0d0d0d;
  border-radius: 100%;
  position: relative;
  background: #2f2f2f;
  cursor: ${({ $cursor }) => $cursor || ""};
  &:hover {
    z-index: 1;
    border: ${({ $isRemainingMembersChip }) =>
      $isRemainingMembersChip
        ? "0.069rem solid #B0B0B0"
        : "0.138rem solid #0d0d0d"};
  }
  &:not(:first-of-type) {
    left: ${({ $index }) => `${-0.486 * $index}rem`};
  }
`;

export default Chip;
