import {
  FloatingOverlay,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useMergeRefs,
  useRole,
} from "@floating-ui/react";
import React, { cloneElement, isValidElement, useState } from "react";
import styled from "styled-components";

const FloatingOverlayStyled = styled(FloatingOverlay)`
  background: #0d0d0db8;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const DialogContainer = styled.div`
  flex: ${({ $flex }) => $flex || "1"};
  max-width: ${({ $maxWidth }) => $maxWidth || "60vw"};
  max-height: ${({ $maxHeight }) => $maxHeight || "65vh"};
  overflow: auto;
  background: #2f2f2f;
  border: 0.069rem solid #777777;
  border-radius: 1.666rem;
  font-family: "Articulat CF Medium";
  font-weight: 500;
  font-size: 1.111rem;
  line-height: 1.3;
  color: #f6f6f6;
  text-align: start;
`;

const Dialog = ({
  children,
  content,
  flex,
  initialOpen = false,
  maxHeight,
  maxWidth,
  open: controlledOpen,
  onOpenChange: setControlledOpen,
}) => {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);
  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;
  const { refs, context } = useFloating({
    open,
    onOpenChange: setOpen,
  });
  const click = useClick(context, {
    enabled: controlledOpen == null,
  });
  const dismiss = useDismiss(context, { outsidePressEvent: "mousedown" });
  const role = useRole(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ]);
  const childrenRef = children?.ref;
  const ref = useMergeRefs([refs.setReference, childrenRef]);
  const childrenWithProps = isValidElement(children)
    ? cloneElement(
        children,
        getReferenceProps({
          ref,
          ...children.props,
          "data-state": context.open ? "open" : "closed",
        }),
      )
    : null;

  return (
    <>
      {childrenWithProps}
      {open && (
        <FloatingPortal>
          <FloatingOverlayStyled lockScroll>
            <DialogContainer
              $flex={flex}
              $maxWidth={maxWidth}
              $maxHeight={maxHeight}
              ref={refs.setFloating}
              {...getFloatingProps()}
            >
              {content}
            </DialogContainer>
          </FloatingOverlayStyled>
        </FloatingPortal>
      )}
    </>
  );
};

export default Dialog;
