import React from "react";
import FlexContainer from "../common/_flexContainer";
import { ReactComponent as CondenseIcon } from "../../assets/images/condenseLogo.svg";
import styled from "styled-components";
import WorkspaceSelect from "./WorkspaceSelect";
import { useLocation } from "react-router-dom";
import FreePlan from "./FreePlan";
import LoginAvatar from "./LoginAvatar";
import GrafanaDashBoardButton from "./GrafanaDashBoardButton";

const CondenseIconStyled = styled(CondenseIcon)`
  width: 10.416rem;
  height: 2.222rem;
`;
const VerticalDivider = styled.div`
  border-right: 0.138rem solid #9090908f;
  height: 2.222rem;
`;
const FlexContainerWithDefaultTextFormatted = styled(FlexContainer)`
  font-family: "Articulat CF Medium";
  font-weight: 500;
  font-size: 16px;
  line-height: 1.3;
  border-bottom: 0.069rem solid #9090903d;
  background: #2f2f2f;
  color: #f6f6f6;
  text-align: start;
`;

const NavBar = () => {
  const location = useLocation();

  return (
    <FlexContainerWithDefaultTextFormatted
      $width="100%"
      $padding="0.84rem 1.112rem"
      $gap="1.112rem"
    >
      <FlexContainer $gap="1.112rem">
        <CondenseIconStyled />
        <VerticalDivider />
        {location.pathname !== "/onBoarding" && <WorkspaceSelect />}
        <FreePlan />
      </FlexContainer>
      <FlexContainer $gap="1.112rem">
        <GrafanaDashBoardButton />
        <LoginAvatar />
      </FlexContainer>
    </FlexContainerWithDefaultTextFormatted>
  );
};

export default NavBar;
