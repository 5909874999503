import styled from "styled-components";

const GridContainer = styled.div`
  width: ${({ $width }) => $width || ""};
  display: grid;
  grid: ${({ $grid }) => $grid || ""};
  gap: ${({ $gap }) => $gap || ""};
  margin: ${({ $margin }) => $margin || ""};
  padding: ${({ $padding }) => $padding || ""};
  place-items: ${({ $placeItems }) => $placeItems || ""};
  place-content: ${({ $placeContent }) => $placeContent || ""};
`;

export default GridContainer;
