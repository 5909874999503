import { useContext, useState } from "react";
import GenericModal from "../../../components/common/_genericModal";
import CustomRow from "../../../components/common/_customRow";
import Column from "../../../components/common/_customColumn";
import styled from "styled-components";
import {
  StyledButtonPrimary,
  StyledButtonsecondary,
} from "../../../components/common/_buttonNewOne";
import axiosAdapter from "../../../utils";
import { SnackbarContext } from "../../../layouts/Context/snackBarContext";
import { ReactComponent as RightArrowIcon } from "../../../assets/images/length_right_arrow.svg";
import { env } from "../../../env";

const roleNormalNameMapper = {
  ADMIN: "Admin",
  USER: "User",
};

const CustomColumn = styled(Column)`
  margin-bottom: ${({ mb }) => (mb ? `${mb}rem` : "0rem")};
`;

const Text = styled.p`
  font-family: "Articulat CF Medium";
  color: #ececec;
  font-size: 1.25rem;
  line-height: 1.555rem;
  margin: 0;
  opacity: 0.7;
`;

const ColumnWithFlexWrap = styled(CustomColumn)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2.222rem;
`;

export const UserRoleChangeModal = ({
  isOpen,
  onClose,
  dataRow,
  refetchUsersAndRoles,
}) => {
  const { showMessage } = useContext(SnackbarContext);
  const [isApiLoading, setApiLoading] = useState(false);

  const handleSubmitClick = async () => {
    setApiLoading(true);
    try {
      await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "user-management/updateUserRole",
        {
          emailId: dataRow.emailId,
          role: dataRow.newRole,
        },
      );
      showMessage("Successfully updated user role", "success");
      refetchUsersAndRoles();
      onClose();
    } catch (err) {
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage("Failed to update user role");
      }
      setApiLoading(false);
    }
  };

  return (
    <GenericModal
      show={isOpen}
      onClose={onClose}
      title={"Change Member Role"}
      disableCloseOnOutsideClick={isApiLoading}
    >
      <CustomRow>
        <CustomColumn mb={1.666}>
          <Text>
            Changing a member's role will change their access across the
            organisation.
          </Text>
        </CustomColumn>
        <ColumnWithFlexWrap mb={3.472}>
          <CustomColumn
            xs={12}
            style={{ display: "flex", alignItems: "center", gap: "0.833rem" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "2.777rem",
                width: "2.777rem",
                borderRadius: "0.2777rem",
                backgroundColor: "#4B4B4B",
                justifyContent: "center",
                color: "#777777",
                fontWeight: "bold",
                fontSize: "1.666rem",
                lineHeight: "1.805rem",
                fontFamily: "Articulat CF Medium",
              }}
            >
              {dataRow.fullName.substring(0, 1).toUpperCase()}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Text
                style={{
                  fontSize: "1.111rem",
                  fontWeight: "bold",
                  lineHeight: "1.666rem",
                  color: "#F6F6F6",
                  opacity: "1",
                }}
              >
                {dataRow.fullName}
              </Text>
              <Text
                style={{
                  fontSize: "0.833rem",
                  lineHeight: "1.111rem",
                  color: "#777777",
                  opacity: "1",
                }}
              >
                {dataRow.emailId}
              </Text>
            </div>
          </CustomColumn>
          <CustomColumn
            xs={12}
            lg={6}
            style={{
              display: "flex",
              gap: "1.041rem",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Text
              style={{
                color: "#F6F6F6",
                fontSize: "1.111rem",
                lineHeight: "1.805rem",
                fontWeight: "bold",
                opacity: "1",
                textDecoration: "line-through",
              }}
            >
              {roleNormalNameMapper[dataRow.role]}
            </Text>
            <RightArrowIcon />
            <Text
              style={{
                color: "#47CCD6",
                fontSize: "1.111rem",
                lineHeight: "1.805rem",
                fontWeight: "bold",
                opacity: "1",
              }}
            >
              {roleNormalNameMapper[dataRow.newRole]}
            </Text>
          </CustomColumn>
        </ColumnWithFlexWrap>
        <CustomColumn
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <StyledButtonsecondary
            width="13.888rem"
            style={{ marginRight: "1.111rem" }}
            onClick={onClose}
          >
            Cancel
          </StyledButtonsecondary>
          <StyledButtonPrimary
            width="13.888rem"
            onClick={() => handleSubmitClick()}
            disabled={isApiLoading}
          >
            Submit
          </StyledButtonPrimary>
        </CustomColumn>
      </CustomRow>
    </GenericModal>
  );
};
