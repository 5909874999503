import React from "react";
import styled from "styled-components";
import { ReactComponent as RocketIcon } from "../../../assets/images/Rocket.svg";
import {
  StyledButtonPrimary,
  StyledButtonsecondary,
} from "../../../components/common/_buttonNewOne";
import FlexContainer from "../../../components/common/_flexContainer";
import Text from "../../../components/common/_text";

const RocketIconStyled = styled(RocketIcon)`
  width: 1.666rem;
  height: 1.666rem;
`;
const PrimaryButton = styled(StyledButtonPrimary)`
  border-radius: 0.555rem;
`;
const SecondaryButton = styled(StyledButtonsecondary)`
  border-radius: 0.555rem;
  border: 0.069rem solid ${(props) => (props.disabled ? "#797979" : "#ECECEC")};
  &:hover {
    border: 0.069rem solid #47ccd6;
  }
`;

const UpgradePlanSectionContents = () => {
  return (
    <>
      <FlexContainer
        $width="min(28rem, 100%)"
        $justifyContent="flex-start"
        $gap="0.555rem"
      >
        <RocketIconStyled />
        <Text
          $width="calc(100% - 1.666rem - 0.555rem)"
          $fontWeight="bold"
          $fontSize="1.388rem"
          $color="#47CCD6"
          $noWrap
        >
          You are currently on the free plan.
        </Text>
        <Text $width="100%" $fontSize="0.972rem" $color="#F6F6F6" $noWrap>
          Paid plans offer lot of other better things and much more.
        </Text>
      </FlexContainer>
      <FlexContainer $flex="1" $justifyContent="flex-end" $gap="1.111rem">
        <SecondaryButton width="14.166rem">Chat to Us</SecondaryButton>
        <PrimaryButton width="13.333rem">Upgrade Plan</PrimaryButton>
      </FlexContainer>
    </>
  );
};

export default UpgradePlanSectionContents;
