import React from "react";
import CustomAppEditorContext from "./CustomAppEditorContext";

const CustomAppEditorProvider = ({ children }) => {
  const initialState = {
    editorFiles: {},
    selectedFile: "file1",
    showTerminal: false,
  };
  const [customAppEditorData, setCustomAppEditorData] =
    React.useState(initialState);

  const updateCustomAppEditorData = (data) => {
    if (!data.selectedFile && Object.keys(data.editorFiles).length > 0) {
      data.selectedFile = Object.keys(data.editorFiles)[0];
    }
    setCustomAppEditorData((prevData) => ({
      ...prevData,
      ...data,
    }));
  };

  return (
    <CustomAppEditorContext.Provider
      value={{
        customAppEditorData,
        updateCustomAppEditorData,
      }}
    >
      {children}
    </CustomAppEditorContext.Provider>
  );
};

export default CustomAppEditorProvider;
