import styled from "styled-components";

/**
 * CustomRow component is a styled div component that wraps other components into a flex row.
 * It accepts props for flexDirection, flexWrap and alignItems.
 *
 * @param {string} flexDirection - The direction of flex items within the container. Defaults to "row".
 * @param {string} flexWrap - Specifies whether flex items should wrap or not. Defaults to "wrap".
 * @param {string} alignItems - Sets the align-items property of the flex container. Defaults to "center".
 * @param {string} justifyContent - Sets the justify-content property of the flex container. Defaults to "space-between".
 */
const CustomRow = styled.div`
  display: flex;
  flex-direction: ${(props) => props.flexDirection || "row"};
  flex-wrap: ${(props) => props.flexWrap || "wrap"};
  align-items: ${(props) => props.alignItems || "center"};
  justify-content: ${(props) => props.justifyContent || "space-between"};
`;

export default CustomRow;
