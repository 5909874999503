import styled from "styled-components";
import CustomRow from "../../components/common/_customRow";
import { CommonFirstPage } from "../LandingPages/CommonFirstPage";
import Column from "../../components/common/_customColumn";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import OtpInputField from "../../components/common/_otpInputField";
import { useState } from "react";
import { StyledButtonPrimary } from "../../components/common/_buttonNewOne";
import ChevronRight from "../../assets/images/Chevron-left.svg";
import { useContext } from "react";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import axiosAdapter from "../../utils";
import { env } from "../../env";

const CustomColumn = styled(Column)`
  margin-bottom: ${({ mb }) => (mb ? `${mb}rem` : "0rem")};
`;

const Text = styled.p`
  font-family: "Articulat CF Medium";
  margin: 0;
  color: #f6f6f6;
  font-size: 1.527rem;
  line-height: 2.166rem;
`;

export const OtpVerification = () => {
  const history = useHistory();
  const location = useLocation();
  const { showMessage } = useContext(SnackbarContext);

  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const [disableAllButtons, setDisableAllButtons] = useState(false);

  if (!Boolean(location.state?.isRedirected)) {
    history.push("/");
  }

  const handleVerifyClick = async () => {
    setDisableAllButtons(true);
    setOtpError("");
    if (otp.trim().length !== 6) {
      setOtpError("Please enter a 6 digit OTP");
      setDisableAllButtons(false);
      return;
    }

    try {
      await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "user-management/verifyOtp",
        {
          emailId: location.state?.emailId,
          otp: otp,
        },
      );

      if (location.state?.isSignUp) {
        history.push("/createNewAccount", {
          isRedirected: true,
          emailId: location.state?.emailId,
          countryCode: location.state?.countryCode,
          phoneNumber: location.state?.phoneNumber,
          fullName: location.state?.fullName,
          organizationName: location.state?.organizationName,
          inviteCode: location.state?.inviteCode,
          otp: otp,
        });
      } else {
        history.push("/resetPasswordPage", {
          isRedirected: true,
          emailId: location.state?.emailId,
          otp: otp,
        });
      }
    } catch (err) {
      console.log("Error: verifyOtp", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage("Unable to verify OTP, please try again later", "error");
      }
      setDisableAllButtons(false);
    }
  };

  const resendOtp = async () => {
    setDisableAllButtons(true);
    try {
      await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "user-management/generateOtp",
        {
          emailId: location.state?.emailId,
          isNewAccount: location.state?.isSignUp,
        },
      );

      // As OTP is re-sent, set disableAllButtons to false
      setDisableAllButtons(false);
      showMessage(
        "OTP is re-sent successfully, please check your mail",
        "success",
      );
    } catch (err) {
      console.log("Error: generateOtp", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage("Unable to generate OTP, please try again later", "error");
      }
      setDisableAllButtons(false);
    }
  };

  return (
    <CommonFirstPage>
      <CustomRow>
        <CustomColumn mb={2.777}>
          <Text>
            {`Please check your mail. We have sent an OTP to`}
            <span
              style={{ color: "#47CCD6" }}
            >{` ${location.state?.emailId}`}</span>
          </Text>
        </CustomColumn>
        <CustomColumn
          mb={2.777}
          style={{ display: "flex", flexDirection: "column", gap: "0.555rem" }}
        >
          <OtpInputField onChange={setOtp} isError={Boolean(otpError)} />
          {otpError && (
            <Text
              style={{
                fontSize: "0.833rem",
                lineHeight: "1.388rem",
                color: "#EA3A3A",
              }}
            >
              {otpError}
            </Text>
          )}
        </CustomColumn>
        <CustomColumn mb={1.111}>
          <StyledButtonPrimary
            style={{ width: "100%", height: "3.888rem" }}
            onClick={handleVerifyClick}
            disabled={disableAllButtons || otp.trim().length !== 6}
          >
            Verify
          </StyledButtonPrimary>
        </CustomColumn>
        <CustomColumn mb={2.777}>
          <Text
            style={{
              color: "#777777",
              fontSize: "1.111rem",
              lineHeight: "1.388rem",
            }}
          >
            Didn’t get a code?
            <span
              style={{
                color: "#CCCCCC",
                fontWeight: "bold",
                textDecoration: "underline",
                cursor: "pointer",
                fontSize: "1.111rem",
                marginLeft: "0.277rem",
              }}
              onClick={() => !disableAllButtons && resendOtp()}
            >
              Click to Resend
            </span>
          </Text>
        </CustomColumn>
        <CustomColumn
          mb={2.777}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "auto",
            cursor: "pointer",
          }}
          onClick={() =>
            !disableAllButtons &&
            history.push(location.state.isSignUp ? "/signUp" : "/signIn")
          }
        >
          <img
            src={ChevronRight}
            alt="Back Icon"
            style={{
              width: "0.833rem",
              height: "0.833rem",
              marginRight: "0.555rem",
            }}
          />
          <Text
            style={{
              color: "#CCCCCC",
              fontSize: "1.111rem",
              lineHeight: "1.388rem",
            }}
          >
            Back to {location.state.isSignUp ? "Sign Up" : "Sign In"}
          </Text>
        </CustomColumn>
      </CustomRow>
    </CommonFirstPage>
  );
};
